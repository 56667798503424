<template>
  <div class="container">

    <div class="card card-outline">
      <div class="card-body">

        <div class="row light-border">
          <div class="col-5" v-if="id">
            <div class="form-group">
              <label for="Code" class="form-label">Id</label>
              <input
                  type="text"
                  class="form-control"
                  id="Code"
                  placeholder="Id"
                  :value="id"
                  disabled
              />
            </div>
          </div>
          <div class="col-5">
            <div class="form-group">
              <label for="Code" class="form-label">Name</label>
              <input
                  type="text"
                  class="form-control"
                  id="Code"
                  placeholder="Name"
                  v-model="form.name"
              />
            </div>
          </div>
          <div class="col-2">
            <div class="form-group text-center">
              <label for="active" class="form-label">Active</label>
              <input
                  type="checkbox"
                  id="active"
                  class="form-control"
                  v-model="form.active"
                  value="1"
              />
            </div>
          </div>
        </div>


        <div class="row light-border" v-if="id">
          <div class="col-12">
            <CollectionImages :id="id"></CollectionImages>
          </div>
        </div>

        <div class="row light-border">
          <div class="col-2">
            <button class="btn btn-danger" @click="deleteCollection">Delete</button>
          </div>
          <div class="col-10 text-right">
            <button class="btn btn-primary" @click="saveCollection">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import CollectionImages from "@/components/Admin/CollectionImages.vue";
import {useToast} from "vue-toastification";

export default {
  name: "CollectionEdit",
  props: ['id'],
  setup() {
    const toast = useToast();
    return {toast};
  },
  data() {
    return {
      isLoading: false,
      load:null,
      loader: null,
      form: {
        name: "",
        active: false
      },
    };
  },
  components: {
    CollectionImages
  },
  computed: {
    ...mapGetters({
      collection: "product/getCollectionDetail",
    })
  },
  methods: {
    deleteCollection(){
      this.load = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: this.canCancel,
        color: this.color,
        backgroundColor: this.bgColor,
        height: this.height,
        width: this.width,
        loader: 'dots',
        opacity: 0.3,
      });
      this.$store.dispatch("product/deleteCollection", {id: this.id}).then(async () => {
        this.toast.error("Deleted");
        this.load.hide()
        await this.$store.dispatch('product/deleteImage', {folder:"collections",filename:this.id+'.jpg'}).then(() => {});
        location.href = '/Admin/Collections';
      });
    },
    saveCollection() {
      this.load = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: this.canCancel,
        color: this.color,
        backgroundColor: this.bgColor,
        height: this.height,
        width: this.width,
        loader: 'dots',
        opacity: 0.3,
      });
      if(this.id !== undefined && this.id !== '') {
        this.$store.dispatch("product/updateCollection", {...this.form, id: this.id}).then(() => {
          this.toast.success("Saved");
          this.load.hide()
        });
      }else{
        this.$store.dispatch("product/addCollection", {...this.form}).then(res => {
          this.load.hide()
          if(res.id !== null){
            location.href = '/Admin/Collections/Edit/'+res.id;
          }
        });
      }
    }
  },
  mounted() {
    this.load = this.$loading.show({
      container: this.fullPage ? null : this.$refs.formContainer,
      canCancel: this.canCancel,
      color: this.color,
      backgroundColor: this.bgColor,
      height: this.height,
      width: this.width,
      loader: 'dots',
      opacity: 0.3,
    });

    if (this.$route.params.id !== undefined && this.$route.params.id !== '') {
      this.$store
          .dispatch("product/collectionDetails", this.$route.params.id)
          .then(() => {
            //this.form.Code = this.product.Code
            this.form.active = this.collection.active
            this.form.name = this.collection.name
            this.load.hide();
          });
    }else{
      this.load.hide();
    }
  },
};
</script>

<style scoped>

.light-border {
  border: 1px solid #e0e0e0; /* Light gray border color */
  padding: 15px; /* Add padding for spacing */
}

</style>