import axios from 'axios'

export default {
    namespaced: true,
    state: {
        paymentBatchData: {},
        paymentStatusData:{},
    },
    getters: {
        getPaymentBatchData(state){
            return state.paymentBatchData
        },
        getPaymentStatusData(state){
            return state.paymentStatusData
        }
    },
    actions: {
        paymentStatus({commit}){
            return new Promise((resolve, reject) => {

                var config = {
                    method: 'get',
                    url: process.env.VUE_APP_API_SERVER + '/paymentStatus',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                }

                axios(config)
                    .then(response => {
                        commit('setPaymentStatusData',response.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        paymentBatchData({commit},{options}){
            return new Promise((resolve, reject) => {
                let data = { ...options }
                var config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/searchPaymentBatch',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                }

                axios(config)
                    .then(response => {
                        commit('setPaymentBatchData',response.data.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        makeBatchPayment(_,InvoiceIds){
            return new Promise((resolve, reject) => {
                let data =  {InvoiceIds}
                var config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/makeBatchPayments',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                }

                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })

            })
        }
    },
    mutations: {
        setPaymentBatchData(state, data){
            state.paymentBatchData = !data ? {} : data
        },
        setPaymentStatusData(state, data){
            state.paymentStatusData = !data ? {} : data
        }
    }
}