<template>
  <div>
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-12">
            <div class="card card-outline card-primary">
              <div class="card-header">
                <div class="row">
                  <div class="col">
                    <h2>Users</h2>
                  </div>
                  <div class="col-auto">
                    <button class="btn btn-primary" @click.prevent="addUser">Add User</button>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="active" class="form-label">Active</label>
                      <select name="acitve" class="form-control" id="active" @change="searchUsers">
                        <option value=""></option>
                        <option value="internal">Internal</option>
                        <option value="Y">Active</option>
                        <option value="N">Not Active</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="role" class="form-label">Role</label>
                      <select name="role" id="role" class="form-control" @change="searchUsers" v-model="filterForm.role">
                        <option value=""></option>
                        <option v-for="role in rolesListD" :key="role.id" :value="role.id">{{ role.title }}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div><!-- /.container-fluid -->
    </section>


    <div class="card card-outline">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <table id="users1" class="table table-bordered table-striped">
              <thead>
              <tr>
                <th>User Id</th>
                <th>Name</th>
                <th>Company</th>
                <th>Address</th>
                <th>User Name</th>
                <th>Email</th>
                <th>Active</th>
                <th>Role</th>
                <th>
                  &nbsp;
                </th>
              </tr>
              </thead>
              <tbody>

              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import $ from "jquery";
import {useToast} from "vue-toastification";
import {mapGetters} from "vuex";

export default {
  name: "Users",
  setup() {
    // Get toast interface
    const toast = useToast();
    return {toast}
  },
  data() {
    return {
      search: '',
      options: {dom: '<"top"f>rt<"bottom"ilp><"clear">', buttons: ['csv']},
      filterForm: {
        role: null
      }
    }
  },
  computed: {
    ...mapGetters({
      'rolesList': 'user/getRolesList',
    }),
    rolesListD() {
      let idToRemove = 3; // The ID you want to remove
      let newData = [];
      const isEmpty = Object.keys(this.rolesList).length === 0;
      if (!isEmpty) {
        // Use the filter method to create a new array without the object with the specified ID
        newData = this.rolesList.filter(function (item) {
          return item.id !== idToRemove;
        });
      }
      return newData;
    }
  },
  methods: {
    searchUsers() {
      $("#users1").DataTable().search(this.search).draw();
    },
    onint() {
      let self = this
      $("#users1_wrapper input[type='search']").off();
      // Use return key to trigger search
      $("#users1_wrapper input[type='search']").on("keyup", function (evt) {
        this.search = $(this).val();
        if (evt.keyCode == 13) {
          $("#users1").DataTable().draw();
        }
      });

      const cbox = document.querySelectorAll(".editUser");

      for (let i = 0; i < cbox.length; i++) {
        cbox[i].addEventListener("click", function () {
          self.editUser(cbox[i].dataset.userid);
        });
      }

      const dbox = document.querySelectorAll(".deleteUser");

      for (let i = 0; i < dbox.length; i++) {
        dbox[i].addEventListener("click", function () {
          self.deleteUser(dbox[i].dataset.userid);
        });
      }
    },
    editUser(userid) {
      this.$router.push({path: '/Admin/UserEdit/' + userid, params: {userid}});
    },
    deleteUser(userid) {
      this.$store.dispatch('user/deleteUser', userid).then(data => {
        if (data.success !== undefined && data.success) {
          this.toast.success("User deleted");
          $("#users1").DataTable().ajax.reload(this.onint);
        } else {
          for (const [key, value] of Object.entries(data.data)) {
            this.toast.warning(key + ": " + value[0]);
          }
        }
      });
    },
    addUser() {
      this.$router.push({path: '/Admin/UserEdit/'});
    },
    loadData() {
      let self = this
      $("#users1").DataTable({
        ajax: function (data, callback) {
          let sPage = (parseInt($("#users1").DataTable().page()) + 1);
          let search = $("#users1_wrapper input[type='search']").val();
          let active = $("#active").val();
          let orderCol = data.columns[data.order[0].column].name;
          let orderDir = data.order[0].dir;

          self.$store.dispatch('user/getUserList', {
            options: {
              page: sPage,
              perPage: data.length,
              active: active,
              search,
              orderCol,
              orderDir, ...self.filterForm
            }
          }).then(adata => {
            const tmpJson = {
              recordsTotal: adata.total, // expected by DataTables to create pagination
              recordsFiltered: adata.total, // expected by DataTables to create pagination
              data: adata.data, // expected by DataTables to populate the table
              currentPage: adata.current_page // added by me to easily manage correct page displaying
            }

            setTimeout(function () {
              self.onint();
            }, 600);

            callback(
                tmpJson
            )
          });
        },
        "initComplete": function () {
          //self.onint();
        },
        processing: true,
        serverSide: true,
        pageLength: 25,
        'columnDefs': [{
          'targets': [4], // column index (start from 0)
          'orderable': false, // set orderable false for selected columns
        }
        ],
        "columns": [
          {"data": "id"},
          {"data": "name"},
          {
            "data": null,
            "name": "Company",
            "render": function (data, type, row) {
              let dta = ''
              if (row.accounts.length > 0) {
                dta = row.accounts[0].Company
              }
              return dta
            }
          },
          {
            "data": null,
            "name": "Address",
            "render": function (data, type, row) {
              let dta = ''
              if (row.accounts.length > 0) {
                dta = row.accounts[0].ShipAddress1 + ' ' + (row.accounts[0].ShipAddress2 !== null ? row.accounts[0].ShipAddress2 : '') + '<br>' + row.accounts[0].ShipCity + ' ' + row.accounts[0].ShipState + ' ' + row.accounts[0].ShipZip
              }
              return dta
            }
          },
          {"data": "username"},
          {"data": "email"},
          {
            "data": null,
            "name": "Active",
            "render": function (data, type, row) {
              let dta = ''
              if (row.accounts.length > 0) {
                dta = row.accounts[0].ActiveType
              }
              return dta
            }
          },
          {
            "data": null,
            "name": "Role",
            "render": function (data, type, row) {
              let dta = ''
              if (row.roles.length > 0) {
                dta = row.roles[0].title
              }
              return dta
            }
          },
          {
            "data": null,
            "name": "InvoiceInvTotal",
            "render": function (data, type, row) {
              let dBtn = '<button type="button" class="btn btn-danger deleteUser ml-2" data-userid="' + row.id + '">Delete</button>';
              let eBtn = '<button type="button" class="btn btn-primary editUser" data-userid="' + row.id + '">Edit</button>';
              return '<div class="btn-group">' + eBtn + dBtn + '</div>';
            }
          },
        ],
        "responsive": true, "lengthChange": false, "autoWidth": false,
        "buttons": ["copy", "csv", "excel", "pdf", "print", "colvis"],
      }).buttons().container().appendTo('#users1_wrapper .col-md-6:eq(0)');

    }
  },
  mounted() {
    this.$store.dispatch('user/getRolesList', {options: {}});
    this.loadData()


    /*
    this.$store.dispatch('user/getUserList', {options: {}}).then(adata => {
      console.log('adfasd',adata);
    })
    */
  },
  beforeRouteLeave() {
      $("#users1").DataTable().destroy();
  }
}
</script>

<style scoped>

</style>