<template>
  <div class="container">
    <div class="card card-outline card-dark">
      <div class="card-header text-center">
        <div class="row">
          <div class="col-3">
          </div>
          <div class="col-6">
            Frame Tracker Data
          </div>
          <div class="col-3">
            <div class="row">
              <div class="col-10">
                <input type="text" class="form-control" placeholder="Search" v-model="searchBox" @keyup.enter="searchProduct()">
              </div>
              <div class="col-2">
                <button class="btn btn-primary" @click.prevent="searchProduct()">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-6">

            <div class="row">
              <div class="col-6">
                <label for="Code" class="form-label">Code</label>
                <input
                    type="text"
                    class="form-control"
                    id="Code"
                    placeholder="Code"
                    :value="product.Code"
                    disabled
                />
              </div>
              <div class="col-6">
                <label for="Code" class="form-label">Frame Type</label>
                <input
                    type="text"
                    class="form-control"
                    id="Code"
                    placeholder="Code"
                    :value="product.FrameType"
                    disabled
                />
              </div>
            </div>

            <div class="row">

              <div class="col-6">
                <label for="Code" class="form-label">ManufCode</label>
                <input
                    type="text"
                    class="form-control"
                    id="Code"
                    placeholder="Manuf Code"
                    :value="product.ManufCode"
                    disabled
                />
              </div>

              <div class="col-6">
                <label for="Code" class="form-label">On Hand</label>
                <input
                    type="text"
                    class="form-control"
                    id="Code"
                    placeholder="On Hand"
                    :value="product.Onhand !== undefined ? parseInt(product.Onhand) : 0"
                    disabled
                />
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <label for="Code" class="form-label">Width</label>
                <input
                    type="text"
                    class="form-control"
                    id="Code"
                    placeholder="Width"
                    :value="product.Width"
                    disabled
                />
              </div>

              <div class="col-6">
                <label for="Code" class="form-label">Land Cost</label>
                <input
                    type="text"
                    class="form-control"
                    id="Code"
                    placeholder="Land Cost"
                    :value="product.LandCost"
                    disabled
                />
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <label for="Code" class="form-label">Active</label>
                <input
                    type="text"
                    class="form-control"
                    id="Code"
                    placeholder="Active"
                    :value="product.ActiveType"
                    disabled
                />
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <label for="FDesc" class="form-label">Description</label>
                <textarea
                    class="form-control"
                    id="FDesc"
                    rows="3"
                    v-html="product.Description"
                    disabled
                ></textarea>
              </div>
            </div>
          </div>

          <div class="col-6">
            <div class="row">
              <div class="col-6">
                <label for="Code" class="form-label">Length</label>
                <input
                    type="text"
                    class="form-control"
                    id="Code"
                    placeholder="Length"
                    :value="product.Length"
                    disabled
                />
              </div>
              <div class="col-6">
                <label for="Code" class="form-label">Exact</label>
                <input
                    type="text"
                    class="form-control"
                    id="Code"
                    placeholder="Length 2"
                    :value="product.Length2"
                    disabled
                />
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <label for="Code" class="form-label">Chop</label>
                <input
                    type="text"
                    class="form-control"
                    id="Code"
                    placeholder="Chop"
                    :value="product.Chop"
                    disabled
                />
              </div>
              <div class="col-6">
                <label for="Code" class="form-label">Join</label>
                <input
                    type="text"
                    class="form-control"
                    id="Code"
                    placeholder="Join"
                    :value="product.JoinAmt"
                    disabled
                />
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <label for="Code" class="form-label">Glass1</label>
                <input
                    type="text"
                    class="form-control"
                    id="Code"
                    placeholder="Glass1"
                    :value="product.Glass1"
                    disabled
                />
              </div>
              <div class="col-6">
                <label for="Code" class="form-label">Mat1</label>
                <input
                    type="text"
                    class="form-control"
                    id="Code"
                    placeholder="Mat1"
                    :value="product.Mat1"
                    disabled
                />
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <label for="Code" class="form-label">Glass2</label>
                <input
                    type="text"
                    class="form-control"
                    id="Code"
                    placeholder="Glass2"
                    :value="product.Glass2"
                    disabled
                />
              </div>
              <div class="col-6">
                <label for="Code" class="form-label">Mat2</label>
                <input
                    type="text"
                    class="form-control"
                    id="Code"
                    placeholder="Mat2"
                    :value="product.Mat2"
                    disabled
                />
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <label for="Code" class="form-label">Glass3</label>
                <input
                    type="text"
                    class="form-control"
                    id="Code"
                    placeholder="Glass3"
                    :value="product.Glass3"
                    disabled
                />
              </div>
              <div class="col-6">
                <label for="Code" class="form-label">Mat3</label>
                <input
                    type="text"
                    class="form-control"
                    id="Code"
                    placeholder="Mat3"
                    :value="product.Mat3"
                    disabled
                />
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <label for="Code" class="form-label">Glass4</label>
                <input
                    type="text"
                    class="form-control"
                    id="Code"
                    placeholder="Glass4"
                    :value="product.Glass4"
                    disabled
                />
              </div>
              <div class="col-6">
                <label for="Code" class="form-label">Mat4</label>
                <input
                    type="text"
                    class="form-control"
                    id="Code"
                    placeholder="Mat4"
                    :value="product.Mat4"
                    disabled
                />
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <label for="Code" class="form-label">Glass5</label>
                <input
                    type="text"
                    class="form-control"
                    id="Code"
                    placeholder="Glass5"
                    :value="product.Glass5"
                    disabled
                />
              </div>
              <div class="col-6">
                <label for="Code" class="form-label">Mat5</label>
                <input
                    type="text"
                    class="form-control"
                    id="Code"
                    placeholder="Mat5"
                    :value="product.Mat5"
                    disabled
                />
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <label for="Code" class="form-label">Sample</label>
                <input
                    type="text"
                    class="form-control"
                    id="Code"
                    placeholder="Glass5"
                    :value="product.Sample"
                    disabled
                />
              </div>
              <div class="col-6">
                <label for="Code" class="form-label">Hardware</label>
                <input
                    type="text"
                    class="form-control"
                    id="Code"
                    placeholder="Hardware"
                    :value="product.Hardware"
                    disabled
                />
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <label for="Code" class="form-label">Readymade</label>
                <input
                    type="text"
                    class="form-control"
                    id="Code"
                    placeholder="Readymade"
                    :value="product.Readymade"
                    disabled
                />
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>

    <div class="card card-outline card-primary">
      <div class="card-header text-center">
        Customizable data
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-6">
            <label for="display_name" class="form-label">Display Name</label>
            <input type="text" class="form-control" id="display_name" v-model="form.display_name"/>
          </div>
          <div class="col-3 text-center">
            <label for="frame_creator_active" class="form-label">Frame creator active</label>
            <input type="checkbox" class="form-control" id="frame_creator_active" v-model="form.frame_creator_active"/>
          </div>
          <div class="col-3 text-center">
            <label for="denver_only" class="form-label">Denver only</label>
            <input type="checkbox" class="form-control" id="denver_only" v-model="form.denver_only"/>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="row">
              <div class="col-3">
                <label for="height" class="form-label">Height</label>
                <input type="text" class="form-control" id="height" v-model="heightBase" @input="validateDec('height')"/>
              </div>
              <div class="col-4">
                <label for="heightFrac" class="form-label" style="height: 47px;"></label>
                <select class="form-select custom-select" id="heightFrac" v-model="heightFrac" @change="validateDec('height')">
                  <option></option>
                  <option value="0.0625">1/16</option>
                  <option value="0.125">1/8</option>
                  <option value="0.1875">3/16</option>
                  <option value="0.25">1/4</option>
                  <option value="0.3125">5/16</option>
                  <option value="0.375">3/8</option>
                  <option value="0.4375">7/16</option>
                  <option value="0.5">1/2</option>
                  <option value="0.5625">9/16</option>
                  <option value="0.625">5/8</option>
                  <option value="0.6875">11/16</option>
                  <option value="0.75">3/4</option>
                  <option value="0.8125">13/16</option>
                  <option value="0.875">7/8</option>
                  <option value="0.9375">15/16</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-6">
            <label for="color_family" class="form-label">Color Family</label>
            <select id="color_family" class="form-control" v-model="form.color_family">
              <option value=""></option>
              <option v-for="col in availableFilters.color_family" :key="col.id" :value="col.id">
                {{ col.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <label for="profile_type" class="form-label">Profile Type</label>
            <select id="profile_type" class="form-control" v-model="form.profile_type">
              <option v-for="col in availableFilters.profile_type" :key="col.id" :value="col.id">
                {{ col.name }}
              </option>
            </select>
          </div>
          <div class="col-6">
            <label for="collection" class="form-label">Collection</label>
            <select id="collection" class="form-control" v-model="form.category">
              <option value=""></option>
              <option v-for="col in availableFilters.collection" :key="col.id" :value="col.id">
                {{ col.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <label for="style" class="form-label">Style</label>
            <select id="style" class="form-control" v-model="form.style">
              <option v-for="col in availableFilters.style" :key="col.value" :value="col.value">
                {{ col.name }}
              </option>
            </select>
          </div>
          <div class="col-6">
            <label for="collection" class="form-label">Finish</label>
            <select id="collection" class="form-control" v-model="form.finish">
              <option v-for="col in availableFilters.finish" :key="col.value" :value="col.value">
                {{ col.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="row">
              <div class="col-3">
                <label for="rabbet_dec" class="form-label">Rabbet</label>
                <input type="text" class="form-control" id="rabbet_dec" v-model="rabbet_decBase" @input="validateDec('rabbet_dec')"/>
              </div>
              <div class="col-4">
                <label for="rabbet_decFrac" class="form-label" style="height: 47px;"></label>
                <select class="form-select custom-select" id="rabbet_decFrac" v-model="rabbet_decFrac" @change="validateDec('rabbet_dec')">
                  <option></option>
                  <option value="0.0625">1/16</option>
                  <option value="0.125">1/8</option>
                  <option value="0.1875">3/16</option>
                  <option value="0.25">1/4</option>
                  <option value="0.3125">5/16</option>
                  <option value="0.375">3/8</option>
                  <option value="0.4375">7/16</option>
                  <option value="0.5">1/2</option>
                  <option value="0.5625">9/16</option>
                  <option value="0.625">5/8</option>
                  <option value="0.6875">11/16</option>
                  <option value="0.75">3/4</option>
                  <option value="0.8125">13/16</option>
                  <option value="0.875">7/8</option>
                  <option value="0.9375">15/16</option>
                </select>
              </div>
            </div>

          </div>
          <div class="col-3">
            <label for="display_name" class="form-label">Box Qty</label>
            <input type="text"
                   class="form-control"
                   id="display_name"
                   v-model="form.box_qty"
                   @input="onlyNumeric"
            />

          </div>
          <div class="col-3 text-center">
            <label for="box_nodiscount" class="form-label">No Box Discount</label>
            <input type="checkbox"
                   class="form-control"
                   id="box_nodiscount"
                   v-model="form.box_nodiscount"
            />

          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <label for="frametype" class="form-label">Frame Type</label>
            <select id="frametype" class="form-control" v-model="form.type">
              <option value=""></option>
              <option v-for="col in frameTypeData" :key="col.id" :value="col.id">
                {{ col.name }}
              </option>
            </select>

          </div>
        </div>

        <div class="row mt-5 mb-5">
          <div class="col">
            <button class="btn btn-primary" @click="saveProduct()">Save</button>
            <router-link
                v-show="showFrameBuilder"
                :to="{ name: 'FrameBuilder', param: { Code: form.Code } }"
            >
              <template #default="{ href, navigate }">
                <button
                    class="btn btn-outline-success ml-3"
                    :href="href"
                    @click="navigate"
                >
                  Frame Builder
                </button>
              </template>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-outline card-primary">
      <div class="card-header text-center">
        Images
      </div>
      <div class="card-body">
        <div class="row mb-5">
          <productImages :Code="Code" :product="product"></productImages>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import productImages from "@/components/Admin/ProductImages.vue";
import {useToast} from "vue-toastification";
import Fraction from "fraction.js";

export default {
  name: "ProductsEdit",
  setup() {
    // Get toast interface
    const toast = useToast();
    return {toast}
  },
  data() {
    return {
      Code: '',
      heightBase: '',
      heightFrac: '',
      rabbet_decBase: '',
      rabbet_decFrac: '',
      searchBox: '',
      form: {
        Code: "",
        display_name: "",
        frame_creator_active: "",
        height: "",
        color_family: "",
        profile_type: "",
        category: "",
        style: "",
        finish: "",
        rabbet: "",
        rabbet_dec: "",
        box_qty: "",
        box_nodiscount: false,
        type:"",
        denver_only: ""
      },
    };
  },
  components: {
    productImages
  },
  computed: {
    ...mapGetters({
      product: "product/getProductDetail",
      availableFilters: "product/getFilterData",
      frameTypeData: 'product/getFrameTypeData',
    }),
    showFrameBuilder() {
      return (
          this.product.FrameType === "MOULDING" ||
          this.product.FrameType === "MATS"
      );
    },
    formattedHeight: {
      get() {
        return this.form.height;
      },
      set(value) {
        // Remove any non-numeric characters
        const numericValue = value.replace(/[^0-9.]/g, "");
        // Get the decimal part with a maximum of 4 decimal places
        const decimalPart = numericValue.includes(".")
            ? numericValue.split(".")[1].slice(0, 4)
            : "";
        // Limit the formatted height to a decimal with 4 decimal places
        this.form.height =
            decimalPart !== "" ? numericValue.split(".")[0] + "." + decimalPart : numericValue.split(".")[0];
      }
    },
  },
  watch: {
    // Watcher for the 'myProp' prop
    'form.rabbet_dec'(newval) {
      if (this.isNumeric(newval)) {
        let x = new Fraction(newval);
        this.form.rabbet = x.toFraction(true);
      }
    }
  },
  methods: {
    searchProduct(){
      this.$router.push({ name: 'AdminProducts', query: { searchString: this.searchBox }});
      //window.location.href = '/Admin/Products/'+this.searchBox;
    },
    isNumeric(value) {
      return !isNaN(parseFloat(value)) && isFinite(value);
    },
    goBack() {
      this.$router.go(-1);
    },
    saveProduct() {
      this.$store.dispatch("product/saveProduct", this.form).then(res => {
        if (res.success) {
          this.toast.success("Saved");
        } else {
          this.toast.warning("Problem Saving");
        }
      });
    },
    validateDec(field) {
      // Remove any non-numeric characters
      let numericBase = this[field + 'Base']?.replace(/[^0-9]/g, "");
      numericBase = numericBase > 0 ? numericBase : ''
      const numericFrac = this[field + 'Frac'];
      const baseValue = numericBase === "" ? 0 : parseFloat(numericBase);
      const fracValue = numericFrac === "" ? 0 : parseFloat(numericFrac);
      const finalNum = baseValue + fracValue;

      this[field + 'Base'] = numericBase
      this.form[field] = finalNum;
    },
    onlyNumeric(event) {
      // Allow only digits
      const regex = /^[0-9]*$/;
      if (!regex.test(event.target.value)) {
        event.target.value = event.target.value.replace(/[^0-9]/g, '');
        this.integerValue = parseInt(event.target.value) || 0;
      }
    }
  },
  async mounted() {
    this.Code = this.form.Code = this.$route.params.Code;

    await this.$store.dispatch("product/getFilters", {options: {}});
    await this.$store.dispatch('product/setupFrameType', {method: 'get'});
    this.$store
        .dispatch("product/getProductDetails", {Code: this.$route.params.Code})
        .then(() => {
          //this.form.Code = this.product.Code
          console.log('f', this.product)
          this.form.display_name = this.product.display_name
          this.form.box_qty = this.product.box_qty
          this.form.box_nodiscount = this.product.box_nodiscount == 1 ? true : false
          this.form.denver_only = this.product.denver_only == 1 ? true : false
          this.form.frame_creator_active = this.product.frame_creator_active == 1 ? true : false
          this.form.color_family = this.product.color_family
          this.form.height = this.product.height
          if (this.isNumeric(this.form.height)) {

            this.heightBase = Math.floor(this.form.height);
            this.heightBase = this.heightBase > 0 ? this.heightBase : ''
            this.heightFrac = this.form.height - this.heightBase;
            this.heightFrac = this.heightFrac > 0 ? this.heightFrac : ''

            this.form.height = parseFloat(this.form.height)
          }
          this.form.profile_type = this.product.profile_type
          this.form.category = this.product.category
          this.form.style = this.product.style
          this.form.finish = this.product.finish
          this.form.rabbet_dec = this.product.rabbet_dec ?? this.product.rabbet
          this.form.type = this.product.type
          if (this.isNumeric(this.form.rabbet_dec)) {
            this.rabbet_decBase = Math.floor(this.form.rabbet_dec);
            this.rabbet_decBase = this.rabbet_decBase > 0 ? this.rabbet_decBase : ''
            this.rabbet_decFrac = this.form.rabbet_dec - this.rabbet_decBase;
            this.rabbet_decFrac = this.rabbet_decFrac > 0 ? this.rabbet_decFrac : ''

            this.form.rabbet_dec = parseFloat(this.form.rabbet_dec)
          }
        });
  },
};
</script>

<style scoped></style>