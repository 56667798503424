import { createStore } from 'vuex'
import userAccess from './modules/login'
import frameBuilder from "@/store/modules/framebuilder";
import user from "@/store/modules/user";
import cart from "@/store/modules/cart"
import product from "@/store/modules/products"
import orders from "@/store/modules/orders";
import Accounts from "@/store/modules/accounts";
import Payment from "@/store/modules/payment";
import System from "@/store/modules/system";
import axios from "axios";
import Reports from "@/store/modules/reports";


axios.interceptors.response.use(response => {
  if(response.status === 206 && response.data !== undefined && !response.data.mfapass){
    location.href = '/mfacode';
  }

  return response;
}, error => {
  if (error.response.status === 401) {
    //place your reentry code
    localStorage.clear()
    location.href='/login';
  }else if (error.response.status === 500) {
      console.log('500 error');
  }
  return error;
});

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    userAccess,
    frameBuilder,
    user,
    cart,
    product,
    orders,
    Accounts,
    Payment,
    System,
    Reports
  }
})
