<template>
  <div class="row" v-for="(name,idx) in disArr" :key="idx">
    <div class="col-3">
      <label for="name">{{idx}}</label>
    </div>
    <div class="col-9">
      <div class="form-group">
        <input class="form-control discountLbl"  :id="'name-' + idx" v-model="disArr[idx]" @keyup="updateData()"/>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "DiscountList",
  data() {
    return {
      disArr: []
    };
  },
  emits:{
    updateDiscount:null
  },
  computed: {
    ...mapGetters({
      'discountData': 'Accounts/getDiscountData',
    }),

  },
  methods: {
    updateData(){
      this.$emit('updateDiscount', this.disArr)
    }
  },
  mounted() {
    this.disArr = this.discountArr
    this.$store.dispatch('Accounts/discountNames').then(() => {
      this.disArr = this.discountData
      this.$emit('updateDiscount', this.disArr)
    })
  }
};
</script>
<style scoped>

</style>