import {createRouter, createWebHistory} from 'vue-router'
import store from "@/store";
import AdminView from "@/components/Admin/AdminView";
import Dashboard from "@/components/Admin/Dashboard";
import Login from "@/components/Login";
import Mfa from "@/components/Mfa.vue";
import Orders from "@/components/Admin/Orders.vue";
import Accounts from "@/components/Admin/Accounts.vue";
import AccountDetails from "@/components/Admin/AccountDetails.vue";
import OrderDetails from "@/components/Admin/OrderDetails.vue";
import InvoiceDetails from "@/components/Admin/InvoiceDetails.vue";
import Users from "@/components/Admin/Users.vue";
import UserEdit from "@/components/Admin/UserEdit.vue";
import NotFound from "@/components/Admin/NotFound.vue";
import ForgotPassword from "@/components/Admin/ForgotPassword.vue";
import ResetPassword from "@/components/Admin/ResetPassword.vue";
import FrameBuilder from "@/components/Admin/FrameBuilder.vue";
import Products from "@/components/Admin/Products.vue";
import ProductsEdit from "@/components/Admin/ProductsEdit.vue";
import Collections from "@/components/Admin/Collections.vue";
import CollectionsEdit from "@/components/Admin/CollectionsEdit.vue";
import PaymentBatch from "@/components/Admin/PaymentBatch.vue";
import PdfList from "@/components/Admin/PdfList.vue";
import Sync from "@/components/Admin/Sync.vue";
import SiteConfig from "@/components/Admin/SiteConfig.vue";
import Reports from "@/components/Admin/Reports.vue";
import Policys from "@/components/Admin/Policys.vue";
import WhoWeAre from "@/components/Admin/WhoWeAre.vue";
import Career from "@/components/Admin/Career.vue";

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/forgot_password',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  {
    path: '/reset-password/:token/:email',
    name: 'ResetPassword',
    component: ResetPassword,
    props: true
  },
  {
    path: '/',
    name: 'LoginHome',
    component: Login
  },
  {
    path: '/mfacode',
    name: 'mfacode',
    component: Mfa
  },
  {
    path: '/Admin',
    component: AdminView,
    children: [
      {
        path: 'Dashboard',
        name: 'AdminDashboard',
        component: Dashboard
      },
      {
        path: '/Siteconfig',
        name: 'Siteconfig',
        component: SiteConfig,
        meta: {
          roles: [1]
        }
      },
      {
        path: '/Policys',
        name: 'Policys',
        component: Policys,
        meta: {
          roles: [1]
        }
      },
      {
        path: '/WhoWeAre',
        name: 'WhoWeAre',
        component: WhoWeAre,
        meta: {
          roles: [1]
        }
      },
      {
        path: '/Sync',
        name: 'Sync',
        component: Sync,
        meta: {
          roles: [1]
        }
      },
      {
        path: '/PdfList',
        name: 'PdfList',
        component: PdfList,
        meta: {
          roles: [1]
        }
      },
      {
        path: '/CareerList',
        name: 'CareerList',
        component: Career,
        meta: {
          roles: [1]
        }
      },
      {
        path: '/Reports',
        name: 'Reports',
        component: Reports,
        meta: {
          roles: [1]
        }
      },
      {
        path: 'Orders',
        name: 'Orders',
        component: Orders,
        meta: {
          showProgressBar: true,
          roles:[1,4]
        }
      },
      {
        path: 'PaymentBatch',
        name: 'PaymentBatch',
        component: PaymentBatch,
        props: true,
        meta: {
          roles: [1]
        }
      },
      {
        path: 'OrderDetails/:orderid',
        name: 'OrderDetails',
        component: OrderDetails,
        props: true,
        meta: {
          roles: [1,4]
        }
      },
      {
        path: 'InvoiceDetails/:invoiceid',
        name: 'InvoiceDetails',
        component: InvoiceDetails,
        props: true,
        meta: {
          roles: [1,4]
        }
      },
      {
        path: 'Accounts',
        name: 'Accounts',
        component: Accounts,
        meta: {
          roles: [1,4]
        }
      },
      {
        path: 'Users',
        name: 'Users',
        component: Users,
        meta: {
          roles: [1]
        }
      },
      {
        path: 'UserEdit/:userid?',
        name: 'UserEdit',
        props: true,
        component: UserEdit,
        meta: {
          roles: [1]
        }
      },
      {
        path: 'AccountDetails/:accountid',
        name: 'AccountDetails',
        component: AccountDetails,
        props: true,
        meta: {
          roles: [1,4]
        }
      },{
        path: 'FrameBuilder/:Code',
        name: 'FrameBuilder',
        component: FrameBuilder,
        props: true,
        meta: {
          roles: [1]
        }
      },{
        path: 'Products',
        name: 'AdminProducts',
        component: Products,
        props:true,
        meta: {
          roles: [1]
        }
      },
      {
        path: 'Products/Edit/:Code',
        name: 'ProductEdit',
        component: ProductsEdit,
        props: true,
        meta: {
          roles: [1]
        }
      },{
        path: 'Collections',
        name: 'AdminCollections',
        component: Collections,
        meta: {
          roles: [1]
        }
      },
      {
        path: 'Collections/Edit/:id?',
        name: 'CollectionsEdit',
        component: CollectionsEdit,
        props: true,
        meta: {
          roles: [1]
        }
      }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:catchAll(.*)',
    component: NotFound,
    name: 'NotFound'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = 'Ampf Admin'
  if (!to.meta.requiresAuth) {
    next()
  } else if(to.meta.requiresAuth && !!store.getters['userAccess/accessToken']){
    store.dispatch('userAccess/isAuthenticated').then(() => {
      let userRoles = store.getters['userAccess/user'].roles
      if(to.meta.roles !== undefined && to.meta.roles.length > 0) {
        const hasPermission = userRoles.filter(obj => to.meta.roles.includes(obj.id));
        if (hasPermission.length > 0) {
          next();
        } else {
          next({name: 'Login'})
        }
      }else {
        next();
      }
    })
  }else{
    next({name: 'Login'})
  }
})


export default router
