<template>
  <div>
    <div class="card" v-if="!filter">
      <div class="card-header">
        <h2 class="card-title">Payment Batch</h2>
        <!-- /.card-tools -->
      </div>
      <!-- /.card-header -->
      <div class="card-body">

        <div class="row">
          <div class="col-sm-2">
            <div class="form-group">
              <label for="batchnum">Batch #</label>
              <input id="batchnum" type="text" class="form-control" v-model="search.batchId">
            </div>
          </div>
          <div class="col-sm-4">

            <div class="form-group">
              <label for="batchnum">Status</label>
              <Multiselect
                  v-model="search.batchStatus"
                  :options="paymentStatusData"
                  mode="tags"
                  :close-on-select="false"
                  :searchable="true"
                  :create-option="true"
              />
            </div>

          </div>
        </div>
      </div>

      <div class="card-footer">
        <button class="btn btn-primary float-right" @click.prevent="runSearch">Search</button>
      </div>
    </div>

    <table id="accounts1" class="table table-bordered table-striped">
      <thead>
      <tr>
        <th>Payment Id</th>
        <th>Batch Id</th>
        <th>Invoice Id</th>
        <th>Terms</th>
        <th>Status</th>
        <th>Reason</th>
        <th>Created</th>
        <th>Updated</th>
      </tr>
      </thead>
      <tbody>

      </tbody>
    </table>

  </div>
</template>

<script>
import $ from 'jquery'
import {mapGetters} from "vuex";
import Multiselect from '@vueform/multiselect'

export default {
  name: "PaymentBatch",
  components: {
    Multiselect,
  },
  data() {
    return {
      perPage: 50,
      search: {
        batchId: null,
        batchStatus: []
      },
      options: {dom: '<"top"f>rt<"bottom"ilp><"clear">', buttons: ['csv']}
    }
  },

  computed: {
    ...mapGetters({
      'accountData': 'Accounts/getAccounts',
      'paymentStatusData': 'Payment/getPaymentStatusData'
    }),
  },
  methods: {
    convertToEST(utcdate) {
      const options = {
        timeZone: "America/New_York",
        timeZoneName: "short"
      };
      return new Date(utcdate).toLocaleString("en-US", options);
    },
    goTo(rl) {
      this.$router.push(rl)
    },
    onint() {
      $("#accounts1_wrapper input[type='search']").off();
      // Use return key to trigger search
      $("#accounts1_wrapper input[type='search']").on("keyup", function (evt) {
        this.search = $(this).val();
        if (evt.keyCode == 13) {
          $("#accounts1").DataTable().draw();
        }
      });
    },
    runSearch() {
      //this.search = $('[aria-controls="accounts1"]').val();

      //$("#accounts1").DataTable().search(this.search).draw();
      $("#accounts1").DataTable().search('').draw();
    },
    loadData() {
      let self = this
      $("#accounts1").DataTable({
        "info": "Showing _START_ to _END_ of _TOTAL_ entries",
        ajax: function (data, callback) {
          let sPage = (parseInt($("#accounts1").DataTable().page()) + 1);
          let search = $("#accounts1_wrapper input[type='search']").val();
          let orderCol = data.columns[data.order[0].column].data;
          let orderDir = data.order[0].dir;

          self.$store.dispatch('Payment/paymentBatchData', {
            options: {
              page: sPage,
              perPage: self.perPage,
              search,
              orderCol,
              orderDir, ...self.search
            }
          }).then(adata => {
            const tmpJson = {
              recordsTotal: adata.total, // expected by DataTables to create pagination
              recordsFiltered: adata.total, // expected by DataTables to create pagination
              data: adata.data, // expected by DataTables to populate the table
              currentPage: adata.current_page // added by me to easily manage correct page displaying
            }

            callback(
                tmpJson
            )
          });
        },
        "initComplete": function () {
          self.onint();
        },
        processing: true,
        serverSide: true,
        "pageLength": self.perPage,
        order: [[0, 'desc']],
        "columns": [
          {"data": "id"},
          {"data": "payment_batch_id"},
          {
            "data": 'invoice_id',
            "render": function (data, type, row) {
              return '<a href="/Admin/InvoiceDetails/' + row.invoice_id + '">' + row.invoice_id + '</a>';
            }
          },
          {"data": "Terms"},
          {
            "data": 'status',
            "render": function (data, type, row) {
              return row.payment_status !== null ? row.payment_status.name : 'In-Process';
            }
          },
          {
            "data": null,
            "render": function (data, type, row) {
              let disp = '';
              let fdata = JSON.parse(row.rawdata)
              if (fdata !== null &&
                  fdata.transactionResponse !== undefined &&
                  fdata.transactionResponse.responseCode !== "1") {
                if (fdata.transactionResponse.messages !== undefined) {
                  disp = fdata.transactionResponse.messages[0].description
                } else if (fdata.transactionResponse.errors !== undefined) {
                  disp = fdata.transactionResponse.errors[0].errorText
                }
              }
              return disp;
            }
          },
          {
            "data": 'created_at',
            "render": function (data, type, row) {
              return self.convertToEST(row.created_at);
            }
          },
          {
            "data": 'updated_at',
            "render": function (data, type, row) {
              let upat = '';
              if(row.updated_at !== null){
                upat = self.convertToEST(row.updated_at);
              }
              return upat;
            }
          },
        ],
        "responsive": true, "lengthChange": false, "autoWidth": false,
        "buttons": ["copy", "csv", "excel", "pdf", "print", "colvis"],
      }).buttons().container().appendTo('#accounts1_wrapper .col-md-6:eq(0)');

    }
  },
  mounted() {
    this.loadData()
    this.$store.dispatch('Payment/paymentStatus')
  },
  beforeRouteLeave() {
      $("#accounts1").DataTable().destroy();
  }
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>




