<template>
  <div>
    <div class="card card-outline card-primary">
      <div class="card-header text-bold">Add PDF's</div>
      <div class="card-body">
        <div class="row mb-3" >
          <div class="col-3">
            <label for="name" class="form-label">Name</label>
            <input type="text" id="name" v-model="form.name" class="form-control" required>
            <p class="alert alert-danger" role="alert" v-show="v$.form.name.$error">Name is required</p>
          </div>
          <div class="col-2">
            <label for="category" class="form-label">Category</label>
            <select name="category" id="category" v-model="form.category_id" class="form-control" required>
              <option v-for="(value, key) in category" :key="key" :value="key">{{value}}</option>
            </select>

            <p class="alert alert-danger" role="alert" v-show="v$.form.category_id.$error">Name is required</p>
          </div>
          <div class="col-2">
            <label for="category" class="form-label">Location</label>
            <select name="locations" id="locations" v-model="form.locations" class="form-control" required>
              <option value="">All</option>
              <option value="G">Denver Only</option>
            </select>

            <p class="alert alert-danger" role="alert" v-show="v$.form.category_id.$error">Name is required</p>
          </div>

          <!--
          <div class="col-4 form-check">
            <input type="checkbox" id="active" v-model="form.active" class="form-check-input">
            <label for="active" class="form-check-label">Active</label>
          </div>
          -->
        </div>
        <div class="row">
          <div class="col-6 form-check">
            <input type="file" @change="handleFileUpload">
            <p class="alert alert-danger" role="alert" v-show="v$.form.data.$error">File is required</p>
          </div>
          <div class="mb-6 form-check">
            <button type="submit" class="btn btn-primary" @click.prevent="uploadPdf">Upload</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {required} from '@vuelidate/validators'

export default {
  setup: () => ({v$: useVuelidate()}),
  name: "PdfFileUpload",
  data() {
    return {
      defaultImg: '',
      productImg: '',
      category: {1: 'Promos', 2: 'Updates', 3: 'Discontinued Items'},
      form: {
        name: '',
        category_id: '',
        locations: '',
        active: true,
        data:''
      }
    };
  },
  validations() {
    return {
      form: {
        name: {required},
        category_id: {required},
        data: {required},
      }
    }
  },
  methods: {
    readFileData(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
          const rawData = event.target.result;
          resolve(rawData);
        };

        reader.onerror = (event) => {
          reject(event.target.error);
        };

        reader.readAsDataURL(file);
      });
    },
    handleFileUpload(e) {
      //const file = event.target.files[0];
      let file = '';
      if (e instanceof File) {
        file = e;
      } else if (e.target.files === undefined) {
        file = e.dataTransfer.files[0];
      } else {
        file = e.target.files[0]
      }

      if (file.type.indexOf('application/pdf') === -1) {
        alert('Please select a pdf file');
        return;
      }

      this.readFileData(file)
          .then((rawData) => {
            // Do something with the raw file data
            this.form.data = rawData
            //console.log(rawData);
          })
          .catch((error) => {
            // Handle any errors that occurred during file reading
            console.error(error);
          });
    },
    async uploadPdf() {
      const result = await this.v$.$validate()
      if (!result) {
        return
      }
      this.$store.dispatch('System/uploadPDF', this.form).then(() => {
        location.reload()
      });

    },
    hideMainImg() {
      this.mainImg = false
    },
    imageUrlAlt(event) {
      event.target.src = this.defaultImg;
    },
  }
}
</script>

<style scoped>

.mainImg {
  width: 300px;
}

</style>