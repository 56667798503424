import axios from 'axios'

export default {
    namespaced: true,
    state: {
        accountData: {},
        accountDetails:{},
        locationData:{},
        diccountData:[]
    },
    getters: {
        getDiscountData(state){
            return state.diccountData;
        },
        getLocation(state){
            return state.locationData
        },
        getAccounts(state){
            return state.accountData
        },
        getAccountDetails(state){
            return state.accountDetails
        },
    },
    actions: {

        getAccountData({commit},{options}){
            return new Promise((resolve, reject) => {
                let data = { ...options }
                let config = {
                    method: 'get',
                    url: process.env.VUE_APP_API_SERVER + '/admin/listAccounts',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    params: data
                }
                axios(config)
                    .then(response => {
                        commit('setAccountData',response.data.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },

        accountDetails({commit},options){
            return new Promise((resolve, reject) => {
                let data = { ...options }
                let config = {
                    method: 'get',
                    url: process.env.VUE_APP_API_SERVER + '/admin/accountDetails',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    params: data
                }

                axios(config)
                    .then(response => {
                        commit('setAccountDetails',response.data.data)
                        resolve(response.data.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },

        deleteMethod({getters,commit},options){
            return new Promise((resolve, reject) => {
                let data = {}
                let config = {
                    method: 'delete',
                    url: process.env.VUE_APP_API_SERVER + '/admin/accountBillingMethods/'+options.billingid,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    params: data
                }

                axios(config)
                    .then(response => {
                        let adetailsbill = getters.getAccountDetails;
                        adetailsbill.billing = adetailsbill.billing.filter(function( obj ) {
                            return obj.id !== options.billingid;
                        });

                        commit('setAccountDetails',adetailsbill)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },

        addMethod({getters,commit},formdata){
            return new Promise((resolve, reject) => {
                let config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/admin/accountBillingMethods',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: formdata
                }

                axios(config)
                    .then(response => {
                        let adetails = getters.getAccountDetails;

                        const isSuccess = response.data.billing.paymentSuccess !== undefined && response.data.billing.paymentSuccess;
                        const isLegacySuccess = response.data.success !== undefined && response.data.success;

                        if (isSuccess || isLegacySuccess) {
                            adetails.billing.push(response.data.billing);
                        }

                        commit('setAccountDetails',adetails)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        updateMethod(_,options){
            return new Promise((resolve, reject) => {
                let config = {
                    method: 'put',
                    url: process.env.VUE_APP_API_SERVER + '/admin/accountBillingMethods/'+options.id,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: {...options}
                }

                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        updateAcctCustData(_,options){
            return new Promise((resolve, reject) => {
                console.log('opt', options)
                let config = {
                    method: 'put',
                    url: process.env.VUE_APP_API_SERVER + '/admin/account/'+options.id,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: {...options}
                }

                axios(config)
                    .then(response => {
                        console.log('rdata',response.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        updateMethodDefault(_,options){
            return new Promise((resolve, reject) => {
                let config = {
                    method: 'put',
                    url: process.env.VUE_APP_API_SERVER + '/admin/accountBillingMethods/'+options.id,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: {...options}
                }

                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        syncAccount(_,options){
            return new Promise((resolve, reject) => {
                let config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/admin/syncAccount',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: {...options}
                }

                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        discountNames({commit}){
            return new Promise((resolve, reject) => {
                let data = {}
                var config = {
                    method: 'get',
                    url: process.env.VUE_APP_API_SERVER + '/discountlbl',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    params: data
                }
                axios(config)
                    .then(response => {
                        commit('setDiscountData',response.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        discountUpdateNames(_,data){
            return new Promise((resolve, reject) => {

                var config = {
                    method: 'put',
                    url: process.env.VUE_APP_API_SERVER + '/admin/discountlbl',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    params: data
                }

                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        getTokenXCreds(){
            return new Promise((resolve, reject) => {
                let data = {}
                let config = {
                    method: 'get',
                    url: process.env.VUE_APP_API_SERVER + '/admin/accountBToken',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    params: data
                }

                axios(config)
                    .then(response => {
                        resolve(response.data.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },

        setLocations({commit}){
            return new Promise((resolve, reject) => {
                let data = { }
                let config = {
                    method: 'get',
                    url: process.env.VUE_APP_API_SERVER + '/admin/locations',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    params: data
                }

                axios(config)
                    .then(response => {
                        commit('setLocationData',response.data.data)
                        resolve(response.data.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },



    },
    mutations: {
        setDiscountData(state, rp){
            state.diccountData = !rp ? {} : rp
        },
        setLocationData(state, locationData){
            state.locationData = !locationData ? {} : locationData
        },
        setAccountData(state, accountData){
            state.accountData = !accountData ? {} : accountData
        },
        setAccountDetails(state, accountData){
            state.accountDetails = !accountData ? {} : accountData
        },
    }
}