<template>
  <div>
    <div class="row" v-for="(name,idx) in proTArr" :key="idx">
      <div class="col-1">
        <label for="name">{{ idx }}</label>
      </div>
      <div class="col-7">
        <div class="form-group">
          <input class="form-control discountLbl" :id="'name-' + idx" v-model="proTArr[idx]" @keyup="updatePT()"/>
        </div>
      </div>
      <div class="col-4">
        <button class="btn btn-danger" @click="deletePT(idx)">
          <span class="spinner-border spinner-border-sm spinDel d-none" :id="'spinnerSP_'+idx" role="status" aria-hidden="true"></span>
          Delete
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-right">
        <button class="btn btn-primary" @click="addPT">
          <span class="spinner-border spinner-border-sm spinAdd d-none" id="spinnerAdd" role="status" aria-hidden="true"></span>
          Add
          </button>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "ProfileType",
  data() {
    return {
      proTArr: []
    };
  },
  emits: {
    updateProfileType: null
  },
  computed: {
    ...mapGetters({
      'profileTypeData': 'product/getProfileTypeData',
    }),

  },
  methods: {
    addPT() {
      document.querySelector('#spinnerAdd').classList.remove('d-none');
      this.$store.dispatch('product/addProfileType',{name:'*******'}).then(rez => {
        this.proTArr[rez.id] = rez.name
        this.$emit('updateProfileType', this.proTArr)
        document.querySelector('#spinnerAdd').classList.add('d-none');
      })
    },
    deletePT(id) {
      document.querySelector('#spinnerSP_'+id).classList.remove('d-none');
      this.$store.dispatch('product/deleteProfileType',{id}).then(rez => {
        if(rez.success) {
          delete this.proTArr[id]
          this.$emit('updateProfileType', this.proTArr)
        }
        document.querySelector('#spinnerSP_'+id).classList.add('d-none');
      })
    },
    updatePT() {
      this.$emit('updateProfileType', this.proTArr)
    },
  },
  mounted() {
    this.proTArr = this.profileTypeData
    this.$store.dispatch('product/getProfileType').then(() => {
      this.proTArr = this.profileTypeData
      this.$emit('updateProfileType', this.proTArr)
    })
  }
};
</script>
<style scoped>

.spinDel{
  position: absolute;
  left:35px;
  top:10px;
}

.spinAdd{
  position: absolute;
  top:10px;
}

</style>