<template>
  <div>
    <div class="header">
        <router-link :to="{ name: 'ProductEdit', params: { Code }}">Back</router-link>

        <h3>Frame Creator Image for {{Code}}</h3>
    </div>

    <input
      style="visibility: hidden"
      ref="input"
      type="file"
      name="image"
      accept="image/*"
      @change="setImage"
    />

    <div class="content">
      <div class="row">
        <div class="col-xs-9">
          <div class="row">
            <div class="col-xs-4">
              <div class="form-group">
                <label for="input1">Width</label>
                <input
                  type="text"
                  id="input1"
                  class="form-control"
                  name="fWidth"
                  v-model="frameOptions.fWidth"
                  placeholder="Width"
                />
              </div>
            </div>
            <div class="col-xs-4">
              <div class="form-group">
                <label for="input2">Height</label>
                <input
                  type="text"
                  id="input2"
                  class="form-control"
                  name="fHeight"
                  v-model="frameOptions.fHeight"
                  placeholder="Height"
                />
              </div>
            </div>
            <div class="col-xs-4">
              <div class="form-group">
                <label for="input3">Frame Size</label>
                <input
                  type="text"
                  id="input3"
                  class="form-control"
                  name="fSize"
                  v-model="frameOptions.fSize"
                  placeholder="Frame Size"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12">
              <section class="cropper-area">
                <div class="img-cropper">
                  <vue-cropper
                    v-show="imgSrc"
                    ref="cropper"
                    :src="imgSrc"
                    preview=".preview"
                    :viewMode="0"
                    :minContainerHeight="400"
                    :minContainerWidth="400"
                    :minCanvasWidth="400"
                    :minCanvasHeight="400"
                  />
                </div>

                <div class="actions">
                  <a href="#" role="button" @click.prevent="zoom(0.2)">
                    Zoom In
                  </a>
                  <a href="#" role="button" @click.prevent="zoom(-0.2)">
                    Zoom Out
                  </a>
                  <a href="#" role="button" @click.prevent="move(-10, 0)">
                    Move Left
                  </a>
                  <a href="#" role="button" @click.prevent="move(10, 0)">
                    Move Right
                  </a>
                  <br />
                  <a href="#" role="button" @click.prevent="move(0, -10)">
                    Move Down
                  </a>
                  <a href="#" role="button" @click.prevent="move(0, 10)">
                    Move Up
                  </a>
                  <a href="#" role="button" @click.prevent="rotate(-90)">
                    Rotate -90 deg
                  </a>
                  <a href="#" role="button" @click.prevent="rotate(90)">
                    Rotate +90 deg
                  </a>
                  <a href="#" role="button" @click.prevent="rotate(-0.1)">
                    Rotate Left -.1 deg
                  </a>
                  <a href="#" role="button" @click.prevent="rotate(0.1)">
                    Rotate Right +.1 deg
                  </a>
                  <a ref="flipX" href="#" role="button" @click.prevent="flipX">
                    Flip X
                  </a>
                  <a ref="flipY" href="#" role="button" @click.prevent="flipY">
                    Flip Y
                  </a>
                  <!--
                  <a href="#" role="button" @click.prevent="reset"> Reset </a>
                  <a href="#" role="button" @click.prevent="getData">
                    Get Data
                  </a>

                  <a href="#" role="button" @click.prevent="setData">
                    Set Data
                  </a>
                  <a href="#" role="button" @click.prevent="getCropBoxData">
                    Get CropBox Data
                  </a>
                  <a href="#" role="button" @click.prevent="setCropBoxData">
                    Set CropBox Data
                  </a>
                    -->
                </div>
              </section>
            </div>
          </div>
          <div class="row">
            <div class="actions">
              <div class="col-xs-12">
                <a href="#" role="button" @click.prevent="cropImage"> Crop </a>

                <a href="#" role="button" @click.prevent="showFileChooser">
                  Upload Image
                </a>
                <a href="#" role="button" v-if="this.cropImg" @click.prevent="saveFrame">
                  Save Frame
                </a>
                <a href="#" role="button" v-else style="background-color:grey;" @click.prevent="noclick">
                  Save Frame
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-3">
          <div class="row">
            <div class="col-xs-12">
              <section class="preview-area">
                <p>Preview</p>
                <div class="preview-outer">
                  <div class="preview" />
                </div>
                <p>Cropped Image</p>
                <div class="cropped-outer">
                  <div class="cropped-image">
                    <img v-if="cropImg" :src="cropImg" alt="Cropped Image" />
                    <div v-else class="crop-placeholder" />
                  </div>
                </div>
                <div>
                  <p>Frame Example</p>
                  <div class="frameexp-outer">
                    <img :src="outFile" alt="" :key="outFileKey" />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import {mapGetters} from "vuex";

export default {
  components: {
    VueCropper,
  },
  props: ["Code"],
  data() {
    return {
      loader: "",
      color: "#00ab00",
      bgColor: "#4b4b4b",
      imgSrc: "",
      cropImg: "",
      outFile: "",
      outFileKey: 1,
      data: null,
      frameOptions: {
        fWidth: 30,
        fHeight: 20,
        fSize: 3,
      },
    };
  },
  computed: {
    ...mapGetters({
      product: "product/getProductDetail",
    }),
  },
  methods: {
    noclick(){
      return false;
    },
    saveFrame() {
      if (this.cropImg) {
        this.loader = this.$loading.show({
          container: this.fullPage ? null : this.$refs.formContainer,
          canCancel: this.canCancel,
          onCancel: this.whenCancelled,
          color: this.color,
          backgroundColor: this.bgColor,
          height: this.height,
          width: this.width,
          loader: 'dots',
          opacity: 1,
        });

        this.$store
          .dispatch("product/saveFrame", {
            imgData: this.cropImg,
            Code: this.Code,
          })
          .then(() => {
            this.loader.hide()
          });
      } else {
        console.log("no image");
      }
    },
    cropImage() {
      //let cropdata = this.$refs.cropper.getCropBoxData();

      let options = {
        fWidth: this.frameOptions.fWidth,
        fHeight: this.frameOptions.fHeight,
        fSize: this.frameOptions.fSize,
      };
      // get image data for post processing, e.g. upload or setting image src
      let imgDataU = this.$refs.cropper.getCroppedCanvas().toDataURL();
      //var imgData = window.btoa(imgDataU)

      let imgData = imgDataU;
      this.$store
        .dispatch("frameBuilder/createFrame", { imgData, options })
        .then((data) => {
          this.outFile = data.outFile;
          this.outFileKey = this.$moment();
        });
      this.cropImg = imgDataU;
    },
    flipX() {
      const dom = this.$refs.flipX;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleX(scale);
      dom.setAttribute("data-scale", scale);
    },
    flipY() {
      const dom = this.$refs.flipY;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleY(scale);
      dom.setAttribute("data-scale", scale);
    },
    getCropBoxData() {
      this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
    },
    getData() {
      this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
    },
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY);
    },
    reset() {
      this.$refs.cropper.reset();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },
    setCropBoxData() {
      if (!this.data) return;

      this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
    },
    setData() {
      if (!this.data) return;

      this.$refs.cropper.setData(JSON.parse(this.data));
    },
    setImage(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
          this.$refs.cropper.relativeZoom(100);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
    async getImageAsBlob(url) {
      try {
        // Fetch the image from the URL
        const response = await fetch(url,
            {mode: 'no-cors',
                 method: 'GET'
                }
        );

        // Check if the fetch was successful
        if (!response.ok) {
          //throw new Error('Network response was not ok');
          return 'network';
        }

        // Get the image data as a Blob
        const blob = await response.blob();
        return blob;
      } catch (error) {
        console.error('Error fetching the image:', error);
        return null;
      }
    },
    imageUrl() {
      let randomInteger = Math.floor(Math.random() * 10);

      let loc = process.env.VUE_APP_NON_CDN + "/frames/" + this.Code + "/" + this.Code + "-small.jpg?v="+randomInteger;
      if(this.product.FrameType === 'MATS'){
        loc = process.env.VUE_APP_NON_CDN + "/mat/" + this.product.ManufCode + "/" + this.product.ManufCode + "-small.jpg?v="+randomInteger;
      }

      //return this.productImg ? this.productImg : process.env.VUE_APP_NON_CDN + "/frames/" + this.Code + '/' + this.Code  + "-small.jpg?v="+randomInteger;
      return loc;
    },
    async getBlobDataFromImgTag() {
      // Get the image element
      const image = document.getElementById('myImage');

      // Fetch the image data as a Blob
      const response = await fetch(image.src);
      const blobData = await response.blob();

      // Now you have the image data as a Blob object, and you can use it as needed

      // Example: Create a URL from the Blob to use it as an image source
      const blobURL = URL.createObjectURL(blobData);

      // Example: Display the image using the Blob URL
      const displayImage = new Image();
      displayImage.src = blobURL;
      document.body.appendChild(displayImage);
    }
  },
  mounted() {
    this.$store.dispatch("frameBuilder/hasFrameImg", this.Code).then((data) => {
      if (data.imgData) {
        this.cropImg = data.imgData;
      }else{
        //if(Object.keys(this.product).length === 0){
          this.$store.dispatch("product/getProductDetails", {Code: this.Code, sendImg: true}).then(async () => {
            if(this.product.search_img !== ''){
              let imageLoc = 'data:image/jpeg;base64,'+this.product.image;
              this.imgSrc = imageLoc;
              // rebuild cropperjs with the updated source
              this.$refs.cropper.replace(imageLoc);
              //this.$refs.cropper.relativeZoom(100);
            }
          });
        //}
      }
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.img-cropper {
  max-width: 550px;
  border: 1px solid gray;
  min-height: 400px;
  background-color: #ffffff;
}

#input1,
#input2,
#input3 {
  width: 150px;
}

.actions {
  margin-top: 1rem;
  width: 600px;
}

.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062cc;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.preview-area {
  width: 307px;
}

.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}

.preview-area p:last-of-type {
  margin-top: 1rem;
}

.preview {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}

.preview-outer {
  padding: 10px;
  border: 1px solid gray;
  min-width: 400px;
  min-height: 100px;
  background-color: #ffffff;
}

.cropped-outer {
  padding: 10px;
  border: 1px solid gray;
  min-width: 400px;
  min-height: 100px;
  background-color: #ffffff;
}

.frameexp-outer {
  padding: 10px;
  border: 1px solid gray;
  min-width: 400px;
  min-height: 100px;
  background-color: #ffffff;
  text-align: center;
}

/*
body {
font-family: Arial, Helvetica, sans-serif;
width: 1024px;
margin: 0 auto;
}

input[type="file"] {
display: none;
}

.header {
display: flex;
justify-content: space-between;
align-items: center;
padding: 10px 0 5px 0;
}

.header h2 {
margin: 0;
}

.header a {
text-decoration: none;
color: black;
}

.content {
display: flex;
justify-content: space-between;
}


.cropper-area {
width: 614px;
height: 500px;
}

.actions {
margin-top: 1rem;
}

.actions a {
display: inline-block;
padding: 5px 15px;
background: #0062CC;
color: white;
text-decoration: none;
border-radius: 3px;
margin-right: 1rem;
margin-bottom: 1rem;
}

textarea {
width: 100%;
height: 100px;
}

.preview-area {
width: 307px;
}

.preview-area p {
font-size: 1.25rem;
margin: 0;
margin-bottom: 1rem;
}

.preview-area p:last-of-type {
margin-top: 1rem;
}

.preview {
width: 100%;
height: calc(372px * (9 / 16));
overflow: hidden;
}

.crop-placeholder {
width: 100%;
height: 200px;
background: #ccc;
}

.cropped-image img {
max-width: 100%;
}
*/
</style>
