<template>
  <div>
    <div class="row">
      <div class="col-8"></div>
      <div class="col-2 text-center"><span class="vertical-text">Search</span></div>
      <div class="col-2 text-center"><span class="vertical-text">Active</span></div>
    </div>
    <div class="row" v-for="(frame,idx) in frameTypeData" :key="idx">
      <div class="col-1">
        <label for="name">{{ (idx + 1) }}</label>
      </div>
      <div class="col-7">
        <div class="form-group">
          <input type="text" class="form-control discountLbl" :id="'name-' + idx" v-model="frame.name" @keyup="updateF()"/>
        </div>
      </div>
      <div class="col-2">
        <div class="form-group">
          <input type="checkbox" class="form-control" :id="'name-' + idx" v-model="frame.search" @change="updateF()"/>
        </div>
      </div>
      <div class="col-2">
        <div class="form-group">
          <input type="checkbox" class="form-control" :id="'name-' + idx" v-model="frame.active" @change="updateF()"/>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-right">
        <button class="btn btn-primary" @click="addF">
          <span class="spinner-border spinner-border-sm spinAdd d-none" id="spinnerAddF" role="status" aria-hidden="true"></span>
          Add
          </button>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "FrameTypes",
  data() {
    return {
    };
  },
  emits: {
    updateFrameType: null
  },
  computed: {
    ...mapGetters({
      'frameTypeData': 'product/getFrameTypeData',
    }),

  },
  methods: {
    addF() {
      document.querySelector('#spinnerAddF').classList.remove('d-none');
      console.log('bbbbbbbbbb',this.frameTypeData)
      this.frameTypeData.push({name:'',search:false,active:false})
      this.$store.commit('product/setFrameType',this.frameTypeData);
      /*
      this.$store.dispatch('product/setupFinish',{data: {name:'*******'}, method:'post'}).then(rez => {
        this.dataArr[rez.id] = rez.name
        this.$emit('updateFinsih', this.dataArr)
        document.querySelector('#spinnerAddF').classList.add('d-none');
      })
      */
      document.querySelector('#spinnerAddF').classList.add('d-none');
    },
    updateF() {
      this.$emit('updateFrameType', this.frameTypeData)
    },
  },
  mounted() {

    //this.dataArr = this.frameTypeData
    this.$store.dispatch('product/setupFrameType',{method:'get'}).then(() => {
      //this.dataArr = this.frameTypeData
      console.log('adfasdf',this.frameTypeData)
      this.$emit('updateFrameType', this.frameTypeData)
    })
  }
};
</script>
<style scoped>

.spinDel{
  position: absolute;
  left:35px;
  top:10px;
}

.spinAdd{
  position: absolute;
  top:10px;
}

.vertical-text {
  /*
  writing-mode: vertical-rl;
  text-orientation: upright;
  */

}

</style>