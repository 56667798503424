import axios from 'axios'

export default {
    namespaced: true,
    state: {
        productData: {},
        productDetails: {},
        productLocationQty: {},
        whereToBuyData: {},
        wtbCenter: '',
        searchFilters: {},
        collectionData: {},
        collectionDetails: {},
        settingsData: {},
        profileTypeData: {},
        colorFamilyData: {},
        styleData: {},
        finishData:{},
        frameTypes:{},
        collectionPopular:{}
    },
    getters: {
        getCollectionPopular(state) {
            return state.collectionPopular
        },
        getFrameTypeData(state) {
            return state.frameTypes
        },
        getFinishData(state) {
            return state.finishData
        },
        getStyleData(state) {
            return state.styleData
        },
        getColorFamilyData(state) {
            return state.colorFamilyData
        },
        getProfileTypeData(state) {
            return state.profileTypeData
        },
        getSettingsData(state) {
            return state.settingsData
        },
        getCollectionData(state) {
            return state.collectionData
        },
        getFilterData(state) {
            return state.searchFilters
        },
        getWTBData(state) {
            return state.whereToBuyData
        },
        getWTBCenter(state) {
            return state.wtbCenter
        },
        getProducts(state) {
            return state.productData
        },
        getProductDetail(state) {
            return state.productDetails
        },
        getCollectionDetail(state) {
            return state.collectionDetails
        },
        getProductLocationQty(state) {
            return state.productLocationQty
        },
    },
    actions: {
        saveImage(_, imgData) {
            //used for Admin creation
            return new Promise((resolve, reject) => {
                let data = {...imgData}
                var config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/admin/saveImages',
                    headers: {
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                }

                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        deleteImage(_, imgData) {
            //used for Admin creation
            return new Promise((resolve, reject) => {
                let data = {...imgData}
                var config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/admin/deleteImage',
                    headers: {
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                }

                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        saveFrame(_, imgData) {
            //used for Admin creation
            return new Promise((resolve, reject) => {
                let data = {...imgData}
                var config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/framebuilder/saveFrame',
                    headers: {
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                }

                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        saveProduct(_, data) {
            //used for Admin creation
            return new Promise((resolve, reject) => {
                //let data = {...form}
                var config = {
                    method: 'put',
                    url: process.env.VUE_APP_API_SERVER + '/admin/Product/' + data.Code,
                    headers: {
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                }

                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        getWTBData({commit}, {options}) {
            return new Promise((resolve, reject) => {
                let data = {...options}
                let config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/products/whereToBuy',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                }

                axios(config)
                    .then(response => {
                        commit('setWhereToBuyData', response.data.data.locations)
                        commit('setWhereToBuyCenter', response.data.data.center)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        getFilters({commit}, {options}) {
            return new Promise((resolve, reject) => {
                let data = {...options}
                let config = {
                    method: 'get',
                    url: process.env.VUE_APP_API_SERVER + '/ProductFilters',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    params: data
                }
                axios(config)
                    .then(response => {
                        commit('setFilterData', response.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        getProductData({commit}, {options, clear}) {
            return new Promise((resolve, reject) => {
                let data = {...options}
                let config = {
                    method: 'get',
                    url: process.env.VUE_APP_API_SERVER + '/Products',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    params: data
                }

                axios(config)
                    .then(response => {
                        if (clear) {
                            commit('setProductData', response.data.data)
                        } else {
                            commit('addProductData', response.data.data)
                        }
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        updateCollection(_, options) {
            return new Promise((resolve, reject) => {
                let data = {...options}
                let config = {
                    method: 'put',
                    url: process.env.VUE_APP_API_SERVER + '/admin/Collections/' + options.id,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                }
                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        deleteCollection(_, options) {
            return new Promise((resolve, reject) => {
                let data = {...options}
                let config = {
                    method: 'delete',
                    url: process.env.VUE_APP_API_SERVER + '/admin/Collections/' + options.id,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                }
                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        addCollection(_, options) {
            return new Promise((resolve, reject) => {
                let data = {...options}
                let config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/admin/Collections',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                }
                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        collectionData({commit}, {options}) {
            return new Promise((resolve, reject) => {
                let data = {...options}
                let config = {
                    method: 'get',
                    url: process.env.VUE_APP_API_SERVER + '/admin/Collections',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    params: data
                }

                axios(config)
                    .then(response => {
                        commit('setCollectionData', response.data.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        collectionDetails({commit}, id) {
            return new Promise((resolve, reject) => {
                let data = {"id": id};

                let config = {
                    method: 'get',
                    url: process.env.VUE_APP_API_SERVER + '/admin/Collections',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    params: data
                }

                axios(config)
                    .then(response => {
                        commit('setCollectionDetails', response.data.data[0])
                        resolve(response.data.data[0])
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        settingsData({commit}) {
            return new Promise((resolve, reject) => {
                let data = {}
                let config = {
                    method: 'get',
                    url: process.env.VUE_APP_API_SERVER + '/admin/settings',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    params: data
                }

                axios(config)
                    .then(response => {
                        commit('setSettingsData', response.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        updateSettingData(_, {data, id}) {
            return new Promise((resolve, reject) => {
                let config = {
                    method: 'put',
                    url: process.env.VUE_APP_API_SERVER + '/admin/settings/' + id,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: {data}
                }

                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        updatePolicyData(_, data) {
            console.log('d',data)
            return new Promise((resolve, reject) => {
                let config = {
                    method: 'put',
                    url: process.env.VUE_APP_API_SERVER + '/admin/settings/policys',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                }

                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        updateWWRData(_, data) {
            console.log('d',data)
            return new Promise((resolve, reject) => {
                let config = {
                    method: 'put',
                    url: process.env.VUE_APP_API_SERVER + '/admin/settings/WWR',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                }

                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        getProductDetails({commit}, {Code, sendImg}) {
            return new Promise((resolve, reject) => {
                let data = JSON.stringify({Code, sendImg});
                let config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/getProductDetailsByCode',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                }

                axios(config)
                    .then(response => {
                        commit('setProductDetails', response.data.products[Code])
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        getProductLocationQty({commit}, Code) {
            return new Promise((resolve, reject) => {
                let data = {Code}
                let headers = {}
                if (this.getters['userAccess/isLoggedin']) {
                    headers = {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    }
                } else {
                    reject({'msg': 'not logged in'})
                }

                let config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/products/getLocationQty',
                    headers: headers,
                    data: data
                }

                axios(config)
                    .then(response => {
                        commit('setProductLocationQty', response.data.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        updateProfileType(_, data) {
            return new Promise((resolve, reject) => {

                var config = {
                    method: 'put',
                    url: process.env.VUE_APP_API_SERVER + '/admin/profileType',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: JSON.stringify(data)
                }

                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        getProfileType({commit}) {
            return new Promise((resolve, reject) => {
                let config = {
                    method: 'get',
                    url: process.env.VUE_APP_API_SERVER + '/admin/profileType',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    params: {}
                }

                axios(config)
                    .then(response => {
                        commit('setProfileType', response.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        deleteProfileType(_, data) {
            return new Promise((resolve, reject) => {
                let config = {
                    method: 'delete',
                    url: process.env.VUE_APP_API_SERVER + '/admin/profileType/'+data.id,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                }

                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        addProfileType(_, data) {
            return new Promise((resolve, reject) => {
                let config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/admin/profileType',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                }

                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        updateColorFamily(_, data) {
            return new Promise((resolve, reject) => {

                var config = {
                    method: 'put',
                    url: process.env.VUE_APP_API_SERVER + '/admin/colorFamily',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: JSON.stringify(data)
                }

                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        getColorFamily({commit}) {
            return new Promise((resolve, reject) => {
                let config = {
                    method: 'get',
                    url: process.env.VUE_APP_API_SERVER + '/admin/colorFamily',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    params: {}
                }

                axios(config)
                    .then(response => {
                        commit('setColorFamily', response.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        deleteColorFamily(_, data) {
            return new Promise((resolve, reject) => {
                let config = {
                    method: 'delete',
                    url: process.env.VUE_APP_API_SERVER + '/admin/colorFamily/'+data.id,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                }

                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        addColorFamily(_, data) {
            return new Promise((resolve, reject) => {
                let config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/admin/colorFamily',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                }

                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        setupStyle({commit},{data, method}) {
            return new Promise((resolve, reject) => {
                let config = {
                    method: method,
                    url: process.env.VUE_APP_API_SERVER + '/admin/style'+(method === 'delete' ? '/'+data.id:''),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                }
                if(method === 'get'){
                    config.params = data
                }else{
                    config.data = data
                }

                axios(config)
                    .then(response => {
                        if(method === 'get') {
                            commit('setStyle', response.data)
                        }
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        setupCollPop(_, data) {
            console.log('d',data)
            return new Promise((resolve, reject) => {
                let config = {
                    method: 'put',
                    url: process.env.VUE_APP_API_SERVER + '/admin/settings/collectionPopular',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                }

                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        setupFinish({commit},{data, method}) {
            return new Promise((resolve, reject) => {
                let config = {
                    method: method,
                    url: process.env.VUE_APP_API_SERVER + '/admin/finish'+(method === 'delete' ? '/'+data.id:''),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                }
                if(method === 'get'){
                    config.params = data
                }else{
                    config.data = data
                }

                axios(config)
                    .then(response => {
                        if(method === 'get') {
                            commit('setFinish', response.data)
                        }
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        setupFrameType({commit},{data, method}) {
            return new Promise((resolve, reject) => {
                let config = {
                    method: method,
                    url: process.env.VUE_APP_API_SERVER + '/admin/frameTypes'+(method === 'delete' ? '/'+data.id:''),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                }
                if(method === 'get'){
                    config.params = data
                }else{
                    config.data = data
                }

                axios(config)
                    .then(response => {
                        console.log('ft',response);
                        if(method === 'get') {
                            commit('setFrameType', response.data)
                        }
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
    },
    mutations: {
        setCollectionPopular(state, data) {
            state.collectionPopular = data
        },
        setFrameType(state, data) {
            state.frameTypes = data
        },
        setFinish(state, data) {
            state.finishData = data
        },
        setStyle(state, data) {
            state.styleData = data
        },
        setColorFamily(state, data) {
            state.colorFamilyData = data
        },
        setProfileType(state, data) {
            state.profileTypeData = data
        },
        setSettingsData(state, data) {
            state.settingsData = data
        },
        setCollectionData(state, collectionData) {
            state.collectionData = collectionData
        },
        setFilterData(state, filters) {
            state.searchFilters = filters
        },
        setWhereToBuyCenter(state, whereToBuyCenter) {
            state.wtbCenter = whereToBuyCenter
        },
        setWhereToBuyData(state, whereToBuyData) {
            state.whereToBuyData = whereToBuyData
        },
        setProductData(state, productData) {
            state.productData = productData
        },
        addProductData(state, productData) {
            state.productData = {...state.productData, ...productData}
        },
        setProductDetails(state, productDetails) {
            state.productDetails = productDetails
        },
        setCollectionDetails(state, collectionDetails) {
            state.collectionDetails = collectionDetails
        },
        setProductLocationQty(state, ploc) {
            state.productLocationQty = ploc
        },
    }
}