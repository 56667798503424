<template>
  <div>
    <PdfFileUpload></PdfFileUpload>

    <div class="card card-outline">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <table class="table">
              <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Category</th>
                <th>Location</th>
                <th>Last Updated</th>
                <th>&nbsp;</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in pdfList" :key="item?.id">
                <td>{{ item?.id }}</td>
                <!--<td><a :download="item?.name" :href="item?.data">{{ item?.name }}</a></td>-->
                <td><a href="" @click.prevent="getPdfAndDownload(item?.id,item?.name)">{{ item?.name }}</a></td>
                <td>{{ convertCategory(item?.category_id) }}</td>
                <td>{{ item.locations === 'G' ? 'Denver Only' : 'All' }}</td>
                <td>{{ convertToEST(item?.created_at) }}</td>
                <td>
                  <button class="btn btn-danger" @click.prevent="deletePDF(item?.id)">Delete</button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import PdfFileUpload from "@/components/Admin/PdfFileUpload.vue";

export default {
  name: "PdfList",
  data() {
    return {
      loader: "dots",
      color: "#00ab00",
      bgColor: "#4b4b4b",
      searchimg: '',
      fbimg: '',
      tableLoaded: false,
      category: {1: 'Promos', 2: 'Updates', 3: 'Discontinued Items'}
    }
  },
  components: {
    PdfFileUpload
  },
  computed: {
    ...mapGetters({
      'pdfList': 'System/getSyncList',
    }),
  },
  methods: {
    convertToEST(utcdate) {
      const options = {
        timeZone: "America/New_York",
        timeZoneName: "short"
      };
      return new Date(utcdate).toLocaleString("en-US", options);
    },
    convertCategory(catid) {
      return this.category[catid]
    },
    getPdfAndDownload(id, name) {
      this.$store.dispatch('System/pdfList', {id: id}).then(rez => {
        let link = document.createElement('a');
        link.href = rez.data;
        link.download = name + '.pdf';
        link.click();
      })
    },
    deletePDF(id) {
      this.loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: this.canCancel,
        onCancel: this.whenCancelled,
        color: this.color,
        backgroundColor: this.bgColor,
        height: this.height,
        width: this.width,
        loader: this.loader,
        opacity: 1,
      });
      this.$store.dispatch('System/deletePDF', id).then(() => {
        location.reload()
      });
    }
  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.fullPage ? null : this.$refs.formContainer,
      canCancel: this.canCancel,
      onCancel: this.whenCancelled,
      color: this.color,
      backgroundColor: this.bgColor,
      height: this.height,
      width: this.width,
      loader: this.loader,
      opacity: 1,
    });

    this.$store.dispatch('System/pdfList', {'list': 1}).then(() => {
      this.loader.hide()
    })
  }

}
</script>

<style scoped>

</style>