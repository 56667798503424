<template>
  <div>
    <select class="form-control colSpots" :id="'spot_'+spot">
      <option value=""></option>
      <option v-for="col in collectionData" :key="col.id" :value="col.id">
        {{col.name}}
      </option>
    </select>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
export default {
  name: "CollectionsList",
  props: ['spot'],
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters({
      'collectionData': 'product/getCollectionData',
    }),

  },
  methods: {

  }
};
</script>
<style scoped>

</style>