import axios from 'axios'

export default {
    namespaced: true,
    state: {
        userList: {},
        rolesList: {},
        userData:{}
    },
    getters: {
        getUserList(state) {
            return state.userList
        },
        getUserData(state) {
            return state.userData
        },
        getRolesList(state) {
            return state.rolesList
        },
    },
    actions: {
        getSSOToken(_, data) {
            return new Promise((resolve, reject) => {
                var config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/getSSOToken',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                }

                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        getUserList({commit}, {options}) {
            return new Promise((resolve, reject) => {
                let data = {...options}
                var config = {
                    method: 'get',
                    url: process.env.VUE_APP_API_SERVER + '/admin/users',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    params: data
                }

                axios(config)
                    .then(response => {
                        commit('setUserList', response.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        getRolesList({commit}, {options}) {
            return new Promise((resolve, reject) => {
                let data = {...options}
                var config = {
                    method: 'get',
                    url: process.env.VUE_APP_API_SERVER + '/admin/roles',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    params: data
                }

                axios(config)
                    .then(response => {
                        commit('setRolesList', response.data.data)
                        resolve(response.data.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        getUserData({commit}, {options}) {
            // Instead of the file system,
            // fetch post data from an external API endpoint
            return new Promise((resolve, reject) => {

                let data = {...options}
                let config = {
                    method: 'get',
                    url: process.env.VUE_APP_API_SERVER + '/admin/users',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    params: data
                }

                axios(config)
                    .then(response => {
                        commit('setUserData', response.data.data[0])
                        resolve(response.data.data[0]);
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject('false');
                        }
                    })

            });
        },
        addUser({commit}, {options}) {
            // Instead of the file system,
            // fetch post data from an external API endpoint
            return new Promise((resolve, reject) => {

                let data = {...options}
                let config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/admin/users',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                }

                axios(config)
                    .then(response => {
                        commit('setUserData', response.data)
                        resolve(response.data);
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject('false');
                        }
                    })
            });
        },
        updateUser(_, {options, userId}) {
            return new Promise((resolve, reject) => {
                let data = {...options}
                let config = {
                    method: 'put',
                    url: process.env.VUE_APP_API_SERVER + '/admin/users/'+userId,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                }

                axios(config)
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject('false');
                        }
                    })
            });
        },
        deleteUser(_, userId) {
            return new Promise((resolve, reject) => {
                let data = {}
                let config = {
                    method: 'delete',
                    url: process.env.VUE_APP_API_SERVER + '/admin/users/'+userId,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                }

                axios(config)
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        console.log('Error if', error)
                        reject(error.response.data);
                    })
            });
        },
    },
    mutations: {
        setUserData(state, userData) {
            state.userData = userData
        },
        setUserList(state, userList) {
            state.userList = !userList ? {} : userList
        },
        setRolesList(state, rolesList) {
            state.rolesList = !rolesList ? {} : rolesList
        },
    }
}