<template>
  <div>
    images
    <img
        v-if="mainImg"
        :src="imageUrl"
        @error="hideMainImg"
        class="mainImg"
        alt="..."
        :key="imgKey"
    />

    <input type="file" @change="handleFileUpload">
    <button class="btn btn-danger" @click.prevent="deleteImage">Delete</button>
  </div>
</template>

<script>
import Compressor from "compressorjs";

export default {
  name: "ProductImages",
  props: ["Code", "product"],
  data() {
    return {
      defaultImg: '',
      mainImg: true,
      imgKey: 0,
      productImg: ''
    };
  },
  computed: {
    imageUrl() {
      let randomInteger = Math.floor(Math.random() * 10);
      let loc = process.env.VUE_APP_NON_CDN + "/frames/" + this.Code + "/" + this.Code + "-small.jpg?v=" + randomInteger;
      if (this.product.FrameType === 'MATS') {
        loc = process.env.VUE_APP_NON_CDN + "/mat/" + this.product.ManufCode + "/" + this.product.ManufCode + "-small.jpg?v=" + randomInteger;
      }

      //return this.productImg ? this.productImg : process.env.VUE_APP_NON_CDN + "/frames/" + this.Code + '/' + this.Code  + "-small.jpg?v="+randomInteger;
      return loc;
    }
  },
  watch: {
    // Watcher for the 'myProp' prop
    product() {
      this.imgKey += 1;
      this.mainImg = true;
    }
  },
  methods: {
    handleFileUpload(e) {
      //const file = event.target.files[0];
      let file = '';
      if (e instanceof File) {
        file = e;
      } else if (e.target.files === undefined) {
        file = e.dataTransfer.files[0];
      } else {
        file = e.target.files[0]
      }

      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file');
        return;
      }

      let self = this

      new Compressor(file, {
        quality: 0.6,
        maxWidth: 600,
        mimeType: 'image/jpeg',
        // The compression process is asynchronous,
        // which means you have to access the `result` in the `success` hook function.
        success(reader) {
          let readFile = new FileReader();
          readFile.readAsDataURL(reader);
          readFile.onload = function () {
            this.imgSrc = readFile.result;
            self.uploadImage(this.imgSrc)
          }
        },
        error(err) {
          console.log(err.message);
        },
      });
    },
    uploadImage(imgSrc) {

      if (this.product.FrameType === 'MATS') {
        this.$store.dispatch('product/saveImage', {
          folder: "mat/" + this.product.ManufCode,
          Code: this.Code,
          filename: this.product.ManufCode + '-small.jpg',
          imgdata: imgSrc
        }).then(() => {
          this.imgKey += 1;
          this.productImg = process.env.VUE_APP_NON_CDN + "/mat/" + this.product.ManufCode + '/' + this.product.ManufCode + "-small.jpg?" + this.imgKey;
          this.mainImg = true
        });


      } else {
        this.$store.dispatch('product/saveImage', {
          folder: "frames/" + this.Code,
          filename: this.Code + '-small.jpg',
          Code: this.Code,
          imgdata: imgSrc
        }).then(() => {
          this.imgKey += 1;
          this.productImg = process.env.VUE_APP_NON_CDN + "/frames/" + this.Code + '/' + this.Code + "-small.jpg?" + this.imgKey;
          this.mainImg = true
        });
      }
    },
    deleteImage() {
      if (this.product.FrameType === 'MATS') {
        this.$store.dispatch('product/deleteImage', {
          folder: "mat/" + this.product.ManufCode,
          filename: this.product.ManufCode + '-small.jpg'
        }).then(() => {
          this.mainImg = false
        });
      } else {
        this.$store.dispatch('product/deleteImage', {
          folder: "frames/" + this.Code,
          filename: this.Code + '-small.jpg'
        }).then(() => {
          this.mainImg = false
        });
      }
    },
    /*
    imageUrl(Code) {
        console.log('code',this.Code)
        let loc =
            process.env.VUE_APP_CDN + "/frames/" + Code + "/" + Code + "-small.jpg";
        //console.log("the image", loc);
        return loc;
    },
     */
    hideMainImg() {
      this.mainImg = false
    },
    imageUrlAlt(event) {
      event.target.src = this.defaultImg;
    },

  },
  mounted() {
    /*
    let self = this
    setTimeout(function () {
      self.imgKey += 1;
      self.mainImg = true;
    }, 8500)
    */
  }
}
</script>

<style scoped>

.mainImg {
  width: 300px;
}

</style>