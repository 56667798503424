<template>
    <div>
        <img
                v-if="mainImg"
                :src="imageUrl"
                @error="hideMainImg"
                class="mainImg m-3"
                ref="mainImg"
                alt="..."
        />
        <input type="file" @change="handleFileUpload">
        <button class="btn btn-danger" @click.prevent="deleteImage">Delete</button>
    </div>
</template>

<script>
import Compressor from 'compressorjs';
export default {
    name: "CollectionImages",
    props: ["id"],
    data() {
        return {
            defaultImg: '',
            imgKey: 0,
            mainImg: true,
            collectionImg: ''
        };
    },
    computed:{
        imageUrl() {
            let randomInteger = Math.floor(Math.random() * 10);
            return this.collectionImg ? this.collectionImg : process.env.VUE_APP_NON_CDN + "/collections/" + this.id  + ".jpg?v="+randomInteger;
        }
    },
    methods: {
        handleFileUpload(e) {
            //const file = event.target.files[0];
            let file = '';
            if (e instanceof File) {
                file = e;
            } else if (e.target.files === undefined) {
                file = e.dataTransfer.files[0];
            } else {
                file = e.target.files[0]
            }

            if (file.type.indexOf('image/') === -1) {
                alert('Please select an image file');
                return;
            }

            let self = this

            new Compressor(file, {
                quality: 0.6,
                maxWidth: 600,
                mimeType: 'image/jpeg',
                // The compression process is asynchronous,
                // which means you have to access the `result` in the `success` hook function.
                success(reader) {
                    let readFile = new FileReader();
                    readFile.readAsDataURL(reader);
                    readFile.onload = function () {
                        this.imgSrc = readFile.result;
                        self.uploadImage(this.imgSrc)
                    }
                },
                error(err) {
                    console.log(err.message);
                },
            });
        },
        uploadImage(imgSrc){
            this.$store.dispatch('product/saveImage', {folder:"collections",filename:this.id+'.jpg',imgdata:imgSrc}).then(() => {
                this.imgKey += 1;
                this.collectionImg = process.env.VUE_APP_NON_CDN + "/collections/" + this.id  + ".jpg?"+this.imgKey;
                this.mainImg = true
            });
        },
        deleteImage(){
            this.$store.dispatch('product/deleteImage', {folder:"collections",filename:this.id+'.jpg'}).then(() => {
                this.mainImg = false
            });
        },
        hideMainImg(){
            this.mainImg=false
        },
        imageUrlAlt(event) {
            event.target.src = this.defaultImg;
        },
    },
    mounted() {

    }
}
</script>

<style scoped>

.mainImg {width:125px;}

</style>