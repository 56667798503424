//import router from 'src/routes/routes'
import axios from 'axios'
axios.defaults.withCredentials = true;
export default {
    namespaced: true,
    state: {
        accessToken: '',
        user: {},
        activeUser: '',
        invalidLogin: true
    },
    getters: {
        accessToken(state) {
            // return localStorage.getItem('access_token') === null ? state.tokenLocal : localStorage.getItem('access_token')
            return localStorage.getItem('access_token') === null ? state.accessToken : localStorage.getItem('access_token')
            //return state.accessToken
        },
        user(state) {
            //return localStorage.getItem('user') === null ? state.user : JSON.parse(localStorage.getItem('user'))
            return localStorage.getItem('user') === null ? state.user : JSON.parse(localStorage.getItem('user'))
            //return state.user
        },
        getActiveUser(state) {
            return state.activeUser
        },
        invalidLogin(state) {
            return state.invalidLogin
        },
        isLoggedin(state){
            return state.user.data !== undefined && state.user.data.login !== undefined ? state.user.data.login : false
        },
    },
    actions: {
        isAuthenticated({getters, commit}) {
            //Check with api that token is valid
            return new Promise((resolve, reject) => {
                let data = '';
                if (getters.accessToken === null || getters.accessToken === undefined) {
                    reject({error: '👾 no token'})
                }
                let token = getters.accessToken;
                let config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/tokenLogin',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token ? 'Bearer ' + token : ''
                    },
                    data: data
                };
                axios(config)
                    .then(function (response) {
                        if (response.data !== undefined && response.data.message === 'mfa required') {
                            reject({mfa:true});
                        } else if (response.data !== undefined && response.data.name === 'Error') {
                            reject(response.data.name);
                        } else if (response.data !== undefined && response.data.login) {
                            commit('setUser', response.data.data[0])
                            commit('setInvalidLogin', false)
                            resolve(response.data)
                        } else {
                            reject({error: true})
                        }
                    })
                    .catch(function (error) {
                        if (!error.status) {
                            reject(error);
                            //window.location.href = process.env.VUE_APP_ACHIEVE_SITE_LOGIN
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        passMFA({getters, commit},mfacode) {
            //Check with api that token is valid
            return new Promise((resolve, reject) => {
                let data = {mfacode}
                if (getters.accessToken === null || getters.accessToken === undefined) {
                    reject({error: '👾 no token'})
                }
                let token = getters.accessToken;
                let config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/tokenLogin',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': token ? 'Bearer ' + token : ''
                    },
                    data: data
                };

                axios(config)
                    .then(function (response) {
                        if (response.data.login) {
                            commit('setUser', response.data.data[0])
                            commit('setInvalidLogin', false)
                            resolve(response)
                        }else{
                            reject(response)
                        }
                    })
                    .catch(function (error) {
                        if (!error.status) {
                            console.log('Error if', error)
                            //window.location.href = process.env.VUE_APP_ACHIEVE_SITE_LOGIN
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        login({commit}, {username, password}) {
            return new Promise((resolve, reject) => {
                let data = {username, password};
                var config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/login',
                    data: data
                };

                axios(config)
                    .then(function (response) {
                        if (response.data.token) {
                            commit('setAccessToken', response.data.token);
                            commit('setInvalidLogin', false)
                        } else {
                            commit('setInvalidLogin', true)
                        }
                        resolve(response.data)
                    })
                    .catch(function (error) {
                        console.log('Error', error)
                        reject(error);
                    });
            })
        },
        login2({commit}, {username, password}) {
            return new Promise((resolve, reject) => {
                let data = {username, password};
                var config = {
                    withCredentials: true,
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/login',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: data
                };

                axios(config)
                    .then(async function (response) {
                        if (response.status === 200 && response.data.data.token !== undefined &&
                            response.data.data.token !== '' && (response.data.data.role === 1 || response.data.data.role === 4)) {
                            commit('setAccessToken', response.data.data.token);
                            commit('setInvalidLogin', false)
                            //await dispatch('isAuthenticated')
                        } else {
                            commit('setInvalidLogin', true)
                        }
                        resolve(response)
                    })
                    .catch(function (error) {
                        console.log('Error', error)
                        reject(error);
                    });
            })
        },
        resetPassword(_, options) {
            return new Promise((resolve, reject) => {
                let data = {...options}
                let config = {
                    method: 'post',
                    url: process.env.VUE_APP_ROOT_API_SERVER + '/reset-password',
                    data: data
                }

                axios(config)
                    .then(response => {
                        resolve(response)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        resetCheck(_, options) {
            return new Promise((resolve, reject) => {
                let data = {}
                let config = {
                    method: 'post',
                    url: process.env.VUE_APP_ROOT_API_SERVER + '/reset-password-check/'+options.token+'/'+options.email,
                    data: data
                }

                axios(config)
                    .then(response => {
                        resolve(response.status)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },
        forgotEmailCheck({state}, email) {
            return new Promise((resolve, reject) => {
                state.forgotid = email
                let data = {email}
                let config = {
                    method: 'post',
                    url: process.env.VUE_APP_ROOT_API_SERVER + '/forgot-password',
                    data: data
                }

                axios(config)
                    .then(response => {
                        resolve(response)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        forgotEmail({state}, email) {
            return new Promise((resolve, reject) => {
                state.email = email
                let data = {...email}
                let config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/forgotEmail',
                    data: data
                }

                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        verifyEmail({state}, vid) {
            return new Promise((resolve, reject) => {
                state.vid = vid
                let data = {vid}
                let config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/verifyEmail',
                    data: data
                }
                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        logout({commit}) {
            return new Promise((resolve) => {
                //find a way to clear all vue states
                commit('setAccessToken', '')
                commit('setUser', {})
                commit('setInvalidLogin', true)
                //commit('sideMenu/setSideMenuOrdered', '')
                localStorage.clear()
                resolve()
            })
        },
        getActiveUser({commit}, {email}) {
            return new Promise((resolve, reject) => {
                let data = {email}
                let config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/loginProblems/checkUserActive',
                    data: data
                }
                axios(config)
                    .then(response => {
                        commit('setActiveUser', response.data.data.active_account)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
    },
    mutations: {
        setAccessToken(state, token) {
            localStorage.setItem('access_token', token)
            state.accessToken = token;
        },
        setUser(state, user) {
            localStorage.setItem('user', JSON.stringify(user))
            state.user = user;
        },
        setActiveUser(state, activeAccount) {
            state.activeUser = activeAccount
        },
        setInvalidLogin(state, invalidLogin) {
            state.invalidLogin = invalidLogin
        }
    }
}