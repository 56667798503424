<template>
  <div class="hold-transition login-page">
    <div class="login-box">
      <!-- /.login-logo -->
      <div class="card card-outline card-primary">
        <div class="card-header text-center">
          <a href="/" class="h1"><b>Ampf</b></a>
        </div>
        <div class="card-body">
          <p class="login-box-msg">Sign in</p>

          <p class="bg-success white" v-show="msg">
            {{msg}}
          </p>
          <form @submit.prevent="submitForm" autocomplete="off">
            <p class="alert alert-danger" role="alert" v-show="showemailverify">Please verify your email before logging in</p>
            <p class="alert alert-danger" role="alert" v-show="v$.form.username.$error">Username is invalid or empty</p>
            <p class="alert alert-danger" role="alert" v-show="v$.form.password.$error">Password is empty</p>
            <p class="alert alert-danger" role="alert" v-show="invalidLogin">Your username and password did not match. Please try again.</p>
            <div class="input-group mb-3">
              <input type="text" v-model="form.username" v-on:keyup.enter="submitForm" id="username" class="form-control" placeholder="Username">
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-envelope"></span>
                </div>
              </div>
            </div>
            <div class="input-group mb-3">
              <input :type="tgltype" class="form-control" v-on:keyup.enter="submitForm" v-model="form.password" id="password"
                     placeholder="Password">
              <font-awesome-icon class="toggle-password" :icon="tglpass" id="togglePassword" @click="togglePass"/>

              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-lock"></span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-8">
                <div class="icheck-primary">

                </div>
              </div>
              <!-- /.col -->
              <div class="col-4">
                <button class="btn btn-primary btn-block text-center" type="button" :disabled="loading" @click="loader(true)">
                  Sign In
                  <span class="spinner-border spinner-border-sm" :class="{'d-none':!loading}" role="status" aria-hidden="true"></span>
                </button>
              </div>
              <!-- /.col -->
            </div>
          </form>

          <p class="mb-1">
            <router-link :to="{ name: 'ForgotPassword'}">I forgot my password</router-link>
          </p>
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {required} from '@vuelidate/validators'
import {mapGetters} from "vuex";


export default {
  name: 'Login-page',
  setup: () => ({v$: useVuelidate()}),
  data() {
    return {
      loading: false,
      msg: '',
      tglpass: 'eye',
      tgltype: 'password',
      invalidLogin: false,
      showemailverify: false,
      form: {
        username: null,
        password: null
      }
    }
  },
  validations() {
    return {
      form: {
        username: {required},
        password: {required}
      }
    }
  },
  computed: {
    ...mapGetters({
      'UserInfo': 'userAccess/user',
    }),
  },
  methods: {
    togglePass(){
      if(this.tglpass === 'eye'){
        this.tglpass = 'eye-slash'
        this.tgltype = 'text'
      }else{
        this.tglpass = 'eye'
        this.tgltype = 'password'
      }
    },
    async submitForm() {
      const result = await this.v$.$validate()
      if (!result) {
        this.loading = false
        return
      }
      // if (this.form.username && this.form.password) {
      this.$store.dispatch('userAccess/login2', {username: this.form.username, password: this.form.password})
          .then(async res => {

            if(res.status === 200 && (res.data.data.role === 1 || res.data.data.role === 4)) {
              await this.$store.dispatch('userAccess/isAuthenticated');
              location.href = '/Admin/Dashboard';
            }else{
              this.invalidLogin = true
              this.loading = false
            }
          })
          .catch(() => {
            this.loading = false
            this.invalidLogin = true
          })
      // }
    },
    successRedirect() {
      this.loading = false
      //const redirectTo = this.$route.query.redirectTo || {name: 'dashboard'}
      const redirectTo = {name: 'AdminDashboard'}
      this.$router.push(redirectTo)
    },
    loader(lv) {
      this.loading = lv
      this.submitForm();
    }
  }, mounted() {
    if (this.$route.params.msg !== undefined) {
      this.msg = this.$route.params.msg
    }
    if (this.UserInfo) {
      this.successRedirect()
    }
  },
  updated() {
    if (this.$route.params.msg !== undefined) {
      this.msg = this.$route.params.msg
    }
  }
}
</script>

<style scoped>

/*password eye*/
.password-container {
  position: relative;
}

#password {
  padding-right: 30px; /* Space for the icon */
}

.toggle-password {
  position: absolute;
  right: 48px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

</style>
