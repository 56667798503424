<template>
  <div>
    <div class="card" v-if="!filter">
      <div class="card-header">
        <h2 class="card-title text-bold">Orders</h2>
        <!-- /.card-tools -->
      </div>
      <!-- /.card-header -->
      <div class="card-body">
        <div class="row">
          <div class="col-sm-3">
            <div class="form-group">
              <label for="invDateFromSearch">Invoice Date From</label>
              <input id="invDateFromSearch" type="date" class="form-control" v-model="filterForm.invoiceDateFrom">
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label for="invDateToSearch">Invoice Date To</label>
              <input id="invDateToSearch" type="date" class="form-control" v-model="filterForm.invoiceDateTo">
            </div>
          </div>
          <div class="col-sm-3 border-left">
            <div class="form-group">
              <label for="orderDateFromSearch">Order Date From</label>
              <input id="orderDateFromSearch" type="date" class="form-control" v-model="filterForm.orderDateFrom">
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label for="orderDateToSearch">Order Date To</label>
              <input id="orderDateToSearch" type="date" class="form-control" v-model="filterForm.orderDateTo">
            </div>
          </div>
        </div>


        <div class="row">
          <div class="col-sm-2">
            <div class="form-group">
              <label for="locationSearch">Location</label>
              <select id="locationSearch" class="custom-select rounded-0" v-model="this.filterForm.Location">
                <option></option>
                <option v-for="loc in locationData" :key="loc.Loc" :value="loc.Loc">{{
                    loc.Name
                  }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-sm-2">
            <div class="form-group">
              <label for="statusSearch">Status</label>
              <select id="statusSearch" class="custom-select rounded-0" v-model="this.filterForm.paystatus">
                <option></option>
                <option value="4">Credits</option>
                <option value="1">Paid</option>
                <option value="0">Not Paid (All)</option>
                <option value="6">Not Paid (No issues)</option>
                <option value="5">Not Paid (With issues)</option>
                <option value="3">Data Conversion</option>
                <option value="9">Pending</option>
              </select>
            </div>
          </div>
          <div class="col-sm-2">
            <div class="form-group">
              <label for="invoiceSearch">Invoice #</label>
              <input id="invoiceSearch" type="text" class="form-control" v-model="this.filterForm.Invoicenum" @keyup.enter="runSearch">
            </div>
          </div>
          <div class="col-sm-2">
            <div class="form-group">
              <label for="orderSearch">Order #</label>
              <input id="orderSearch" type="text" class="form-control" v-model="this.filterForm.Ordernum" @keyup.enter="runSearch">
            </div>
          </div>
          <div class="col-sm-2">
            <div class="form-group">
              <label for="accountSearch">Account #</label>
              <input id="accountSearch" type="text" class="form-control" v-model="this.filterForm.Acctnum" @keyup.enter="runSearch">
            </div>
          </div>
          <div class="col-sm-2">
            <div class="form-group">
              <label for="canCharge">Can Charge</label>
              <select name="canCharge" id="canCharge" class="custom-select rounded-0"
                      v-model="this.filterForm.canCharge">
                <option value=""></option>
                <option value="1">Yes</option>
                <option value="2">No</option>
              </select>
            </div>
          </div>
        </div>


      </div>
      <!-- /.card-body -->
      <div class="card-footer">
        <button class="btn btn-success float-right ml-2" v-if="canBatchPay" v-show="hasBatchPay"
                @click.prevent="makeMassPayment">Make Payment
        </button>
        <button class="btn btn-primary float-right" @click.prevent="runSearch">Search</button>
      </div>
      <!-- /.card-footer -->
    </div>
    <!-- /.card -->

    <div class="card card-outline">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <table id="orders1" class="table table-bordered table-striped">
              <thead>
              <tr>
                <th>
                  <input type="checkbox" id="selectAll" value="1">
                </th>
                <th>Order Number</th>
                <th>Location</th>
                <th>Account #</th>
                <th>Company</th>
                <th>Total</th>
                <th>Inv Total</th>
                <th>Payment</th>
                <th>Partial Pay</th>
                <th>Order Date</th>
                <!--<th>Status</th>-->
                <th>Invoice Date</th>
                <th>Inv #'s</th>
              </tr>
              </thead>
              <tbody>

              </tbody>
              <tfoot>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
              <th id="invTotal">&nbsp;</th>
              <th id="payLeft">&nbsp;</th>
              <th id="partPayment">&nbsp;</th>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
              <th>&nbsp;</th>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import $ from 'jquery'
import {mapGetters} from "vuex";
import {useToast} from "vue-toastification";


export default {
  name: "Orders",
  props: {acctnum: Number, filter: Boolean},
  setup() {
    const toast = useToast();
    return {toast};
  },
  data() {
    return {
      orders1DT: '',
      canBatchPay: false,
      popperInstance: '',
      search: '',
      options: {dom: '<"top"f>rt<"bottom"ilp><"clear">', buttons: ['csv']},
      batchPayInv: [],
      bangImg: require("@/assets/img/bang.png"),
      partialPayTotal: 0,
      filterForm: {
        invoiceDateTo: null,
        invoiceDateFrom: null,
        orderDateTo: null,
        orderDateFrom: null,
        Location: '',
        paystatus: 6,
        Acctnum: null,
        Invoicenum: null,
        Ordernum: null,
        canCharge: 1
      }
    }
  },
  computed: {
    ...mapGetters({
      'orderData': 'orders/getOrders',
      'locationData': 'Accounts/getLocation',
      'UserInfo': 'userAccess/user'
    }),
    hasBatchPay() {
      return this.batchPayInv.length > 0
    }
  },
  methods: {
    makeMassPayment() {
      if (this.batchPayInv.length > 0) {
        let batchPayInvWPP = []
        this.batchPayInv.forEach(function (Inv) {
          let invPP = document.getElementById(Inv);
          if (invPP) {
            batchPayInvWPP.push({'invoiceid': Inv, 'partialPayment': invPP.value !== '' ? parseFloat(invPP.value) : 0})
          } else {
            batchPayInvWPP.push({'invoiceid': Inv})
          }

        });

        this.$store.dispatch('Payment/makeBatchPayment', batchPayInvWPP)
            .then(data => {
              if (data.success && data.batchId !== null) {
                this.toast.success('Batch Id ' + data.batchId + ' Created');
                let checkboxes = document.getElementsByClassName("chkINV");

                for (let i = 0; i < checkboxes.length; i++) {
                  checkboxes[i].checked = false;
                }

                let ppay = document.getElementsByClassName("partialPay");

                for (let i = 0; i < ppay.length; i++) {
                  ppay[i].value = '';
                }

                document.getElementById('selectAll').checked = false

                this.runSearch()

              } else {
                this.toast.warning('Batch Failed');
              }
            });

      } else {
        alert('No rows selected');
      }
    },
    goTo(rl) {
      this.$router.push(rl)
    },
    runSearch() {
      this.search = $('[aria-controls="orders1"]').val();
      $('#payLeft').html('');
      $("#orders1").DataTable().search(this.search).draw();
    },
    onint() {
      $("#orders1_wrapper input[type='search']").off();
      // Use return key to trigger search
      $("#orders1_wrapper input[type='search']").on("keyup", function (evt) {
        this.search = $(this).val();
        if (evt.keyCode == 13) {
          $("#orders1").DataTable().draw();
        }
      });
      let self = this;
      $(".chkINV").on("change", function () {
        self.payLeftCalc();
        let checkboxes = document.getElementsByClassName("chkINV");
        self.batchPayInv = []
        for (var i = 0; i < checkboxes.length; i++) {
          if (checkboxes[i].checked) {
            self.batchPayInv.push(checkboxes[i].value);
          }
        }
      });

      if (this.filter === 'false') {
        document.getElementById('selectAll').style.display = "none";

        let checkboxes = document.getElementsByClassName("chkINV");
        for (var i = 0; i < checkboxes.length; i++) {
          checkboxes[i].style.display = "none"
        }
      }

      let total = 0;
      for (const key of Object.keys(this.orderData.data)) {
        const value = this.orderData.data[key].invamt;
        if (!isNaN(parseFloat(value))) {
          total = (total + parseFloat(value));
        }
      }
      $("#invTotal").html('$' + this.formatCurrency(total));
      //$('#partPayment').html('$' + this.formatCurrency(total));


      // eslint-disable-next-line no-unused-vars
      $('.partialPay').on('keyup', function () {
        let maxAmount = this.getAttribute('data-invtotal');
        let invnum = this.getAttribute('id');

        let checkboxes = document.getElementsByClassName("chkINV");

        for (var i = 0; i < checkboxes.length; i++) {
          if (checkboxes[i].value === invnum) {
            checkboxes[i].checked = true
          }
        }
        //let invTotal = total;
        //let formatCurrency = self.formatCurrency

        // Remove non-numeric characters except decimal point
        this.value = this.value.replace(/[^0-9.]/g, '');

        // Format the value as currency
        const parts = this.value.split('.');
        //parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        if (parts.length > 1) {
          parts[1] = parts[1].slice(0, 2); // Keep only two decimal places
        }
        this.value = parts.join('.');

        // Validate against the maximum amount
        const amount = parseFloat(this.value);
        if (amount > maxAmount) {
          alert(`Amount should be less than or equal to Inv Total`);
          this.value = parseFloat(maxAmount);
        }

        //calc all partial pays #partPayment
        //let partialPayTotal = invTotal;
        let partialPayTotal = 0;
        $('.partialPay').each(function (index, element) {
          let inputValue = $(element).val();
          if (!isNaN(parseFloat(inputValue))) {
            partialPayTotal = (partialPayTotal + parseFloat(inputValue));
          }
        });
        self.partialPayTotal = partialPayTotal
        //$('#partPayment').html('$' + formatCurrency(partialPayTotal));
        self.payLeftCalc()
      });

    },
    payLeftCalc() {
      let checkboxes = document.getElementsByClassName("chkINV");
      let payLeft = 0
      for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].checked) {
          let val = checkboxes[i].value

          const foundItem = this.orderData.data.find(item => parseInt(item.Invnum) === parseInt(val));
          if (foundItem) {
            let partialPay = document.getElementById(val).value;
            if (partialPay !== '') {
              payLeft = (payLeft + parseFloat(partialPay))
            } else {
              payLeft = (payLeft + parseFloat(foundItem.balance))
            }
          }
        }
      }
      $('#payLeft').html('$' + this.formatCurrency(payLeft));
    },
    loadData() {
      console.log('load again')
      let self = this

      this.orders1DT = $("#orders1").DataTable({
        ajax: function (data, callback) {
          let sPage = (parseInt($("#orders1").DataTable().page()) + 1);
          let search = $("#orders1_wrapper input[type='search']").val();
          let orderCol = data.columns[data.order[0].column].name;
          let orderDir = data.order[0].dir;


          let filterLogic = self.filterForm
          filterLogic.withInvItems = false
          if (filterLogic.Invoicenum !== null && filterLogic.Invoicenum !== undefined && filterLogic.Invoicenum.trim() !== '') {
            filterLogic = {Invoicenum: self.filterForm.Invoicenum}
          } else if (filterLogic.Ordernum !== null && filterLogic.Ordernum !== undefined && filterLogic.Ordernum.trim() !== '') {
            filterLogic = {Ordernum: self.filterForm.Ordernum}
          } else if (filterLogic.Acctnum !== null && filterLogic.Acctnum !== undefined && filterLogic.Acctnum.trim() !== '') {
            filterLogic = {Acctnum: self.filterForm.Acctnum}
          }

          self.$store.dispatch('orders/getAdminOrderData', {
            options: {
              page: sPage,
              perPage: data.length,
              search,
              orderCol,
              orderDir, ...filterLogic
            }
          }).then(adata => {
            const tmpJson = {
              recordsTotal: adata.data.total, // expected by DataTables to create pagination
              recordsFiltered: adata.data.total, // expected by DataTables to create pagination
              data: adata.data.data, // expected by DataTables to populate the table
              currentPage: adata.data.current_page // added by me to easily manage correct page displaying
            }
            setTimeout(function () {
              self.onint();
            }, 1000);

            callback(
                tmpJson
            )
          });
        },
        processing: true,
        serverSide: true,
        pageLength: 25,
        order: [[9, 'desc']],
        'columnDefs': [{
          'targets': [0, 8], // column index (start from 0)
          'orderable': false, // set orderable false for selected columns
        }
        ],
        "columns": [
          {
            "data": null,
            "name": null,
            "render": function (data, type, row) {
              let inv = '';
              if (row.InvoiceNumbers !== null && data.paystatus !== 1 &&
                  data.paystatus !== 4 && self.canBatchPay &&
                  row.invamt !== null && row.invamt !== '' && row.invamt > 0) {
                inv = '<input type="checkbox" class="chkINV" value="' + row.InvoiceNumbers + '">';
              }
              return inv;
            }

          },
          {
            "data": null,
            "name": 'Ordnum',
            "render": function (data, type, row) {
              return '<a href="/Admin/OrderDetails/' + row.Ordnum + '">' + row.Ordnum + '</a>';
            }

          },
          {
            "data": null,
            "render": function (data, type, row) {
              return row.location !== null ? row.location.Name : '';
            }
          },
          {
            "data": null,
            "name": 'Acctnum',
            "render": function (data, type, row) {
              return '<a href="/Admin/AccountDetails/' + row.Acctnum + '">' + row.Acctnum + '</a>';
            }

          },
          {
            "data": null,
            "name": "Company",
            "render": function (data, type, row) {
              return row.account.Company;
            }
          },
          {
            "data": null,
            "name": "InvoiceInvTotal",
            "render": function (data, type, row) {
              let ttl = 0;
              if (row.InvoiceInvTotal !== null) {
                ttl = row.InvoiceInvTotal !== null ? '$' + parseFloat(row.InvoiceInvTotal).toFixed(2) : '';
              } else {
                ttl = row.OrderInvTotal !== null ? '$' + parseFloat(row.OrderInvTotal).toFixed(2) : '';
              }
              return ttl
            }
          },
          {
            "data": null,
            "name": 'invamt',
            "render": function (data, type, row) {
              let ia = row.invamt;
              if (ia !== '') {
                ia = '$' + Math.abs(ia).toFixed(2);
              }
              if (row.paystatus === 4 || row.shipvia === 'CREDIT') {
                ia = '<span style="color:red;">(' + ia + ')</span>';
              }

              return ia;
            }
          },
          {
            "data": null,
            "name": "payamt",
            "render": function (data, type, row) {
              return row.payamt !== null ? '$' + parseFloat(row.payamt).toFixed(2) : '';
            }
          },
          {
            "data": null,
            "name": null,
            "render": function (data, type, row) {
              let inv = '';
              if (row.InvoiceNumbers !== null) {
                if (parseFloat(row.invamt) > 0 && row.paystatus !== 1) {
                  inv = '<input type="text" class="partialPay" data-invtotal="' + row.invamt + '" data-invnum="' + row.InvoiceNumbers + '" id="' + row.InvoiceNumbers + '" value="" >';
                }
              }
              return inv;
            }

          },
          {
            "data": null,
            "name": 'OrdDate',
            "render": function (data, type, row) {
              return '<span style="white-space: nowrap;">' + (row.OrdDate !== null ? row.OrdDate.slice(0, 10) : '') + '</span>';
            }
          },
          {
            "data": null,
            "name": 'InvDate',
            "render": function (data, type, row) {
              return row.InvDate !== null ? row.InvDate.slice(0, 10) : '';
            }
          },
          {
            "data": null,
            "name": 'Invnum',
            "render": function (data, type, row) {
              let invS = ''
              if (row.InvoiceNumbers !== null) {
                let invx = row.InvoiceNumbers.split(',')
                let disp = ''
                //let batchStatus = row.batchStatus
                if (row.batchRaw !== '') {
                  let fdata = JSON.parse(row.batchRaw)
                  if (fdata !== null &&
                      fdata.transactionResponse !== undefined &&
                      fdata.transactionResponse.responseCode !== "1") {
                    if (fdata.transactionResponse.messages !== undefined) {
                      disp = fdata.transactionResponse.messages[0].description
                    } else if (fdata.transactionResponse.errors !== undefined) {
                      disp = fdata.transactionResponse.errors[0].errorText
                    }
                  }
                  if (disp === null) {
                    disp = '';
                  }
                }
                let batchStatusName = row.batchStatusName === null ? '' : row.batchStatusName
                for (let i = 0; i < invx.length; i++) {
                  if (i > 0) {
                    invS += ', ';
                  }
                  invS += '<a href="/Admin/InvoiceDetails/' + invx[i] + '">' + invx[i] + '</a>';
                }
                if (row.paystatus !== 1 && row.batchStatus !== null && row.batchStatus !== 1 && row.batchStatus !== 2) {
                  invS += '<img data-toggle="tooltip" data-placement="auto" title="' + batchStatusName + ': ' + disp + '" class="invoicePopArr" id="invoicePop_' + invx[0] + '" width="15" src="' + self.bangImg + '">';
                }
              }

              return invS;
            }
          },
        ],
        // eslint-disable-next-line no-unused-vars
        "rowCallback": function (row, data, index) {
          let color = '#000000';
          if (data.paystatus === 1) {
            color = 'green';
          } else if (data.paystatus === 3) {
            color = '#cf6678';
          } else if (data.paystatus === 4) {
            //inv text is red
            color = 'green';
          }
          $('td', row).css('color', color);
        },
        "responsive": true, "lengthChange": false, "autoWidth": false,
        "buttons": ["copy", "csv", "excel", "pdf", "print", "colvis"],
      }).buttons().container().appendTo('#orders1_wrapper .col-md-6:eq(0)');

    }
  },
  mounted() {
    const hasAdmin = this.UserInfo.roles.find(obj => obj.id === 1);
    if (hasAdmin) {
      this.canBatchPay = true
    }
    this.$store.dispatch('Accounts/setLocations')
    if (this.acctnum === undefined) {
      let currentDT = new Date().toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      }).slice(0, 10);
      let currentDTx = currentDT.split('/');
      let currentDTF = (currentDTx[2] - 1) + '-' + currentDTx[0] + '-' + currentDTx[1];
      let currentDTT = currentDTx[2] + '-' + currentDTx[0] + '-' + currentDTx[1];
      this.filterForm.invoiceDateFrom = currentDTF;
      this.filterForm.invoiceDateTo = currentDTT;
      //$("#orders1").DataTable().destroy()

        console.log('mounted order')
        this.loadData()

    } else {
      this.filterForm.Acctnum = this.acctnum
    }
    let self = this
    $("#selectAll").on("change", function () {
      let isC = $(this).prop("checked");
      let checkboxes = document.getElementsByClassName("chkINV");
      self.batchPayInv = []

      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = isC
        if (checkboxes[i].checked) {
          self.batchPayInv.push(checkboxes[i].value);
        }
      }
    });
  },
  beforeRouteLeave() {
      $("#orders1").DataTable().destroy();
  }
}
</script>

<style>

.partialPay {
  width: 100px;
}

#orders1 thead th {
  white-space: nowrap;
}

.invoicePopArr {
  margin-left: 5px;
  margin-top: -5px;
}


</style>