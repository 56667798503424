import axios from 'axios'

export default {
    namespaced: true,
    state: {
        reportData:[]
    },
    getters: {
        getReportData(state){
            return state.reportData
        },
    },
    actions: {
        reportData({commit},{report, options}){
            return new Promise((resolve, reject) => {
                let data = { ...options }
                let config = {
                    method: 'get',
                    url: process.env.VUE_APP_API_SERVER + '/admin/report/'+report,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                }

                axios(config)
                    .then(response => {
                        commit('setReportData',response.data.data)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
    },
    mutations: {
        setReportData(state, reportData){
            state.reportData = !reportData ? [] : reportData
        },

    }
}