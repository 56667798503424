<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card ">
          <div class="card-body p-3">
            <div class="row">
              <div class="col-12 mb-2">
                <label>Active</label>
                <select name="active" id="active" class="form-control" v-model="form.active">
                  <option :value="true">Active</option>
                  <option :value="false">Not Active</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mb-2">
                <label>Job Heading</label>
                <input type="text" class="form-control" v-model="form.job_heading">
              </div>
            </div>
            <div class="row">
              <div class="col-12 mb-2">
                <label>Job Subject</label>
                <input type="text" class="form-control" v-model="form.job_subject">
              </div>
            </div>
            <div class="row">
              <div class="col-12 mb-2">
                <label>Job Description</label>
                <Editor
                    :api-key="tinykey"
                    v-model="form.job_description"
                    :init="{plugins: 'lists link image table code help wordcount'}"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card ">
          <div class="row">
            <div class="col-12 m-1 text-right">
              <button class="btn btn-primary mr-2 " @click="saveCareer">Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import $ from "jquery";

export default {
  name: 'whoWeAre',
  props: ['editId', 'data'],
  data() {
    return {
      tinykey: process.env.VUE_APP_TINYKEY,
      form: {
        id: '',
        active: false,
        job_heading: '',
        job_subject: '',
        job_description: '',
      }
    };
  },
  watch: {
    // Watcher for the 'myProp' prop
    async 'editId'(newval) {
      console.log('edit change', newval)
      await this.$store.dispatch('System/careers', {data: {id: newval}, method: 'get'}).then(rez => {
        console.log('dta', rez);
        this.form = rez
      });
    }
  },
  components: {
    Editor
  },
  methods: {
    async saveCareer() {
      if (this.editId) {
        this.form.id = this.editId
        await this.$store.dispatch('System/careers', {data: this.form, method: 'put'})
      } else {
        await this.$store.dispatch('System/careers', {data: this.form, method: 'post'})
      }
      this.$store.dispatch('System/careers', {data: {}, method: 'get'})
      $("#careerMethodModal").modal("hide");
    }
  }
}
</script>

<style scoped>

</style>