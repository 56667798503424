<template>
  <div class="container">

    <div class="card card-outline card-primary">
      <div class="card-header">
        <div class="row">
          <div class="col">
            <h2>Sync Status</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-outline">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <table class="table">
              <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Last Updated</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in syncList" :key="item.id">
                <td>{{ item.id }}</td>
                <td>{{ item.name }}</td>
                <td>{{ convertToEST(item.last_updated) }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "AProducts",
  data() {
    return {
      searchimg: '',
      fbimg: '',
      tableLoaded: false
    }
  },
  computed: {
    ...mapGetters({
      'syncList': 'System/getSyncList',
    }),
  },
  methods: {
    convertToEST(utcdate) {
      const options = {
        timeZone: "America/New_York",
        timeZoneName: "short"
      };
      return new Date(utcdate).toLocaleString("en-US", options);
    },
  },
  mounted() {
    this.$store.dispatch('System/syncList')
  }

}


</script>


<style scoped>

</style>