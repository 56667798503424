<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card ">
          <div class="row">
            <div class="col-12 m-1 text-right">
              <button class="btn btn-primary mr-2 " @click="savePolicys">Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card ">
          <div class="card-header">
            <h3 class="card-title">Policy</h3>
          </div>
          <div class="card-body p-3">
            <div class="row">
              <div class="col-12 mb-2">
                <Editor
                    :api-key="tinykey"
                    v-model="form.Policy"
                    :init="{plugins: 'lists link image table code help wordcount'}"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="card ">
          <div class="card-header">
            <h3 class="card-title">Terms</h3>
          </div>
          <div class="card-body p-3">
            <div class="row">
              <div class="col-12 mb-2">
                <Editor
                    :api-key="tinykey"
                    v-model="form.Terms"
                    :init="{plugins: 'lists link image table code help wordcount'}"
                />
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'

export default {
  name: 'policys',
  data() {
    return {
      tinykey: process.env.VUE_APP_TINYKEY,
      form: {
        Policy: '',
        Terms: ''
      }
    };
  },
  components: {
    Editor
  },
  methods:{
    savePolicys() {
      this.$store.dispatch('product/updatePolicyData', this.form)
    }
  },
  mounted() {
    this.$store.dispatch('product/settingsData').then(rez => {
      let policy = rez.find(obj => obj.name === "Policy");
      let terms = rez.find(obj => obj.name === "Terms");
      this.form.Policy = policy.data
      this.form.Terms = terms.data
      console.log('setting data',rez)
    })
  }
}
</script>

<style scoped>

</style>