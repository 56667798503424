<template>
  <div class="hold-transition login-page">
  <div class="login-box">
    <div class="card card-outline card-primary">
      <div class="card-header text-center">
        <a href="../../index2.html" class="h1"><b>Ampf</b></a>
      </div>
      <div class="card-body">
        <p class="login-box-msg">You are only one step a way from your new password, recover your password now.</p>
        <p class="alert alert-danger" role="alert" v-show="showmsg" v-html="msg"></p>
        <form @submit.prevent="submitForm" autocomplete="off">
          <div class="input-group mb-3">
            <input type="password" v-model="form.password" class="form-control" placeholder="Password">
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-lock"></span>
              </div>
            </div>
          </div>
          <div class="input-group mb-3">
            <input type="password" v-model="form.password_confirmation" class="form-control" placeholder="Confirm Password">
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-lock"></span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button type="submit" class="btn btn-primary btn-block">Change password</button>
            </div>
            <!-- /.col -->
          </div>
        </form>

        <p class="mt-3 mb-1">
          <a href="login.html">Login</a>
        </p>
      </div>
      <!-- /.login-card-body -->
    </div>
  </div>
  <!-- /.login-box -->
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {required} from '@vuelidate/validators'
import {mapGetters} from "vuex";
export default {
  name: "ResetPassword",
  setup: () => ({v$: useVuelidate()}),
  props: ['token','email'],
  data() {
    return {
      msg: '',
      showmsg: false,
      form: {
        password: null,
        password_confirmation: null
      }
    }
  },
  validations() {
    return {
      form: {
        password: {required},
        password_confirmation: {required}
      }
    }
  },
  computed: {
    ...mapGetters({
      'UserInfo': 'userAccess/user',
    }),
  },
  methods: {
    async submitForm() {
      let self = this
      const result = await this.v$.$validate()
      if (!result) {
        return
      }
      // if (this.form.username && this.form.password) {
      this.$store.dispatch('userAccess/resetPassword', {token:this.token,email:this.email, password: this.form.password, password_confirmation: this.form.password_confirmation})
          .then(async res => {
            if(res.data.status !== undefined && res.data.status === 'passwords.reset'){
              //display message and redirect to login
              this.showmsg = true
              this.msg = "Password has been reset please login. You are being redirected"
              setTimeout(function(){
                self.$router.push({name:'Login'})
              },3000)
            }else{
              this.msg = res.data.data.password.toString().replace(',','<br />')
              this.showmsg = true;
            }
          })
          .catch(() => {
            this.loading = false
            // alert('🤷‍️' + error.message)
          })
      // }
    },
  }, mounted() {
    //http://192.168.88.12:8082/reset-password/e06dd60e245625138575173ddf3d6fd45ae8cc84e1ffafa2960977ddd810e3b7/mdinat3@gmail.com
    this.$store.dispatch('userAccess/resetCheck', {token:this.token,email:this.email})
        .then(async res => {
          if(res !== 200){
            this.$router.push({name:'Login'})
          }
        })
        .catch(() => {
          this.$router.push({name:'Login'})
        })
  },
}
</script>

<style scoped>

</style>