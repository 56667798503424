<template>
  <div class="content">

    <div class="card card-outline card-primary">
      <div class="card-header">
        <div class="row">
          <div class="col">
            <h2>Reports</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-outline">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <ul>
              <li>
                <a href="#" @click.prevent="loadReport('missingTransactionReport')">Missing Payment Transactions</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-outline">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <table class="table">
              <thead>
              <tr>
                <th v-for="(name,idx) in reportData[0]" :key="idx">{{ idx }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(data,idxtd) in reportData" :key="idxtd">
                <td v-for="(rdata, idxr) in data" :key="idxr">
                  {{ idxr === 'created_at' || idxr === 'updated_at' ? convertToEST(rdata) : rdata }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Reports",
  data() {
    return {}
  },
  components: {},
  computed: {
    ...mapGetters({
      'reportData': 'Reports/getReportData',
    }),
  },
  methods: {
    loadReport(report) {
      this.$store.dispatch("Reports/reportData", {report, options: {}})
          .then(() => {

          });
    },
    convertToEST(utcDateString) {
      const utcDate = new Date(utcDateString);
      //const estOffset = -5 * 60; // EST offset in minutes (UTC-5)

      // Apply the offset to the UTC date
      //utcDate.setMinutes(utcDate.getMinutes() + estOffset);

      // Format the EST date as a string
      return utcDate.toLocaleString('en-US', {
        timeZone: 'America/New_York', // Set to Eastern Time
        hour12: true, // Use 12-hour time format
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      });
    },
  },
  mounted() {


    //this.$store.dispatch('System/pdfList',{'list':1}).then(() => {
    //this.loader.hide()
    //})
  }

}
</script>

<style scoped>

</style>