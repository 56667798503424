<template>

  <div class="hold-transition login-page">
    <div class="login-box">
      <div class="card card-outline card-primary">
        <div class="card-header text-center">
          <a href="../../index2.html" class="h1"><b>Ampf</b></a>
        </div>
        <div class="card-body">
          <p class="login-box-msg">2FA authentication</p>
          <p class="text-center">We sent code to your email</p>
          <p class="alert alert-danger" role="alert" v-show="showmsg" v-html="msg"></p>
            <div class="input-group mb-3">
              <input id="code" type="number" class="form-control is-invalid " name="code" v-model="mfacode" required
                     autocomplete="code" autofocus>
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-lock"></span>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">

                <button class="btn btn-primary btn-block text-center" type="button" :disabled="loading" @click="passMFA()">
                  Submit
                  <span class="spinner-border spinner-border-sm" :class="{'d-none':!loading}" role="status" aria-hidden="true"></span>
                </button>
              </div>
              <!-- /.col -->
            </div>

          <p class="mt-3 mb-1">
            <a class="btn btn-link" href="" @click.prevent="resetCode">Reset Code</a>
          </p>
        </div>
        <!-- /.login-card-body -->
      </div>
    </div>
    <!-- /.login-box -->
  </div>

</template>

<script>
//import {mapGetters} from "vuex"
export default {
  name: "Mfa",
  data() {
    return {
      loading: false,
      message: '',
      mfacode: ''
    }
  },
  methods: {
    resetCode() {
      this.$store.dispatch('userAccess/logout').then(() =>{
        this.loading = false
        this.$router.push({name: 'Login'})
      })
    },
    passMFA() {
      this.loading = true
      this.$store.dispatch("userAccess/passMFA", this.mfacode).then(() => {
        const redirectTo = {name: 'AdminDashboard'}
        this.loading = false
        this.$router.push(redirectTo)
      })
    }
  },
  async mounted() {
    //await this.$store.dispatch('userAccess/isAuthenticated');
  }
}
</script>

<style scoped>

</style>