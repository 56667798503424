import axios from 'axios'

export default {
    namespaced: true,
    state: {
        pdfList: {},
        careerList:{}
    },
    getters: {
        getSyncList(state) {
            return state.pdfList
        },
        getCareerList(state) {
            return state.careerList
        }
    },
    actions: {
        syncList({commit}) {
            return new Promise((resolve, reject) => {
                var config = {
                    method: 'get',
                    url: process.env.VUE_APP_API_SERVER + '/admin/syncStatus',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    params: ''
                }

                axios(config)
                    .then(response => {
                        resolve('syncstatus',response.data)
                        commit('setSyncList',response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        pdfList({commit},data) {
            return new Promise((resolve, reject) => {
                var config = {
                    method: 'get',
                    url: process.env.VUE_APP_API_SERVER + '/admin/pdfList',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    params: {...data}
                }

                axios(config)
                    .then(response => {
                        resolve(response.data)
                        if(data.id === undefined) {
                            commit('setPdfList', response.data.data)
                        }
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        uploadPDF(_,data) {
            return new Promise((resolve, reject) => {
                let config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/admin/pdfList',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                }

                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        deletePDF(_,id) {
            return new Promise((resolve, reject) => {
                let data = {}
                let config = {
                    method: 'delete',
                    url: process.env.VUE_APP_API_SERVER + '/admin/pdfList/'+id,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                }

                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        careers({commit},{data, method}) {
            return new Promise((resolve, reject) => {
                let config = {
                    method: method,
                    url: process.env.VUE_APP_API_SERVER + '/admin/career'+(method === 'delete' || method === 'put' ? '/'+data.id:''),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                }
                if(method === 'get'){
                    config.params = data
                }else{
                    config.data = data
                }

                axios(config)
                    .then(response => {
                        console.log('career', response)
                        if(method === 'get' && data.id === undefined) {
                            commit('setCareer', response.data)
                        }
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
    },
    mutations: {
        setCareer(state, pdfList) {
            state.careerList = !pdfList ? {} : pdfList
        },
        setPdfList(state, pdfList) {
            state.pdfList = !pdfList ? {} : pdfList
        },
        setSyncList(state, pdfList) {
            state.pdfList = !pdfList ? {} : pdfList
        },
    }
}