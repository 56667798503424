<template>
  <div class="container-fluid">
    <div class="card card-outline card-primary">
      <div class="card-header">
        <div class="row">
          <div class="col">
            <h2>Collections</h2>
          </div>
          <div class="col-auto">
            <button class="btn btn-primary" @click="newCollection">New</button>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-outline">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <table class="table" id="collections">
              <thead>
              <tr>
                <th>Id</th>
                <th>&nbsp;</th>
                <th>Collection</th>
                <th>Active</th>
                <th></th>
                <th>&nbsp;</th>
              </tr>
              </thead>
              <tbody>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
//import {mapGetters} from "vuex"
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";

export default {
  name: "CollectionsA",
  data() {
    return {
      tableLoaded: false
    }
  },
  computed: {},
  methods: {
    newCollection() {
      this.$router.push({path: '/Admin/Collections/Edit'});
    },
    onint() {
      $("#products_wrapper input[type='search']").off();
      // Use return key to trigger search
      $("#products_wrapper input[type='search']").on("keyup", function (evt) {
        this.search = $(this).val();
        if (evt.keyCode == 13) {
          $("#products").DataTable().draw();
        }
      });
    },
    loadData() {
      let self = this
      $("#collections").DataTable({
        ajax: function (data, callback) {
          let sPage = (parseInt($("#collections").DataTable().page()) + 1);
          let search = $("#collections_wrapper input[type='search']").val();
          let orderCol = data.columns[data.order[0].column].data;
          let orderDir = data.order[0].dir;
          let options = {
            page: sPage,
            items_per_page: data.length,
            orderCol,
            orderDir, ...self.filterForm
          }

          if (search !== '') {
            options.search = search
          }
          self.$store.dispatch('product/collectionData', {
            options
          }).then(adata => {
            const tmpJson = {
              recordsTotal: adata.total, // expected by DataTables to create pagination
              recordsFiltered: adata.total, // expected by DataTables to create pagination
              data: adata.data, // expected by DataTables to populate the table
              currentPage: adata.current_page // added by me to easily manage correct page displaying
            }

            callback(
                tmpJson
            )
          });
        },
        "initComplete": function () {
          self.onint();
        },
        processing: true,
        serverSide: true,
        order: [[2, 'asc']],
        'columnDefs': [{
          'targets': [1], // column index (start from 0)
          'orderable': false, // set orderable false for selected columns
        }],
        "columns": [
          {"data": "id"},
          {
            "data": null,
            "render": function (data, type, row) {
              return '<img onerror="this.src=\'/ampf.gif\'" src="' + process.env.VUE_APP_NON_CDN + "/collections/" + row.id + ".jpg" + '" width="120">';
            }

          },
          {"data": "name"},
          {"data": "active"},
          {
            "data": null,
            "name": 'id',
            "render": function (data, type, row) {
              return '<a class="btn btn-primary" href="/Admin/Collections/Edit/' + row.id + '">Edit</a>';
            }

          },
        ],
        "responsive": true, "lengthChange": false, "autoWidth": false,
        "buttons": ["copy", "csv", "excel", "pdf", "print", "colvis"],
      }).buttons().container().appendTo('#collections_wrapper .col-md-6:eq(0)');

    }
  },
  mounted() {
    this.loadData()
  },
  beforeRouteLeave() {
      $("#collections").DataTable().destroy();
  }
}
</script>

<style scoped>

</style>