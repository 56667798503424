<template>
  <div class="wrapper">

    <!--<Preloader></Preloader>-->
    <Navbar></Navbar>
    <Sidebar></Sidebar>

    <div class="content-wrapper">

      <ContentHeader></ContentHeader>

      <section class="content">
        <div class="container-fluid">
          <router-view/>
        </div>
      </section>

    </div>

    <Footer></Footer>
    <ControlSidebar></ControlSidebar>

  </div>
</template>

<script>
import $ from 'jquery'
import "../../../node_modules/admin-lte/plugins/select2-bootstrap4-theme/select2-bootstrap4.min.css"
import "../../../node_modules/admin-lte/plugins/select2/css/select2.min.css"

import ContentHeader from "../../dashboard/ContentHeader.vue"
import Navbar from "../../dashboard/Navbar.vue";
import Sidebar from "../../dashboard/Sidebar.vue";
import Footer from "../../dashboard/Footer.vue";
import ControlSidebar from "../../dashboard/ControlSidebar.vue"


export default {
  name: "AdminView",
  components: {
    ContentHeader,
    Navbar,
    Sidebar,
    Footer,
    ControlSidebar
  },
  methods: {
    initializeSelect2() {
      //Initialize Select2 Elements
      $(".select2").select2();

      //Initialize Select2 Elements
      $(".select2bs4").select2({
        theme: "bootstrap4",
      });
    },
  },
  mounted() {
    this.initializeSelect2()
  }
}
</script>

<style scoped>

</style>