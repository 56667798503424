<template>
  <div>

    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label for="value_name">Block Name</label>
          <textarea id="value_name" class="form-control" style="height: 100px; " v-model="form.name" @keyup="updateF()"></textarea>
        </div>
      </div>
    </div>

    <div class="row" style="border:1px solid #000; height: 300px; overflow: scroll;">
      <div class="col-12">
        <div class="row" v-for="col in collectionData" :key="col.id">
          <div class="col-2">
            <input type="checkbox" v-model="form.collections" :value="col.id" @change="updateF()">
          </div>
          <div class="col-10">
            {{ col.name }}
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "CollectionsListPopular",
  props: ['spot'],
  data() {
    return {
      form: {
        name: '',
        collections: []
      }
    };
  },
  computed: {
    ...mapGetters({
      'collectionData': 'product/getCollectionData'
    }),

  },
  methods: {
    updateF() {
      console.log('cols', this.form)
      this.$emit('updatePopular', this.form)
    },
  },
  mounted() {

    this.$store.dispatch('product/settingsData').then(rez => {
      let valuelines = rez.find(obj => obj.name === "value_lines");
      if (valuelines.data !== undefined) {
        let valuelinesObj = JSON.parse(valuelines.data)
        this.form.name = valuelinesObj?.name
        this.form.collections = valuelinesObj !== null ? valuelinesObj?.collections : []
        console.log('setting data', valuelinesObj)
        this.$emit('updatePopular', this.form)
      }
    })

  }
};
</script>
<style scoped>

</style>