<template>
  <div>
    <div class="row" v-for="(name,idx) in dataArr" :key="idx">
      <div class="col-1">
        <label for="name">{{ idx }}</label>
      </div>
      <div class="col-7">
        <div class="form-group">
          <input class="form-control discountLbl" :id="'name-' + idx" v-model="dataArr[idx]" @keyup="updateS()"/>
        </div>
      </div>
      <div class="col-4">
        <button class="btn btn-danger" @click="deleteS(idx)">
          <span class="spinner-border spinner-border-sm spinDel d-none" :id="'spinnerS_'+idx" role="status" aria-hidden="true"></span>
          Delete
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-right">
        <button class="btn btn-primary" @click="addS">
          <span class="spinner-border spinner-border-sm spinAdd d-none" id="spinnerAddS" role="status" aria-hidden="true"></span>
          Add
          </button>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "Style",
  data() {
    return {
      dataArr: []
    };
  },
  emits: {
    updateStyle: null
  },
  computed: {
    ...mapGetters({
      'styleData': 'product/getStyleData',
    }),

  },
  methods: {
    addS() {
      document.querySelector('#spinnerAddS').classList.remove('d-none');
      this.$store.dispatch('product/setupStyle',{data: {name:'*******'}, method:'post'}).then(rez => {
        this.dataArr[rez.id] = rez.name
        this.$emit('updateStyle', this.dataArr)
        document.querySelector('#spinnerAddS').classList.add('d-none');
      })
    },
    deleteS(id) {
      document.querySelector('#spinnerS_'+id).classList.remove('d-none');
      this.$store.dispatch('product/setupStyle',{data: {id}, method:'delete'}).then(rez => {
        if(rez.success) {
          delete this.dataArr[id]
          this.$emit('updateStyle', this.dataArr)
        }
        document.querySelector('#spinnerS_'+id).classList.add('d-none');
      })
    },
    updateS() {
      this.$emit('updateStyle', this.dataArr)
    },
  },
  mounted() {
    this.dataArr = this.styleData
    this.$store.dispatch('product/setupStyle',{method:'get'}).then(() => {
      this.dataArr = this.styleData
      this.$emit('updateStyle', this.dataArr)
    })
  }
};
</script>
<style scoped>

.spinDel{
  position: absolute;
  left:35px;
  top:10px;
}

.spinAdd{
  position: absolute;
  top:10px;
}

</style>