<template>
  <section class="content">
    <div class="row">
      <div class="col-md-12">
        <div class="card collapsed-card">
          <div class="card-header">
            <h3 class="card-title">Payments</h3>
            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse" ><i class="fas fa-plus fa-lg"></i></button>
            </div>
          </div>
          <!-- /.card-header -->
          <div class="card-body p-0">
            <table class="table table-sm">
              <thead>
              <tr>
                <th>Date</th>
                <th>Inv Amt</th>
                <th>Pay Amt</th>
                <th>Comment</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="pay in payments" :key="pay.SeqNum">
                <td>{{ formatDate(pay.TranDate) }}</td>
                <td>${{ this.formatCurrency(pay.InvAmt) }}</td>
                <td>${{ this.formatCurrency(pay.PayAmt) }}</td>
                <td>{{pay.Comment}}</td>
              </tr>

              </tbody>
            </table>
          </div>
          <!-- /.card-body -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "payments",
  props:['payments'],
  data() {
    return {
      search: '',
      options: {dom: '<"top"f>rt<"bottom"ilp><"clear">', buttons: ['csv']}
    }
  },
  methods: {
    formatDate(date){
      let newDate = ''
      if(date != undefined) {
        let dates = date.split(' ')
        let datex = dates[0].split('-')
        newDate = parseInt(datex[1]) + '/' + parseInt(datex[2]) + '/' + datex[0];
      }
      return newDate
    }
  },
  mounted() {
    //this.$store.dispatch('orders/getAdminInvoiceDetails', {Invoicenum: this.invoiceid});
  }
}
</script>

<style scoped>

</style>