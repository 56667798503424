<template>
  <!-- Main Sidebar Container -->
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <a href="index3.html" class="brand-link">
      <img
        src="../assets/logo.png"
        alt="AdminLTE Logo"
        class="brand-image img-circle elevation-3"
        style="opacity: 0.8"
      />
      <span class="brand-text font-weight-light">AMPF</span>
    </a>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar user panel (optional) -->
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <img
            src="../assets/img/avatar5.png"
            class="img-circle elevation-2"
            alt="User Image"
          />
        </div>
        <div class="info">
          <a href="#" class="d-block">{{ UserInfo.name }}</a>
        </div>
      </div>
      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >
          <!--
                    <li class="nav-item">
                      <a href="pages/widgets.html" class="nav-link">
                        <i class="nav-icon fas fa-th"></i>
                        <p>
                          Dashboard
                        </p>
                      </a>
                    </li>
                    -->

          <li class="nav-item" v-if="navItems.users">
            <router-link :to="{ path: '/Admin/Users/' }">
              <a href="/Admin/Users/" class="nav-link">
                <i class="fas fa-people-arrows nav-icon"></i>
                <p>Users</p>
              </a>
            </router-link>
          </li>

          <!--
                    <li class="nav-item">
                      <a href="#" class="nav-link">
                        <i class="nav-icon fas fa-tachometer-alt"></i>
                        <p>
                          User Management
                          <i class="right fas fa-angle-left"></i>
                        </p>
                      </a>
                      <ul class="nav nav-treeview">
                        <li class="nav-item">
                          <router-link :to="{ path: '/Admin/Users/'}">
                            <a href="./index.html" class="nav-link active">
                              <i class="far fa-circle nav-icon"></i>
                              <p>Users</p>
                            </a>
                          </router-link>
                        </li>
                      </ul>
                    </li>
                    -->

          <li class="nav-item"  v-if="navItems.accounts">
            <!--
                        <a
                          href="#"
                          @click.prevent.stop="goTo({ name: 'Accounts' })"
                          class="nav-link"
                        >
                          <i class="nav-icon fas fa-th"></i>
                          <p>Accounts</p>
                        </a>
                          -->

            <router-link :to="{ path: '/Admin/Accounts/' }">
              <a href="/Admin/Accounts/" class="nav-link">
                <i class="fas fa-book nav-icon"></i>
                <p>Accounts</p>
              </a>
            </router-link>
          </li>
          <li class="nav-item" v-if="navItems.orderInvoice">
            <a
              href="/Admin/Orders"
              @click.prevent.stop="goTo({ name: 'Orders' })"
              class="nav-link"
            >
              <i class="nav-icon fas fa-th"></i>
              <p>Orders/Invoices</p>
            </a>
          </li>
          <li class="nav-item" v-if="navItems.paymentBatch">
            <a
              href="/Admin/PaymentBatch"
              @click.prevent.stop="goTo({ name: 'PaymentBatch' })"
              class="nav-link"
            >
              <i class="nav-icon fas fa-th"></i>
              <p>Payment Batch</p>
            </a>
          </li>
          <li class="nav-item" v-if="navItems.products">
            <a
              href="/Admin/Products"
              @click.prevent.stop="goTo({ name: 'AdminProducts' })"
              class="nav-link"
            >
              <i class="nav-icon fas fa-th"></i>
              <p>Products</p>
            </a>
          </li>
          <li class="nav-item" v-if="navItems.collections">
            <a
              href="/Admin/Collections"
              @click.prevent.stop="goTo({ name: 'AdminCollections' })"
              class="nav-link"
            >
              <i class="nav-icon fas fa-th"></i>
              <p>Collections</p>
            </a>
          </li>
          <li class="nav-item" v-if="navItems.sync">
            <a
              href="/Admin/Sync"
              @click.prevent.stop="goTo({ name: 'Sync' })"
              class="nav-link"
            >
              <i class="nav-icon fas fa-th"></i>
              <p>Sync</p>
            </a>
          </li>
          <li class="nav-item" v-if="navItems.pdflist">
            <a
              href="/Admin/PdfList"
              @click.prevent.stop="goTo({ name: 'PdfList' })"
              class="nav-link"
            >
              <i class="nav-icon fas fa-th"></i>
              <p>Pdf List</p>
            </a>
          </li>
          <li class="nav-item" v-if="navItems.pdflist">
            <a
                href="/Admin/Reports"
                @click.prevent.stop="goTo({ name: 'Reports' })"
                class="nav-link"
            >
              <i class="nav-icon fas fa-th"></i>
              <p>Reports</p>
            </a>
          </li>
          <li class="nav-item" v-if="navItems.homePage">
            <a
                href="/Admin/siteconfig"
                @click.prevent.stop="goTo({ name: 'Siteconfig' })"
                class="nav-link"
            >
              <i class="nav-icon fas fa-th"></i>
              <p>Site Config</p>
            </a>
          </li>
          <li class="nav-item" v-if="navItems.homePage">
            <a
                href="/Admin/CareerList"
                @click.prevent.stop="goTo({ name: 'CareerList' })"
                class="nav-link"
            >
              <i class="nav-icon fas fa-th"></i>
              <p>Careers</p>
            </a>
          </li>
          <li class="nav-item" v-if="navItems.website">
            <a
                href="https://ampfdev.ampfframes.com/#/"
                target="_blank"
                class="nav-link"
            >
              <i class="nav-icon fas fa-th"></i>
              <p>Website</p>
            </a>
          </li>
          <li class="nav-item">
            <a href="Logout" @click.prevent.stop="logout" class="nav-link">
              <i class="nav-icon fas fa-arrow-circle-left"></i>
              <p>Logout</p>
            </a>
          </li>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
    <div style="position: absolute; bottom: 0; color: white">
      v {{ version }}
    </div>
  </aside>
</template>

<script>
import { mapGetters } from "vuex";
import packageJson from "../../package.json";
export default {
  data() {
    return {
      version: packageJson.version,
      message: "",
      mfacode: "",
      navItems: {
        users: false,
        accounts: false,
        orderInvoice: false,
        paymentBatch: false,
        products: false,
        collections: false,
        sync: false,
        pdflist: false,
        homePage: false,
        website: true
      }
    };
  },
  computed: {
    ...mapGetters({
      UserInfo: "userAccess/user",
    }),
  },
  methods: {
    goTo(rl) {
      this.$router.push(rl);
    },
    logout() {
      this.$store.dispatch("userAccess/logout").then(() => {
        this.$router.go("/");
      });
    },
  },
  mounted() {
    const hasAdmin = this.UserInfo.roles.find(obj => obj.id === 1);
    if(hasAdmin){
      let nItems = this.navItems
      for (const key in nItems) {
        // eslint-disable-next-line no-prototype-builtins
        if (nItems.hasOwnProperty(key)) {
          nItems[key] = true;
        }
      }
    }

    const hasAEmployee = this.UserInfo.roles.find(obj => obj.id === 4);
    if(hasAEmployee){
      console.log('Employee')
      this.navItems.orderInvoice = true
      this.navItems.accounts = true
    }

    const navLinks = document.querySelectorAll(".nav-sidebar .nav-link");

    navLinks.forEach((link) => {
      if (link.href === window.location.href) {
        link.classList.add("active");
      }
      link.addEventListener("click", function () {
        navLinks.forEach((navLink) => navLink.classList.remove("active"));
        this.classList.add("active");
      });
    });
  },
};
</script>

<style></style>
