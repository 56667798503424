<template>
  <div class="hold-transition login-page">
  <div class="login-box">
    <div class="card card-outline card-primary">
      <div class="card-header text-center">
        <a href="../../index2.html" class="h1"><b>Ampf</b></a>
      </div>
      <div class="card-body" v-show="SentPR">
        <p class="alert alert-success" role="alert">Sent Password Reset</p>
        <br />
        <p class="mt-3 mb-1">
          <router-link :to="{ name: 'Login'}">Login</router-link>
        </p>
      </div>
      <div class="card-body" v-show="!SentPR">
        <p class="login-box-msg">You forgot your password? Here you can easily retrieve a new password.</p>
        <p class="alert alert-danger" role="alert" v-show="v$.form.email.$error">Email format incorrect</p>
        <form @submit.prevent="submitForm" autocomplete="off">
          <div class="input-group mb-3">
            <input type="email" v-model="form.email" class="form-control" placeholder="Email">
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-envelope"></span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button type="submit" class="btn btn-primary btn-block">Request new password</button>
            </div>
            <!-- /.col -->
          </div>
        </form>
        <p class="mt-3 mb-1">
          <router-link :to="{ name: 'Login'}">Login</router-link>
        </p>
      </div>
      <!-- /.login-card-body -->
    </div>
  </div>
  <!-- /.login-box -->
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {email,required} from '@vuelidate/validators'
import {mapGetters} from "vuex";

export default {
  name: "ForgotPassword",
  setup: () => ({v$: useVuelidate()}),
  data() {
    return {
      msg: '',
      SentPR: false,
      form: {
        email: null,
      }
    }
  },
  validations() {
    return {
      form: {
        email: {required, email}
      }
    }
  },
  computed: {
    ...mapGetters({
      'UserInfo': 'userAccess/user',
    }),
  },
  methods: {
    async submitForm() {
      const result = await this.v$.$validate()
      if (!result) {
        return
      }
      // if (this.form.username && this.form.password) {
      this.$store.dispatch('userAccess/forgotEmailCheck', this.form.email)
          .then(async () => {
            this.SentPR = true;
          })
          .catch(() => {
            this.loading = false
            // alert('🤷‍️' + error.message)
          })
      // }
    },
  }, mounted() {

  },
}
</script>

<style scoped>

</style>