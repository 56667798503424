<template>
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <!-- left column -->
        <div class="col-md-6">
          <!-- general form elements -->
          <div class="card card-primary">
            <div class="card-header">
              <h3 class="card-title">{{header}}</h3>
            </div>
            <!-- /.card-header -->
            <!-- form start -->
            <form  v-on:submit.prevent="AddEditUser">
              <div class="card-body">
                <div class="form-group">
                  <label for="name">Name</label>
                  <input type="text" class="form-control" id="name" v-model="form.name">
                </div>
                <div class="form-group">
                  <label for="name">Username</label>
                  <input type="text" class="form-control" id="username" v-model="form.username" required>
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Email address</label>
                  <input type="email" class="form-control" id="exampleInputEmail1" v-model="form.email" required>
                </div>
                <div class="form-group" v-if="!this.userid">
                  <label for="exampleInputPassword1">Password</label>
                  <input :type="tgltype" class="form-control" id="exampleInputPassword1" v-model="form.password" required>
                  <font-awesome-icon class="toggle-password" :icon="tglpass" id="togglePassword" @click="togglePass"/>
                </div>

                  <div class="form-group" >
                      <label for="account">Account</label>
                      <input type="text" class="form-control" id="account" v-model="form.account">
                  </div>

                <div class="form-group">
                  <label>Role</label>
                  <select class="form-control" v-model="this.form.roles[0].id" required>
                    <option value=""></option>
                    <option v-for="role in rolesListD" :key="role.id" :value="role.id">{{role.title}}</option>
                  </select>
                </div>

              </div>
              <!-- /.card-body -->

              <div class="card-footer">
                <button type="submit" class="btn btn-primary float-right">{{this.userid ? 'Save' : 'Add'}}</button>
                <button class="btn btn-danger mr-5 float-right" @click.prevent="cancelPage">Cancel</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import {useToast} from "vue-toastification";
export default {
  name: "UserEdit",
  props:['userid'],
  setup() {
    // Get toast interface
    const toast = useToast();
    return {toast}
  },
  data() {
    return {
      header: 'Add New User',
      tglpass: 'eye',
      tgltype: 'password',
      form:{
        name:'',
        username:'',
        email:'',
        password:'',
        account:'',
        roles:[{id:0}]
      }
    }
  },
  methods:{
    togglePass(){
      if(this.tglpass === 'eye'){
        this.tglpass = 'eye-slash'
        this.tgltype = 'text'
      }else{
        this.tglpass = 'eye'
        this.tgltype = 'password'
      }
    },
    cancelPage(){
      this.$router.push({path: '/Admin/Users/'});
    },
    AddEditUser(){
      const newRoles = this.form.roles.map(element => element.id);
      let form = JSON.parse(JSON.stringify(this.form))
      form.roles = newRoles

      if(this.userid) {
        delete form.password
        this.$store.dispatch('user/updateUser', {options: form, userId:this.userid}).then(data => {
          if (data.success) {
              this.toast.success("User saved");
              //this.$router.push({path: '/Admin/Users/'});
          }else if(data.message !== undefined){
              this.toast.warning(data.message);
          }else{
            for (const [key, value] of Object.entries(data.data)) {
              this.toast.warning(key+": "+value[0]);
            }
          }
        });
      }else{
        this.$store.dispatch('user/addUser', {options: form}).then(data => {
          if (data.success) {
            this.toast.success("User added");
            this.$router.push({path: '/Admin/Users/'});
          }else if(data.message !== undefined){
              if(data.data !== undefined){
                for (const [key, value] of Object.entries(data.data)) {
                  this.toast.warning(key+": "+value[0]);
                }
              }else {
                this.toast.warning(data.message);
              }
          }else{
            for (const [key, value] of Object.entries(data.data)) {
              this.toast.warning(key+": "+value[0]);
            }
          }
        });
      }

    }
  },
  computed: {
    ...mapGetters({
      'rolesList': 'user/getRolesList',
    }),
    rolesListD(){
     let idToRemove = 3; // The ID you want to remove
     let newData = [];
      const isEmpty = Object.keys(this.rolesList).length === 0;
     if(!isEmpty) {
       // Use the filter method to create a new array without the object with the specified ID
       newData = this.rolesList.filter(function (item) {
         return item.id !== idToRemove;
       });
     }
      return newData;
    }
  },
  mounted() {
    if(this.userid){
      this.header = 'Edit User'

      this.$store.dispatch('user/getUserData', {options: {userId: this.userid}}).then(data => {
           this.form.name = data.name
           this.form.username = data.username
           this.form.email = data.email
           this.form.roles = data.roles.length > 0 ? data.roles : [{id:0}]
           this.form.account = data.accounts.length > 0 ? data.accounts[0].Acctnum : ''
      });

    }

    this.$store.dispatch('user/getRolesList', {options: {}});
  }
}
</script>

<style scoped>

/*password eye*/
.password-container {
  position: relative;
}

#exampleInputPassword1 {
  padding-right: 30px; /* Space for the icon */
}

.toggle-password {
  position: absolute;
  right: 25px;
  top: 56.5%;
  transform: translateY(-50%);
  cursor: pointer;
}

</style>