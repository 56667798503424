<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <button
                class="btn btn-primary"
                data-toggle="modal"
                data-target="#careerMethodModal"

            >Post Job
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body p-3">
            <div class="row">
              <div class="col-12 mb-2">
                <table class="table">
                  <thead>
                  <tr>
                    <th>ID</th>
                    <th>Heading</th>
                    <th>Subject</th>
                    <th>Active</th>
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in careerList" :key="item?.id">
                    <td>{{ item?.id }}</td>
                    <!--<td><a :download="item?.name" :href="item?.data">{{ item?.name }}</a></td>-->
                    <td>{{ item?.job_heading }}</td>
                    <td>{{ item?.job_subject }}</td>
                    <td>{{ (item?.active ? 'Active' : 'Not Active') }}</td>
                    <td>
                      <button class="btn btn-danger" @click.prevent="deleteCareer(item?.id)">Delete</button>
                    </td>
                    <td>
                      <button class="btn btn-primary" @click.prevent="editCareer(item?.id)">Edit</button>
                    </td>
                  </tr>
                  </tbody>
                </table>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
        class="modal fade"
        id="careerMethodModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="careerMethodModalLabel"
        aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="careerMethodModalLabel">Career</h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="padding">
              <div class="row">
                <career-edit :editId="editId"></career-edit>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import CareerEdit from "@/components/Admin/CareerEdit.vue";
import $ from "jquery";

export default {
  name: 'whoWeAre',
  data() {
    return {
      tinykey: process.env.VUE_APP_TINYKEY,
      editId: '',
      form: {
        Who: '',
      }
    };
  },
  components: {
    CareerEdit
  },
  computed: {
    ...mapGetters({
      'careerList': 'System/getCareerList',
    }),
  },
  methods: {
    editCareer(id) {
      this.editId = id
      $("#careerMethodModal").modal("show");
    },
    deleteCareer(id) {
      this.$store.dispatch('System/careers', {data: {id}, method: 'delete'}).then(() => {
        this.$store.dispatch('System/careers', {data: {}, method: 'get'})
      })
    }
  },
  mounted() {
    this.$store.dispatch('System/careers', {data: {}, method: 'get'})
    //let self = this
    $("#careerMethodModal").on("show.bs.modal", async function () {
      console.log('open cm')
      //self.editId = 333;
    });
  }
}
</script>

<style scoped>

</style>