<template>
  <div class="container-fluid">
    <div class="card card-outline card-primary">
      <div class="card-header">
        <div class="row">
          <div class="col">
            <h2>Products</h2>
          </div>
        </div>
      </div>
      <div class="card-body">

        <div class="row">
          <div class="col-2">
            Has Image<br>
            <select class="form-control" id="searchimg" v-model="searchimg">
              <option value=""></option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </div>
          <div class="col-3">
            Has Frame Builder Image<br>
            <select class="form-control" id="fbimg" v-model="fbimg">
              <option value=""></option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </div>
          <div class="col-4">
            Active<br>
            <select class="form-control" id="active" v-model="ActiveType">
              <option value="both"></option>
              <option value="Y">Yes</option>
              <option value="N">No</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-outline card-primary">

      <div class="card-body">
        <div class="row">
          <div class="col">
            <table class="table" id="productsTbl">
              <thead>
              <tr>
                <th>Code</th>
                <th>Description</th>
                <th>Type</th>
                <th>Has Image</th>
                <th>FB Image</th>
                <th>&nbsp;</th>
              </tr>
              </thead>
              <tbody>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
//import {mapGetters} from "vuex"
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";

export default {
  name: "AProducts",
  data() {
    return {
      dataloaded: false,
      searchimg: '',
      fbimg: '',
      ActiveType: 'Y',
      tableLoaded: false,
      searchString: ''
    }
  },
  computed: {},
  methods: {
    goTo(rl) {
      window.location.href = '/Admin/Products/Edit/' + rl;
    },
    onint() {
      let self = this
      $("#productsTbl_wrapper input[type='search']").off();
      // Use return key to trigger search
      $("#productsTbl_wrapper input[type='search']").on("keyup", function (evt) {
        this.search = $(this).val();
        if (evt.keyCode == 13) {
          $("#productsTbl").DataTable().draw();
        }
      });

      $("#fbimg, #searchimg, #active").on("change", function () {
        $("#productsTbl").DataTable().draw();
      });

      $(".editlnk").on("click", function (event) {
        event.preventDefault()
        //self.goTo({name:'ProductEdit', params: {Code: $(this).attr('data-code')}})
        self.goTo($(this).attr('data-code'));
      });
    },
    loadData() {
      let self = this
      $("#productsTbl").DataTable({
        ajax: function (data, callback) {
          console.log('s',this.searchString,self.$route.query.searchString)
          if(this.searchString === undefined && self.$route.query.searchString && self.$route.query.searchString !== '') {
            $("#productsTbl_wrapper input[type='search']").val(self.$route.query.searchString)
            this.searchString = self.$route.query.searchString
          }

          let sPage = (parseInt($("#productsTbl").DataTable().page()) + 1);
          let search = $("#productsTbl_wrapper input[type='search']").val();
          let orderCol = data.columns[data.order[0].column].data;
          let orderDir = data.order[0].dir;
          let options = {
            page: sPage,
            items_per_page: data.length,
            orderCol,
            orderDir, ...self.filterForm
          }

          options.ActiveType = self.ActiveType;

          if (search !== '') {
            options.TopSearch = search
          }

          if (self.searchimg !== '') {
            options.searchimg = self.searchimg
          }

          if (self.fbimg !== '') {
            options.fbimg = self.fbimg
          }

          self.$store.dispatch('product/getProductData', {
            options
          }).then(adata => {
            const tmpJson = {
              recordsTotal: adata.total, // expected by DataTables to create pagination
              recordsFiltered: adata.total, // expected by DataTables to create pagination
              data: adata.data, // expected by DataTables to populate the table
              currentPage: adata.current_page // added by me to easily manage correct page displaying
            }

            setTimeout(function () {
              self.onint();
            }, 600);

            callback(
                tmpJson
            )
          });
        },
        "initComplete": function () {
          //self.onint();
        },
        processing: true,
        serverSide: true,
        order: [[1, 'desc']],
        "columns": [
          {"data": "Code"},
          {"data": "Description"},
          {"data": "FrameType"},
          {
            "data": 'search_img',
            "name": 'search_img',
            "render": function (data, type, row) {
              return (row.search_img === null ? 'No' : 'Yes');
            }

          },
          {
            "data": 'top_img',
            "name": 'top_img',
            "render": function (data, type, row) {
              return (row.top_img === null ? 'No' : 'Yes');
            }

          },
          {
            "data": null,
            "name": 'Code',
            "render": function (data, type, row) {
              //return '<a href="/Admin/Products/Edit/'+row.Code+'">Edit</a>';
              return '<button class="btn btn-primary editlnk" data-code="' + row.Code + '" >Edit</button>';
            }

          },
        ],
        "responsive": true, "lengthChange": false, "autoWidth": false,
        "buttons": ["copy", "csv", "excel", "pdf", "print", "colvis"],
      }).buttons().container().appendTo('#productsTbl_wrapper .col-md-6:eq(0)');

    }
  },
  mounted() {
    this.loadData()

    //let self = this
    //this.$store.dispatch("product/getProductData", {options: {"items_per_page": 50}}).then(rez => {
    //console.log('rez', rez)
    /*
      $("#datatable").DataTable({
      "fnInitComplete": function () {
        console.log('completed load');
        self.tableLoaded = true
      }
    });
      */

    //})
  },
  beforeRouteLeave() {
      $("#productsTbl").DataTable().destroy();
  }
}
</script>

<style>
.editlnk {
  color: blue;
  cursor: pointer;
}
</style>