import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Maska from 'maska'
import Loading from 'vue-loading-overlay';
import moment from 'moment'

window.$ = window.jQuery = require("jquery");

import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {dom, library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons'
import {fab} from '@fortawesome/free-brands-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
import {faLock, faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {faFacebook, faGooglePlus} from '@fortawesome/free-brands-svg-icons';

import Toast from "vue-toastification";

//import adminlte scripts

/* eslint-disable no-console */
//import "@/assets/js/popperjs"
/* eslint-disable no-console */

import "../node_modules/admin-lte/dist/js/adminlte.min.js"
import "../node_modules/admin-lte/plugins/select2/js/select2.full.min.js"
import "../node_modules/admin-lte/plugins/bootstrap/js/bootstrap.bundle.min.js"
import "../node_modules/admin-lte/plugins/overlayScrollbars/js/jquery.overlayScrollbars.min.js"

//import "../node_modules/admin-lte/plugins/summernote/summernote-bs4.min.js"
//import "../node_modules/admin-lte/plugins/tempusdominus-bootstrap-4/js/tempusdominus-bootstrap-4.min.js"
import "../node_modules/admin-lte/plugins/daterangepicker/daterangepicker.js"
//mport "../node_modules/admin-lte/plugins/moment/moment.min.js"
import "../node_modules/admin-lte/plugins/jquery-knob/jquery.knob.min.js"
//import "../node_modules/admin-lte/plugins/jqvmap/maps/jquery.vmap.usa.js"
//import "../node_modules/admin-lte/plugins/jqvmap/jquery.vmap.min.js"
import "../node_modules/admin-lte/plugins/sparklines/sparkline.js"
//import "../node_modules/admin-lte/plugins/chart.js/Chart.min.js"
import "../node_modules/admin-lte/plugins/jquery/jquery.min.js"
import "../node_modules/admin-lte/plugins/jquery-ui/jquery-ui.min.js"
import "../node_modules/admin-lte/plugins/inputmask/jquery.inputmask.min.js"
//import "../node_modules/admin-lte/plugins/tempusdominus-bootstrap-4/js/tempusdominus-bootstrap-4.min.js"
import "../node_modules/admin-lte/plugins/bootstrap-switch/js/bootstrap-switch.min.js"
import "../node_modules/admin-lte/plugins/bs-stepper/js/bs-stepper.min.js"
import "../node_modules/admin-lte/plugins/dropzone/min/dropzone.min.js"

import "../node_modules/admin-lte/plugins/datatables/jquery.dataTables.min"


//import adminlte styles
import '../node_modules/admin-lte/dist/css/adminlte.min.css'
import "../node_modules/admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.min.css"
import "../node_modules/admin-lte/plugins/summernote/summernote-bs4.min.css"
import "../node_modules/admin-lte/plugins/daterangepicker/daterangepicker.css"
import "../node_modules/admin-lte/plugins/jqvmap/jqvmap.min.css"
import "../node_modules/admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css"
import "../node_modules/admin-lte/plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css"
import "../node_modules/admin-lte/plugins/bootstrap4-duallistbox/bootstrap-duallistbox.min.css"
import "../node_modules/admin-lte/plugins/bs-stepper/css/bs-stepper.min.css"
import "../node_modules/admin-lte/plugins/dropzone/min/dropzone.min.css"

import "../node_modules/admin-lte/plugins/datatables-bs4/css/dataTables.bootstrap4.min.css"
import "../node_modules/admin-lte/plugins/datatables-responsive/css/responsive.bootstrap4.min.css"
import "../node_modules/admin-lte/plugins/datatables-buttons/css/buttons.bootstrap4.min.css"
import "vue-toastification/dist/index.css";

import "../node_modules/admin-lte/plugins/datatables-responsive/js/dataTables.responsive.min.js"
import "../node_modules/admin-lte/plugins/datatables-responsive/js/responsive.bootstrap4.min.js"
import "../node_modules/admin-lte/plugins/datatables-buttons/js/dataTables.buttons.min.js"
import "../node_modules/admin-lte/plugins/datatables-buttons/js/buttons.bootstrap4.min.js"
import "../node_modules/admin-lte/plugins/jszip/jszip.min.js"
import "../node_modules/admin-lte/plugins/pdfmake/pdfmake.min.js"
import "../node_modules/admin-lte/plugins/pdfmake/vfs_fonts.js"
import "../node_modules/admin-lte/plugins/datatables-buttons/js/buttons.html5.min.js"
import "../node_modules/admin-lte/plugins/datatables-buttons/js/buttons.print.min.js"
import "../node_modules/admin-lte/plugins/datatables-buttons/js/buttons.colVis.min.js"

import '../node_modules/vue-loading-overlay/dist/vue-loading.css';

library.add(fas, fab, far, faLock, faEnvelope, faFacebook, faGooglePlus);
dom.watch()

const app = createApp(App).component("font-awesome-icon", FontAwesomeIcon).mixin({
    methods: {
        formatCurrency(value) {
            let val = (value/1).toFixed(2).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
    },
})
app.config.globalProperties.$moment = moment
app.use(Loading).use(Toast).use(store).use(Maska).use(router).mount('#app')
