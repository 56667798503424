<template>
  <div>
    Dashboard
  </div>
</template>

<script>

export default {
  name: "AdminDashboard"
}
</script>

<style scoped>

</style>