<template>
  <div class="container-fluid">

    <div class="card card-outline card-primary">
      <div class="card-header">
        <div class="row">
          <div class="col">
            <h2>Accounts</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-outline">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <table id="accounts1" class="table table-bordered table-striped">
              <thead>
              <tr>
                <th>&nbsp;</th>
                <th>Account Number</th>
                <th>Company</th>
                <th>Address</th>
                <th>Phone</th>
                <th>Fax</th>
                <th>Contact</th>
              </tr>
              </thead>
              <tbody>

              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import $ from 'jquery'
import {mapGetters} from "vuex";

export default {
  name: "Accounts",
  data() {
    return {
      search: '',
      options: {dom: '<"top"f>rt<"bottom"ilp><"clear">', buttons: ['csv']}
    }
  },

  computed: {
    ...mapGetters({
      'accountData': 'Accounts/getAccounts',
    }),
  },
  methods: {
    goTo(rl) {
      this.$router.push(rl)
    },
    ssoLogin(acctId) {
      this.$store.dispatch('user/getSSOToken', {Acctnum: acctId}).then(adata => {
        if (adata.vtoken !== '') {
          window.open(process.env.VUE_APP_FORGOTPW_LINK + '/#/login/' + adata.vtoken, '_blank');
        }
      })
    },
    onint() {
      let self = this
      $("#accounts1_wrapper input[type='search']").off();
      // Use return key to trigger search
      $("#accounts1_wrapper input[type='search']").on("keyup", function (evt) {
        this.search = $(this).val();
        if (evt.keyCode == 13) {
          $("#accounts1").DataTable().draw();
        }
      });

      $(".actlog").on("click", function (event) {
        event.preventDefault()
        self.ssoLogin($(this).attr('data-id'))
      });
    },
    loadData() {
      let self = this
      $("#accounts1").DataTable({
        ajax: function (data, callback) {
          let sPage = (parseInt($("#accounts1").DataTable().page()) + 1);
          let search = $("#accounts1_wrapper input[type='search']").val();
          let orderCol = data.columns[data.order[0].column].data;
          let orderDir = data.order[0].dir;
          //let sOrder = '';
          self.$store.dispatch('Accounts/getAccountData', {
            options: {
              page: sPage,
              perPage: data.length,
              search,
              orderCol,
              orderDir
            }
          }).then(adata => {
            const tmpJson = {
              recordsTotal: adata.data.total, // expected by DataTables to create pagination
              recordsFiltered: adata.data.total, // expected by DataTables to create pagination
              data: adata.data.data, // expected by DataTables to populate the table
              currentPage: adata.current_page // added by me to easily manage correct page displaying
            }

            setTimeout(function () {
              self.onint();
            }, 800);

            callback(
                tmpJson
            )
          });
        },
        processing: true,
        serverSide: true,
        'columnDefs': [{
          'targets': [3], // column index (start from 0)
          'orderable': false, // set orderable false for selected columns
        }],
        "columns": [
          {
            "data": null,
            "render": function (data, type, row) {
              return '<span class="actlog" data-id="' + row.Acctnum + '">Log in as</span>';
            }

          },
          {
            "data": null,
            "render": function (data, type, row) {
              return '<a href="/Admin/AccountDetails/' + row.Acctnum + '">' + row.Acctnum + '</a>';
            }

          },
          {"data": "Company"},
          {
            "data": null,
            "render": function (data, type, row) {
              return row.Address1 + " " + (row.Address2 !== null ? row.Address2 : '') + " " + (row.City !== null ? row.City : '');
            }

          },
          {"data": "Phone"},
          {"data": "Fax"},
          {"data": "Contact"},
        ],
        "responsive": true, "lengthChange": false, "autoWidth": false,
        "buttons": ["copy", "csv", "excel", "pdf", "print", "colvis"],
      }).buttons().container().appendTo('#accounts1_wrapper .col-md-6:eq(0)');

    }
  },
  mounted() {
    this.loadData()
  },
  beforeRouteLeave() {
    $("#accounts1").DataTable().destroy();
  }
}
</script>

<style>
.actlog {
  cursor: pointer;
  white-space: nowrap;
}
</style>