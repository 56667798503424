<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card ">
          <div class="card-body p-3">
            <div class="row">
              <div class="col-12 mb-2 text-right">
                <button class="btn btn-primary" @click.prevent="saveCollection">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-4">
        <div class="card collapsed-card">
          <div class="card-header">
            <h3 class="card-title">Popular collections</h3>
            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-plus fa-lg"></i>
              </button>
            </div>
          </div>
          <div class="card-body p-3">

            <div class="row">
              <div class="col-12 mb-2">
                <CollectionsList spot="1"/>
              </div>
              <div class="col-12 mb-2">
                <CollectionsList spot="2"/>
              </div>
              <div class="col-12 mb-2">
                <CollectionsList spot="3"/>
              </div>
              <div class="col-12 mb-2">
                <CollectionsList spot="4"/>
              </div>
              <div class="col-12 mb-2">
                <CollectionsList spot="5"/>
              </div>
              <div class="col-12 mb-2">
                <CollectionsList spot="6"/>
              </div>
              <!--
              <div class="col-12 mb-2">
                <CollectionsList spot="7"/>
              </div>
              -->
            </div>
          </div>
        </div>
      </div>


      <div class="col-4">
        <div class="card collapsed-card">
          <div class="card-header">
            <h3 class="card-title">What's New</h3>
            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-plus fa-lg"></i>
              </button>
            </div>
          </div>
          <div class="card-body p-3">

            <div class="row">
              <div class="col-12 mb-2">
                <CollectionsList spot="8"/>
              </div>
              <div class="col-12 mb-2">
                <CollectionsList spot="9"/>
              </div>
              <div class="col-12 mb-2">
                <CollectionsList spot="10"/>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="col-4">
        <div class="card collapsed-card">
          <div class="card-header">
            <h3 class="card-title">Popular Value Line</h3>
            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-plus fa-lg"></i>
              </button>
            </div>
          </div>
          <div class="card-body p-3">
            <div class="row">
              <div class="col-12">
                <CollectionsListPopular @updatePopular="updatePopular"/>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="row">
      <div class="col-4">
        <div class="card collapsed-card">
          <div class="card-header">
            <h3 class="card-title">Profile Type</h3>
            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-plus fa-lg"></i>
              </button>
            </div>
          </div>
          <div class="card-body p-3">
            <div class="row">
              <div class="col-12">
                <ProfileType @updateProfileType="updateProfileType"/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="card collapsed-card">
          <div class="card-header">
            <h3 class="card-title">Color Family</h3>
            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-plus fa-lg"></i>
              </button>
            </div>
          </div>
          <div class="card-body p-3">
            <div class="row">
              <div class="col-12">
                <ColorFamily @updateColorFamily="updateColorFamily"/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="card collapsed-card">
          <div class="card-header">
            <h3 class="card-title">Style</h3>
            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-plus fa-lg"></i>
              </button>
            </div>
          </div>
          <div class="card-body p-3">
            <div class="row">
              <div class="col-12">
                <Style @updateStyle="updateStyle"/>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="row">
      <div class="col-4">
        <div class="card collapsed-card">
          <div class="card-header">
            <h3 class="card-title">Finish</h3>
            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-plus fa-lg"></i>
              </button>
            </div>
          </div>
          <div class="card-body p-3">
            <div class="row">
              <div class="col-12">
                <Finish @updateFinish="updateFinish"/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-8">
        <div class="card collapsed-card">
          <div class="card-header">
            <h3 class="card-title">Frame Types</h3>
            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-plus fa-lg"></i>
              </button>
            </div>
          </div>
          <div class="card-body p-3">
            <div class="row">
              <div class="col-12">
                <FrameTypes @updateFrameType="updateFrameType"/>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="row">
      <div class="col-4">
        <div class="card collapsed-card">
          <div class="card-header">
            <h3 class="card-title">Discount Labels</h3>
            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-plus fa-lg"></i>
              </button>
            </div>
          </div>
          <div class="card-body p-3">
            <div class="row">
              <div class="col-12">
                <DiscountList @updateDiscount="updateDiscount"/>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="row">
      <div class="col-12">
        <div class="card ">
          <div class="card-body p-3">
            <div class="row">
              <div class="col-4 mb-2 ">
                <button class="btn btn-primary" @click="goToPage({'name':'Policys'})">Policy & Terms</button>
              </div>
              <div class="col-4 mb-2 ">
                <button class="btn btn-primary" @click="goToPage({'name':'WhoWeAre'})">Who We Are</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card ">
          <div class="card-body p-3">
            <div class="row">
              <div class="col-12 mb-2 text-right">
                <button class="btn btn-primary" @click.prevent="saveCollection">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import CollectionsList from "@/components/Admin/CollectionsList.vue";
import DiscountList from "@/components/Admin/DiscountList.vue";
import ProfileType from "@/components/Admin/ProfileType.vue";
import ColorFamily from "@/components/Admin/ColorFamily.vue";
import Style from "@/components/Admin/Style.vue";
import Finish from "@/components/Admin/Finish.vue";
import FrameTypes from "@/components/Admin/FrameTypes.vue";
import {mapGetters} from "vuex";
import {useToast} from "vue-toastification";
import CollectionsListPopular from "@/components/Admin/CollectionsListPopular.vue";


export default {
  name: "SiteConfig",
  setup() {
    // Get toast interface

    const toast = useToast();
    return {toast};
  },
  data() {
    return {
      loader: "dots",
      color: "#00ab00",
      bgColor: "#4b4b4b",
      collectionList: [],
      discountArr: [],
      profileTypeArr: [],
      colorFamilyArr: [],
      styleArr: [],
      finishArr: [],
      frametypeArr: [],
      collectionPopular: []
    };
  },
  components: {
    CollectionsList,
    DiscountList,
    ProfileType,
    ColorFamily,
    Style,
    Finish,
    FrameTypes,
    CollectionsListPopular
  },
  computed: {
    ...mapGetters({
      'collectionData': 'product/getCollectionData',
      'settingsData': 'product/getSettingsData',
    }),

  },
  methods: {
    goToPage(page) {
      this.$router.push(page);
    },
    updateFinish(data) {
      this.finishArr = data
    },
    updateFrameType(data) {
      console.log('ft data', data)
      this.frametypeArr = data
    },
    updateStyle(data) {
      this.styleArr = data
    },
    updateColorFamily(data) {
      this.colorFamilyArr = data
    },
    updateProfileType(proData) {
      this.profileTypeArr = proData
    },
    updateDiscount(disData) {
      this.discountArr = disData
    },
    updatePopular(data) {
      this.collectionPopular = data
    },
    async saveCollection() {
      let loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: this.canCancel,
        color: this.color,
        backgroundColor: this.bgColor,
        height: this.height,
        width: this.width,
        loader: this.loader,
        opacity: 0.3,
      });


      let inputs = document.getElementsByClassName("colSpots");
      let collection = {};
      let missingColValue = 0;
      for (let i = 0; i < inputs.length; i++) {
        let selectedOption = inputs[i].options[inputs[i].selectedIndex];
        let selectedText = selectedOption.text;
        if (inputs[i].value === '') {
          missingColValue++
        }
        collection[inputs[i].id] = {name: selectedText, value: inputs[i].value}
      }

      if (missingColValue !== inputs.length) {
        const upSetData = this.$store.dispatch('product/updateSettingData', {
          id: 1,
          data: JSON.stringify(collection)
        })

        await upSetData;
      }
      if (Object.keys(this.discountArr).length > 0) {
        const disUpData = this.$store.dispatch('Accounts/discountUpdateNames', {disArr: this.discountArr})
        await disUpData;
      }

      if (Object.keys(this.profileTypeArr).length > 0) {
        const proTypeData = this.$store.dispatch('product/updateProfileType', {proTArr: this.profileTypeArr})
        await proTypeData;
      }

      if (Object.keys(this.colorFamilyArr).length > 0) {
        const colorFamilyData = this.$store.dispatch('product/updateColorFamily', {dataArr: this.colorFamilyArr})
        await colorFamilyData;
      }

      if (Object.keys(this.styleArr).length > 0) {
        const styleData = this.$store.dispatch('product/setupStyle', {data: {dataArr: this.styleArr}, method: 'put'})
        await styleData;
      }
      if (Object.keys(this.finishArr).length > 0) {
        const finishData = this.$store.dispatch('product/setupFinish', {data: {dataArr: this.finishArr}, method: 'put'})
        await finishData;
      }

      if (Object.keys(this.collectionPopular).length > 0) {
        const colPopData = this.$store.dispatch('product/setupCollPop', {value_lines: this.collectionPopular})
        await colPopData;
      }

      if (Object.keys(this.frametypeArr).length > 0) {
        const frameTypeData = this.$store.dispatch('product/setupFrameType', {
          data: {types: this.frametypeArr},
          method: 'put'
        })
        await frameTypeData;
        this.$store.dispatch('product/setupFrameType', {method: 'get'})
      }

      loader.hide()
      this.toast.success("Saved");

    }
  },
  mounted() {
    this.$store.dispatch('product/collectionData', {options: {perPage: 1000, orderCol: 'name', orderDir: 'Asc'}})
    this.$store.dispatch('product/settingsData').then(rez => {
      const popularCollections = rez.find(obj => obj.name === "popular_collections");
      if (popularCollections.data !== undefined) {
        let popcolObj = JSON.parse(popularCollections.data)
        for (let key in popcolObj) {
          if (Object.prototype.hasOwnProperty.call(popcolObj, key)) {
            const value = popcolObj[key];
            if (document.getElementById(key)) {
              document.getElementById(key).value = value.value;
            }
          }
        }
      }
    });
  }
};
</script>
<style scoped>
.buttons {
  margin-top: 35px;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}


</style>