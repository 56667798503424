<template>
  <div>
    <!-- Content Header (Page header) -->
    <!--
    <section class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-12">
                  <button @click="goBack">Back</button>
                    <h1>Invoice</h1>
                </div>
            </div>
        </div>
    </section>
    -->
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <!--
                        <div class="callout callout-info">
                          <h5><i class="fas fa-info"></i> Note:</h5>
                          This page has been enhanced for printing. Click the print button at the bottom of the invoice to test.
                        </div>
                        -->

            <!-- Main content -->
            <div class="invoice p-3 mb-3">
              <!-- title row -->
              <div class="row">
                <div class="col-12">
                  <h4>
                    <i class="fas fa-globe"></i> AMPF
                    <small class="float-right"
                    >Date: {{ formatDateT(invoiceDetails.OrdDate) }}</small
                    >
                  </h4>
                </div>
                <!-- /.col -->
              </div>
              <!-- info row -->
              <div class="row invoice-info">
                <div class="col-sm-4 invoice-col">
                  From
                  <address
                      v-html="displayInvLocation(invoiceDetails)"
                  ></address>
                </div>
                <!-- /.col -->
                <div class="col-sm-4 invoice-col">
                  To
                  <address>
                    <strong>{{ invoiceDetails.ShipCompany }}</strong
                    ><br/>
                    {{ invoiceDetails.ShipAddress1 }} <br/>
                    {{ invoiceDetails.ShipAddress2 }}<br/>
                    {{
                      invoiceDetails.ShipCity +
                      " " +
                      invoiceDetails.ShipState +
                      " " +
                      invoiceDetails.ShipZip
                    }}<br/>
                  </address>
                </div>
                <!-- /.col -->
                <div class="col-sm-4 invoice-col">
                  <b>Invoice #{{ invoiceDetails.Invnum }}</b
                  ><br/>
                  <br/>
                  <b>Order #:</b>
                  <router-link
                      :to="{
                      path: '/Admin/OrderDetails/' + invoiceDetails.Ordnum,
                    }"
                  >
                    {{ invoiceDetails.Ordnum }}
                  </router-link>
                  <br/>
                  <b>Payment Due:</b> {{
                    formatDate(invoiceDetails.DueDate)
                  }}<br/>
                  <b>Account:</b>
                  <router-link
                      :to="{
                      path: '/Admin/AccountDetails/' + invoiceDetails.Acctnum,
                    }"
                  >
                    {{ invoiceDetails.Acctnum }}
                  </router-link>
                </div>
                <!-- /.col -->
              </div>
              <!-- /.row -->

              <div class="row">
                <div class="col-sm-2 invoice-col">
                  <label for="Terms">Terms</label>
                  <input
                      type="text"
                      class="form-control"
                      id="Terms"
                      name="Terms"
                      :value="invoiceDetails.Terms"
                      disabled="disabled"
                  />
                </div>
                <!-- /.col -->
                <div class="col-sm-2 invoice-col">
                  <label for="Terms">Shipvia</label>
                  <input
                      type="text"
                      class="form-control"
                      id="Terms"
                      name="Terms"
                      :value="invoiceDetails.Shipvia"
                      disabled="disabled"
                  />
                </div>
                <!-- /.col -->
                <div class="col-sm-2 invoice-col">
                  <label for="Terms">Sls</label>
                  <input
                      type="text"
                      class="form-control"
                      id="Terms"
                      name="Terms"
                      :value="invoiceDetails.Sls"
                      disabled="disabled"
                  />
                </div>
                <!-- /.col -->
                <div class="col-sm-2 invoice-col">
                  <label for="Terms">Pay By</label>
                  <input
                      type="text"
                      class="form-control"
                      id="Terms"
                      name="Terms"
                      :value="invoiceDetails.PayBy"
                      disabled="disabled"
                  />
                </div>
                <div class="col-sm-2 invoice-col">
                  <label for="Terms">PO #</label>
                  <input
                      type="text"
                      class="form-control"
                      id="Terms"
                      name="Terms"
                      :value="invoiceDetails.CustPo"
                      disabled="disabled"
                  />
                </div>
                <div class="col-sm-2 invoice-col">
                  <label for="Terms">Operator</label>
                  <input
                      type="text"
                      class="form-control"
                      id="Terms"
                      name="Terms"
                      :value="invoiceDetails.Operator"
                      disabled="disabled"
                  />
                </div>
              </div>

              <!-- Table row -->
              <div class="row">
                <div class="col-12 table-responsive">
                  <table class="table table-striped">
                    <thead>
                    <tr>
                      <th>Ship</th>
                      <th>Order</th>
                      <th>Code</th>
                      <th>Description</th>
                      <th>Dimension</th>
                      <th>T</th>
                      <th>Footage</th>
                      <th>UnitPrice</th>
                      <th>Total</th>
                      <th>Join Fee</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="item in invoiceDetails.invoice_items"
                        :key="item.Seqnum"
                    >
                      <td>{{ item.Shipped ? parseInt(item.Shipped) : 0 }}</td>
                      <td>{{ item.Qty ? parseInt(item.Qty) : 0 }}</td>
                      <td>{{ item.Code }}</td>
                      <td>{{ item.Description }}</td>
                      <td>
                        {{
                          setInvoiceDetailsTotals[item.Seqnum].dimensionsFull
                        }}
                      </td>
                      <td>{{ item.Pcode }}</td>
                      <td>
                        {{
                          item.Footage
                              ? parseFloat(item.Footage).toFixed(2)
                              : 0
                        }}
                      </td>
                      <td>{{ "$" + formatCurrency(item.UnitPrice) }}</td>
                      <td>
                        {{
                          "$" +
                          formatCurrency(
                              setInvoiceDetailsTotals[item.Seqnum].totalPrice
                          )
                        }}
                      </td>
                      <td>
                        {{
                          setInvoiceDetailsTotals[item.Seqnum].calcJoin
                              ? formatCurrency(
                                  setInvoiceDetailsTotals[item.Seqnum].calcJoin
                              )
                              : 0.0
                        }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <!-- /.col -->
              </div>
              <!-- /.row -->

              <div class="row">
                <!-- accepted payments column -->
                <div class="col-6"></div>
                <!-- /.col -->
                <div class="col-6">
                  <div class="table-responsive">
                    <table class="table">
                      <tr>
                        <th style="width: 50%">Item Total:</th>
                        <td>${{ formatCurrency(invoiceDetails.InvTotal) }}</td>
                      </tr>
                      <tr>
                        <th style="width: 50%">
                          Discount%
                          <input
                              style="width: 60px"
                              type="text"
                              readonly
                              :value="invoiceDetails.DiscRate"
                          />
                        </th>
                        <td>${{ formatCurrency(invoiceDetails.Discount) }}</td>
                      </tr>
                      <tr>
                        <th>Tax</th>
                        <td>${{ formatCurrency(invoiceDetails.Tax) }}</td>
                      </tr>
                      <tr>
                        <th>Shipping:</th>
                        <td>${{ formatCurrency(invoiceDetails.Shipping) }}</td>
                      </tr>
                      <tr>
                        <th>Shipping Cost:</th>
                        <td>${{ formatCurrency(invoiceDetails.ShipCost) }}</td>
                      </tr>
                      <tr>
                        <th>Packing:</th>
                        <td>${{ formatCurrency(invoiceDetails.Packing) }}</td>
                      </tr>
                      <tr>
                        <th>Total:</th>
                        <td>${{ formatCurrency(invoiceDetails.InvoiceInvTotal) }}</td>
                      </tr>
                    </table>
                  </div>
                </div>
                <!-- /.col -->
              </div>
              <!-- /.row -->

              <!-- this row will not appear when printing -->

              <div class="row no-print">
                <div class="col-12">
                  <!--<a href="invoice-print.html" rel="noopener" target="_blank" class="btn btn-default"><i class="fas fa-print"></i> Print</a>-->
                  <!--
                                    <button type="button" class="btn btn-success float-right"
                                            :class="canChargeCard(invoiceDetails) ? '' : 'disabled'"
                                            :disabled="!canChargeCard(invoiceDetails)"
                                            @click.prevent="SubmitPayment">
                                        <i class="far fa-credit-card"></i> Submit Payment
                                    </button>
                                    -->

                  <button
                      type="button"
                      class="btn btn-success float-right"
                      :class="canChargeCard(invoiceDetails) ? '' : 'disabled'"
                      :disabled="!canChargeCard(invoiceDetails)"
                      data-toggle="modal"
                      data-target="#methodModal"
                      v-if="canSubmitPay"
                  >
                    <i class="far fa-credit-card"></i>Submit Payment
                  </button>

                  <div class="float-right mr-4 text-bold text-danger">
                    <h2>
                      {{
                        PaymentStatusDisp(
                            invoiceDetails.paystatus,
                            invoiceDetails.balance
                        )
                      }}
                    </h2>
                  </div>
                  <!--
                                    <button type="button" class="btn btn-primary float-right" style="margin-right: 5px;">
                                      <i class="fas fa-download"></i> Generate PDF
                                    </button>
                                    -->
                </div>
              </div>
            </div>
            <!-- /.invoice -->
          </div>
          <!-- /.col -->
        </div>
        <!-- /.row -->

        <payments :payments="invoiceDetails.payments"/>
      </div>
      <!-- /.container-fluid -->
    </section>
    <!-- /.content -->

    <div
        class="modal fade"
        id="methodModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="methodModalLabel"
        aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="methodModalLabel">Billing Method</h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="padding">
              <div class="row">
                <div class="col-md-12">
                  <div class="card expanding-card">
                    <div class="card-header">
                      <h3 class="card-title">Billing Methods</h3>
                      <div class="card-tools">
                        <button
                            type="button"
                            class="btn btn-tool billMeth"
                            data-card-widget="collapse"
                        >
                          <i class="fas fa-minus fa-lg"></i>
                        </button>
                      </div>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body p-0">
                      <table class="table table-sm">
                        <thead>
                        <tr>
                          <th>Charge</th>
                          <th>Last Four</th>
                          <th>Exp</th>
                          <th>Type</th>
                          <th>Default</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="bill in accountDetails.billing"
                            :key="bill.id"
                        >
                          <td>
                            <input
                                type="radio"
                                name="billMethod"
                                v-model="form.accountBillId"
                                :value="bill.id"
                            />
                          </td>
                          <td>{{ bill.last_four }}</td>
                          <td>{{ bill.expmonth + " - " + bill.expyear }}</td>
                          <td>{{ bill.type }}</td>
                          <td>
                            <input
                                type="radio"
                                name="billDefault"
                                :checked="bill.default"
                                disabled
                            />
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <!-- /.card-body -->
                  </div>
                </div>
              </div>

              <p class="alert alert-danger" role="alert" v-show="errorMsg">
                {{ msg }}
              </p>
              <p
                  class="alert alert-danger"
                  role="alert"
                  v-show="
                  v$.form.accountBillId !== undefined
                    ? v$.form.accountBillId.$invalid
                    : false
                "
              >
                Please choose payment method
              </p>

              <p
                  class="alert alert-danger"
                  role="alert"
                  v-show="
                  v$.form.expmonth !== undefined
                    ? v$.form.expmonth.$invalid
                    : false
                "
              >
                Please choose Exp Month
              </p>
              <p
                  class="alert alert-danger"
                  role="alert"
                  v-show="
                  v$.form.expyear !== undefined
                    ? v$.form.expyear.$invalid
                    : false
                "
              >
                Please choose exp Year
              </p>

              <div class="row">
                <div class="col-sm-12">
                  <div class="card expanding-card">
                    <div class="card-header">
                      <h3 class="card-title">
                        <input
                            type="radio"
                            name="billMethod"
                            value="manual"
                            v-model="form.accountBillId"
                        />
                        Enter Card
                      </h3>
                      <div class="card-tools">
                        <button
                            type="button"
                            class="btn btn-tool newCard"
                            data-card-widget="collapse"
                        >
                          <i class="fas fa-minus fa-lg"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="form-group">
                            <label for="name">Billing Information</label>
                            <input
                                class="form-control"
                                id="billing_name"
                                type="text"
                                placeholder="Customer Name"
                                v-model="form.billing_name"
                            />
                            <input
                                class="form-control mt-2"
                                id="billing_address"
                                type="text"
                                placeholder="Address"
                                v-model="form.billing_address"
                            />
                            <input
                                class="form-control mt-2"
                                id="billing_city"
                                type="text"
                                placeholder="City"
                                v-model="form.billing_city"
                            />
                            <input
                                class="form-control mt-2"
                                id="billing_state"
                                type="text"
                                placeholder="State"
                                v-model="form.billing_state"
                            />
                            <input
                                class="form-control mt-2"
                                id="billing_postal"
                                type="text"
                                placeholder="Postal Code"
                                v-model="form.billing_postal"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-12">
                          <div class="form-group">
                            <label for="ccnumber">Credit Card Number</label>
                            <div>
                              <div class="tokenBox" id="tokenExIframeDiv"></div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="form-group col-sm-4">
                          <label for="ccmonth">Month</label>
                          <select
                              class="form-control"
                              id="expmonth"
                              v-model="form.expmonth"
                          >
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                          </select>
                        </div>
                        <div class="form-group col-sm-4">
                          <label for="ccyear">Year</label>
                          <select
                              class="form-control"
                              id="expyear"
                              v-model="form.expyear"
                          >
                            <option
                                v-for="yr in yearRange"
                                :value="yr"
                                :key="yr"
                            >
                              {{ yr }}
                            </option>
                          </select>
                        </div>
                        <!--
                                                <div class="col-sm-4">
                                                  <div class="form-group">
                                                    <label for="cvv">CVV/CVC</label>
                                                    <input class="form-control" id="cvv" type="text" placeholder="123">
                                                  </div>
                                                </div>
                                                -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="row">
                <div class="col-sm-12">
                  <div class="card expanding-card">
                    <div class="card-header">
                      <h3 class="card-title">
                        Payment Amount
                      </h3>
                      <div class="card-tools">
                        <button
                            type="button"
                            class="btn btn-tool newCard"
                            data-card-widget="collapse"
                        >
                          <i class="fas fa-minus fa-lg"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="form-group">
                            <label for="name">Payment Amount</label>
                            <input
                                class="form-control"
                                id="billing_name"
                                type="text"
                                :placeholder="formatCurrency(invoiceDetails.balance)"
                                v-model="form.amount"
                                @input="formatCurrencyInput(invoiceDetails.balance)"
                            />
                            <p v-if="currencyValueError" class="error">{{ currencyValueError }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="modal-footer">
            <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
            >
              Close
            </button>
            <button
                type="button"
                class="btn btn-primary"
                id="btnSubmit"
                @click.prevent="SubmitType"
                ref="paybutton"
            >
              Submit Payment
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import payments from "@/components/Admin/payments.vue";
import {useToast} from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import $ from "jquery";
import {required} from "@vuelidate/validators";

export default {
  name: "InvoiceDetails",
  props: ["invoiceid"],
  setup() {
    // Get toast interface

    const toast = useToast();
    return {toast, v$: useVuelidate()};
  },
  data() {
    return {
      search: "",
      errorMsg: false,
      msg: "",
      canSubmitPay: false,
      options: {dom: '<"top"f>rt<"bottom"ilp><"clear">', buttons: ["csv"]},
      fullPage: true,
      canCancel: false,
      useSlot: false,
      loader: "dots",
      timeout: 10000, //ms
      color: "#00ab00",
      bgColor: "#4b4b4b",
      height: 64,
      width: 64,
      isBtnLoaded: false,
      yearRange: [],
      currencyValueError: false,
      form: {
        Acctnum: "",
        accountBillId: "",
        paymentType: "",
        token: "",
        expyear: "",
        expmonth: "",
        billing_address: "",
        billing_city: "",
        billing_state: "",
        billing_postal: "",
        billing_name: "",
        rawdata: "",
        last_four: "",
        type: "",
        amount:""
      },
    };
  },
  components: {
    payments: payments,
  },
  computed: {
    ...mapGetters({
      invoiceDetails: "orders/getInvoiceDetails",
      setInvoiceDetailsTotals: "orders/getInvoiceDetailsTotals",
      accountDetails: "Accounts/getAccountDetails",
      UserInfo: "userAccess/user"
    }),
    rules() {
      return this.form.accountBillId !== "manual"
          ? {accountBillId: {required, $autoDirty: true}}
          : {
            accountBillId: {required},
            token: {required},
            expyear: {required},
            expmonth: {required},
          };
    },
  },
  validations() {
    return {
      form: this.rules,
    };
  },
  methods: {
    formatCurrencyInput(maxAmount) {
      // Remove non-numeric characters except decimal point
      this.form.amount = this.form.amount.replace(/[^0-9.]/g, '');

      // Format the value as currency
      const parts = this.form.amount.split('.');
      //parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      if (parts.length > 1) {
        parts[1] = parts[1].slice(0, 2); // Keep only two decimal places
      }
      this.form.amount = parts.join('.');

      // Validate against the maximum amount
      const amount = parseFloat(this.form.amount);
      if (amount > maxAmount) {
        this.$refs.paybutton.disabled = true
        this.$refs.paybutton.classList.add('disabled')
        this.currencyValueError = `Amount should be less than or equal to ${this.formatCurrency(maxAmount)}`;
      } else {
        this.$refs.paybutton.disabled = false
        this.$refs.paybutton.classList.remove('disabled')
        this.currencyValueError = '';
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    displayInvLocation(invD) {
      let dLoc = "<strong>AMPF INC.</strong><br>";
      if (invD.location !== undefined) {
        let invL = invD.location;
        dLoc += invL.Addr1 + "<br>";
        dLoc += invL.Addr2 ? invL.Addr2 + "<br>" : "";
        dLoc += invL.City + ", " + invL.State + " " + invL.Zip + "<br>";
        dLoc += "Tel: 800-640-2467" + "<br>";
        dLoc += "Fax: 800-474-1869" + "<br>";
        dLoc += "E-mail: info@ampfframes.com";
      }
      return dLoc;
    },
    canChargeCard(invD) {
      /*
      &&
        (invD.Terms === "VISA" ||
          invD.Terms === "M/C" ||
          invD.Terms === "MASTERCARD" ||
          invD.Terms === "AMEX")
      * */
      let cc = false;
      let balance = parseFloat(invD.balance).toFixed(2);
      if (
          invD.paystatus === 0 &&
          balance > 0
      ) {
        cc = true;
      }
      return cc;
    },
    PaymentStatusDisp(status, balance) {
      let statusS = "";
      if (status === 0) {
        statusS = "Balance $" + this.formatCurrency(balance);
      } else if (status === 1) {
        statusS = "Paid in full";
      }
      if (status === 2) {
        statusS = "";
      }
      if (status === 3) {
        statusS = "Balance $" + this.formatCurrency(balance);
      }
      if (status === 4) {
        statusS = "View Credit Memo";
      }
      return statusS;
    },
    async SubmitType() {
      if (this.form.accountBillId === "manual") {
        this.iframe.tokenize();
      } else {
        const result = await this.v$.$validate();
        if (!result) {
          return;
        }
        this.SubmitPayment();
      }
    },
    SubmitPayment() {
      if (this.form.accountBillId === "manual") {
        this.form.paymentType = "manPlain";
      }

      let loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: this.canCancel,
        onCancel: this.whenCancelled,
        color: this.color,
        backgroundColor: this.bgColor,
        height: this.height,
        width: this.width,
        loader: this.loader,
        opacity: 0.3,
      });
      /*
            setTimeout(() => {
              loader.hide()
            }, this.timeout)
            */
      this.$store
          .dispatch("orders/makePayment", {
            Invnum: this.invoiceid,
            ...this.form,
          })
          .then((data) => {
            if (data.success && data.paymentSuccess) {
              this.toast.success("Payment Ran");
              this.$store
                  .dispatch("orders/getAdminInvoiceDetails", {
                    Invoicenum: this.invoiceid,
                  })
                  .then(() => {
                    loader.hide();
                    $("#methodModal").modal("hide");
                  });
            } else if (!data.success || !data.paymentSuccess) {
              if (data.msg !== undefined) {
                this.toast.error(data.msg);
              } else if (data.message !== undefined) {
                this.toast.error(data.message);
              }
              loader.hide();
            }
          });
    },
    formatDateT(date) {
      let newDate = "";
      if (date != undefined) {
        let dates = date.split("T");
        let datex = dates[0].split("-");
        newDate =
            parseInt(datex[1]) + "/" + parseInt(datex[2]) + "/" + datex[0];
      }
      return newDate;
    },
    formatDate(date) {
      let newDate = "";
      if (date != undefined) {
        let dates = date.split(" ");
        let datex = dates[0].split("-");
        newDate =
            parseInt(datex[1]) + "/" + parseInt(datex[2]) + "/" + datex[0];
      }
      return newDate;
    },
    clearForm() {
      const keys = Object.keys(this.form);
      // eslint-disable-next-line no-unused-vars
      keys.forEach((key, index) => {
        this.form[key] = "";
      });
    },
    expYearRange() {
      let lowEnd = new Date().getFullYear();
      let highEnd = new Date().getFullYear() + 10;
      while (lowEnd <= highEnd) {
        this.yearRange.push(lowEnd++);
      }
    },
  },
  mounted() {
    const hasAdmin = this.UserInfo.roles.find(obj => obj.id === 1);
    if(hasAdmin){
      this.canSubmitPay = true
    }
    this.expYearRange();

    let loader = this.$loading.show({
      container: this.fullPage ? null : this.$refs.formContainer,
      canCancel: this.canCancel,
      onCancel: this.whenCancelled,
      color: this.color,
      backgroundColor: this.bgColor,
      height: this.height,
      width: this.width,
      loader: this.loader,
      opacity: 1,
    });


    this.$store.dispatch("orders/getAdminInvoiceDetails", {
      Invoicenum: this.invoiceid,
    }).then(() => {
      loader.hide();
    });

    let self = this;

    $("#methodModal").on("show.bs.modal", async function () {
      await self.$store.dispatch("Accounts/accountDetails", {
        Acctnum: self.invoiceDetails.Acctnum,
      });
      await self.clearForm();
      self.form.Acctnum = self.invoiceDetails.Acctnum;

      self.form.billing_name = self.accountDetails?.Company
      self.form.billing_address = self.accountDetails?.Address1 + ' ' + self.accountDetails?.Address2
      self.form.billing_city = self.accountDetails?.City
      self.form.billing_state = self.accountDetails?.State
      self.form.billing_postal = self.accountDetails?.Zip

      if (!self.isBtnLoaded) {
        let iframeConfig = await self.$store.dispatch(
            "Accounts/getTokenXCreds"
        );
        // eslint-disable-next-line no-undef
        self.iframe = new TokenEx.Iframe("tokenExIframeDiv", iframeConfig);

        self.iframe.on("load", function () {
          console.log("iFrame Loaded");
        });
        self.iframe.on("focus", function () {
          console.log("iFrame Focus");
        });
        self.iframe.on("blur", function () {
          console.log("iFrame blur");
          self.iframe.validate();
        });
        self.iframe.on("validate", function (data) {
          if (!data.isValid) {
            self.errorMsg = true;
            self.msg = "Check Credit Card Number";
          } else {
            self.errorMsg = false;
            self.msg = "";
          }
          console.log("iFrame validate:" + JSON.stringify(data));
        });
        self.iframe.on("cardTypeChange", function (data) {
          console.log("iFrame cardTypeChange:" + JSON.stringify(data));
        });
        self.iframe.on("tokenize", async function (data) {
          console.log("iFrame Tokenize:", data);
          self.form.rawdata = JSON.stringify(data);
          self.form.token = data.token;
          self.form.last_four = data.lastFour;
          self.form.type = data.cardType;
          self.errorMsg = false;
          const result = await self.v$.$validate();
          if (!result) {
            console.log("errors token");
            return;
          }
          self.SubmitPayment();
        });
        self.iframe.on("error", function (data) {
          self.errorMsg = true;
          self.msg = "Unknown error please refresh page";
          console.log("iFrame error:" + JSON.stringify(data));
        });
        /*
                $('#btnSubmit').off().on('click', function (event) {
                  event.preventDefault();
                  self.iframe.tokenize();
                });
                */
        self.isBtnLoaded = true;

        self.iframe.load();
      }
    });
  },
};
</script>

<style scoped>
.tokenBox {
  height: 55px;
}
</style>
